/*!
 * DSFR v1.13.0 | SPDX-License-Identifier: MIT | License-Filename: LICENSE.md | restricted use (see terms and conditions)
 */
@charset "UTF-8";

/* ¯¯¯¯¯¯¯¯¯ *\
  ICON BUILDINGS
\* ˍˍˍˍˍˍˍˍˍ */
.fr-icon-ancient-gate-fill::before,
.fr-icon-ancient-gate-fill::after {
  -webkit-mask-image: url("../../icons/buildings/ancient-gate-fill.svg");
  mask-image: url("../../icons/buildings/ancient-gate-fill.svg");
}

.fr-icon-ancient-gate-line::before,
.fr-icon-ancient-gate-line::after {
  -webkit-mask-image: url("../../icons/buildings/ancient-gate-line.svg");
  mask-image: url("../../icons/buildings/ancient-gate-line.svg");
}

.fr-icon-ancient-pavilion-fill::before,
.fr-icon-ancient-pavilion-fill::after {
  -webkit-mask-image: url("../../icons/buildings/ancient-pavilion-fill.svg");
  mask-image: url("../../icons/buildings/ancient-pavilion-fill.svg");
}

.fr-icon-ancient-pavilion-line::before,
.fr-icon-ancient-pavilion-line::after {
  -webkit-mask-image: url("../../icons/buildings/ancient-pavilion-line.svg");
  mask-image: url("../../icons/buildings/ancient-pavilion-line.svg");
}

.fr-icon-bank-fill::before,
.fr-icon-bank-fill::after {
  -webkit-mask-image: url("../../icons/buildings/bank-fill.svg");
  mask-image: url("../../icons/buildings/bank-fill.svg");
}

.fr-icon-bank-line::before,
.fr-icon-bank-line::after {
  -webkit-mask-image: url("../../icons/buildings/bank-line.svg");
  mask-image: url("../../icons/buildings/bank-line.svg");
}

.fr-icon-building-fill::before,
.fr-icon-building-fill::after {
  -webkit-mask-image: url("../../icons/buildings/building-fill.svg");
  mask-image: url("../../icons/buildings/building-fill.svg");
}

.fr-icon-building-line::before,
.fr-icon-building-line::after {
  -webkit-mask-image: url("../../icons/buildings/building-line.svg");
  mask-image: url("../../icons/buildings/building-line.svg");
}

.fr-icon-community-fill::before,
.fr-icon-community-fill::after {
  -webkit-mask-image: url("../../icons/buildings/community-fill.svg");
  mask-image: url("../../icons/buildings/community-fill.svg");
}

.fr-icon-community-line::before,
.fr-icon-community-line::after {
  -webkit-mask-image: url("../../icons/buildings/community-line.svg");
  mask-image: url("../../icons/buildings/community-line.svg");
}

.fr-icon-government-fill::before,
.fr-icon-government-fill::after {
  -webkit-mask-image: url("../../icons/buildings/government-fill.svg");
  mask-image: url("../../icons/buildings/government-fill.svg");
}

.fr-icon-government-line::before,
.fr-icon-government-line::after {
  -webkit-mask-image: url("../../icons/buildings/government-line.svg");
  mask-image: url("../../icons/buildings/government-line.svg");
}

.fr-icon-home-4-fill::before,
.fr-icon-home-4-fill::after {
  -webkit-mask-image: url("../../icons/buildings/home-4-fill.svg");
  mask-image: url("../../icons/buildings/home-4-fill.svg");
}

.fr-icon-home-4-line::before,
.fr-icon-home-4-line::after {
  -webkit-mask-image: url("../../icons/buildings/home-4-line.svg");
  mask-image: url("../../icons/buildings/home-4-line.svg");
}

.fr-icon-hospital-fill::before,
.fr-icon-hospital-fill::after {
  -webkit-mask-image: url("../../icons/buildings/hospital-fill.svg");
  mask-image: url("../../icons/buildings/hospital-fill.svg");
}

.fr-icon-hospital-line::before,
.fr-icon-hospital-line::after {
  -webkit-mask-image: url("../../icons/buildings/hospital-line.svg");
  mask-image: url("../../icons/buildings/hospital-line.svg");
}

.fr-icon-hotel-fill::before,
.fr-icon-hotel-fill::after {
  -webkit-mask-image: url("../../icons/buildings/hotel-fill.svg");
  mask-image: url("../../icons/buildings/hotel-fill.svg");
}

.fr-icon-hotel-line::before,
.fr-icon-hotel-line::after {
  -webkit-mask-image: url("../../icons/buildings/hotel-line.svg");
  mask-image: url("../../icons/buildings/hotel-line.svg");
}

.fr-icon-store-fill::before,
.fr-icon-store-fill::after {
  -webkit-mask-image: url("../../icons/buildings/store-fill.svg");
  mask-image: url("../../icons/buildings/store-fill.svg");
}

.fr-icon-store-line::before,
.fr-icon-store-line::after {
  -webkit-mask-image: url("../../icons/buildings/store-line.svg");
  mask-image: url("../../icons/buildings/store-line.svg");
}

.fr-fi-home-4-fill::before,
.fr-fi-home-4-fill::after {
  -webkit-mask-image: url("../../icons/buildings/home-4-fill.svg");
  mask-image: url("../../icons/buildings/home-4-fill.svg");
}

.fr-fi-home-4-line::before,
.fr-fi-home-4-line::after {
  -webkit-mask-image: url("../../icons/buildings/home-4-line.svg");
  mask-image: url("../../icons/buildings/home-4-line.svg");
}

/* ¯¯¯¯¯¯¯¯¯ *\
  ICON BUSINESS
\* ˍˍˍˍˍˍˍˍˍ */
.fr-icon-archive-fill::before,
.fr-icon-archive-fill::after {
  -webkit-mask-image: url("../../icons/business/archive-fill.svg");
  mask-image: url("../../icons/business/archive-fill.svg");
}

.fr-icon-archive-line::before,
.fr-icon-archive-line::after {
  -webkit-mask-image: url("../../icons/business/archive-line.svg");
  mask-image: url("../../icons/business/archive-line.svg");
}

.fr-icon-attachment-fill::before,
.fr-icon-attachment-fill::after {
  -webkit-mask-image: url("../../icons/business/attachment-fill.svg");
  mask-image: url("../../icons/business/attachment-fill.svg");
}

.fr-icon-attachment-line::before,
.fr-icon-attachment-line::after {
  -webkit-mask-image: url("../../icons/business/attachment-line.svg");
  mask-image: url("../../icons/business/attachment-line.svg");
}

.fr-icon-award-fill::before,
.fr-icon-award-fill::after {
  -webkit-mask-image: url("../../icons/business/award-fill.svg");
  mask-image: url("../../icons/business/award-fill.svg");
}

.fr-icon-award-line::before,
.fr-icon-award-line::after {
  -webkit-mask-image: url("../../icons/business/award-line.svg");
  mask-image: url("../../icons/business/award-line.svg");
}

.fr-icon-bar-chart-box-fill::before,
.fr-icon-bar-chart-box-fill::after {
  -webkit-mask-image: url("../../icons/business/bar-chart-box-fill.svg");
  mask-image: url("../../icons/business/bar-chart-box-fill.svg");
}

.fr-icon-bar-chart-box-line::before,
.fr-icon-bar-chart-box-line::after {
  -webkit-mask-image: url("../../icons/business/bar-chart-box-line.svg");
  mask-image: url("../../icons/business/bar-chart-box-line.svg");
}

.fr-icon-bookmark-fill::before,
.fr-icon-bookmark-fill::after {
  -webkit-mask-image: url("../../icons/business/bookmark-fill.svg");
  mask-image: url("../../icons/business/bookmark-fill.svg");
}

.fr-icon-bookmark-line::before,
.fr-icon-bookmark-line::after {
  -webkit-mask-image: url("../../icons/business/bookmark-line.svg");
  mask-image: url("../../icons/business/bookmark-line.svg");
}

.fr-icon-briefcase-fill::before,
.fr-icon-briefcase-fill::after {
  -webkit-mask-image: url("../../icons/business/briefcase-fill.svg");
  mask-image: url("../../icons/business/briefcase-fill.svg");
}

.fr-icon-briefcase-line::before,
.fr-icon-briefcase-line::after {
  -webkit-mask-image: url("../../icons/business/briefcase-line.svg");
  mask-image: url("../../icons/business/briefcase-line.svg");
}

.fr-icon-calendar-2-fill::before,
.fr-icon-calendar-2-fill::after {
  -webkit-mask-image: url("../../icons/business/calendar-2-fill.svg");
  mask-image: url("../../icons/business/calendar-2-fill.svg");
}

.fr-icon-calendar-2-line::before,
.fr-icon-calendar-2-line::after {
  -webkit-mask-image: url("../../icons/business/calendar-2-line.svg");
  mask-image: url("../../icons/business/calendar-2-line.svg");
}

.fr-icon-calendar-event-fill::before,
.fr-icon-calendar-event-fill::after {
  -webkit-mask-image: url("../../icons/business/calendar-event-fill.svg");
  mask-image: url("../../icons/business/calendar-event-fill.svg");
}

.fr-icon-calendar-event-line::before,
.fr-icon-calendar-event-line::after {
  -webkit-mask-image: url("../../icons/business/calendar-event-line.svg");
  mask-image: url("../../icons/business/calendar-event-line.svg");
}

.fr-icon-calendar-fill::before,
.fr-icon-calendar-fill::after {
  -webkit-mask-image: url("../../icons/business/calendar-fill.svg");
  mask-image: url("../../icons/business/calendar-fill.svg");
}

.fr-icon-calendar-line::before,
.fr-icon-calendar-line::after {
  -webkit-mask-image: url("../../icons/business/calendar-line.svg");
  mask-image: url("../../icons/business/calendar-line.svg");
}

.fr-icon-cloud-fill::before,
.fr-icon-cloud-fill::after {
  -webkit-mask-image: url("../../icons/business/cloud-fill.svg");
  mask-image: url("../../icons/business/cloud-fill.svg");
}

.fr-icon-cloud-line::before,
.fr-icon-cloud-line::after {
  -webkit-mask-image: url("../../icons/business/cloud-line.svg");
  mask-image: url("../../icons/business/cloud-line.svg");
}

.fr-icon-copyright-fill::before,
.fr-icon-copyright-fill::after {
  -webkit-mask-image: url("../../icons/business/copyright-fill.svg");
  mask-image: url("../../icons/business/copyright-fill.svg");
}

.fr-icon-copyright-line::before,
.fr-icon-copyright-line::after {
  -webkit-mask-image: url("../../icons/business/copyright-line.svg");
  mask-image: url("../../icons/business/copyright-line.svg");
}

.fr-icon-customer-service-fill::before,
.fr-icon-customer-service-fill::after {
  -webkit-mask-image: url("../../icons/business/customer-service-fill.svg");
  mask-image: url("../../icons/business/customer-service-fill.svg");
}

.fr-icon-customer-service-line::before,
.fr-icon-customer-service-line::after {
  -webkit-mask-image: url("../../icons/business/customer-service-line.svg");
  mask-image: url("../../icons/business/customer-service-line.svg");
}

.fr-icon-flag-fill::before,
.fr-icon-flag-fill::after {
  -webkit-mask-image: url("../../icons/business/flag-fill.svg");
  mask-image: url("../../icons/business/flag-fill.svg");
}

.fr-icon-flag-line::before,
.fr-icon-flag-line::after {
  -webkit-mask-image: url("../../icons/business/flag-line.svg");
  mask-image: url("../../icons/business/flag-line.svg");
}

.fr-icon-global-fill::before,
.fr-icon-global-fill::after {
  -webkit-mask-image: url("../../icons/business/global-fill.svg");
  mask-image: url("../../icons/business/global-fill.svg");
}

.fr-icon-global-line::before,
.fr-icon-global-line::after {
  -webkit-mask-image: url("../../icons/business/global-line.svg");
  mask-image: url("../../icons/business/global-line.svg");
}

.fr-icon-line-chart-fill::before,
.fr-icon-line-chart-fill::after {
  -webkit-mask-image: url("../../icons/business/line-chart-fill.svg");
  mask-image: url("../../icons/business/line-chart-fill.svg");
}

.fr-icon-line-chart-line::before,
.fr-icon-line-chart-line::after {
  -webkit-mask-image: url("../../icons/business/line-chart-line.svg");
  mask-image: url("../../icons/business/line-chart-line.svg");
}

.fr-icon-links-fill::before,
.fr-icon-links-fill::after {
  -webkit-mask-image: url("../../icons/business/links-fill.svg");
  mask-image: url("../../icons/business/links-fill.svg");
}

.fr-icon-links-line::before,
.fr-icon-links-line::after {
  -webkit-mask-image: url("../../icons/business/links-line.svg");
  mask-image: url("../../icons/business/links-line.svg");
}

.fr-icon-mail-fill::before,
.fr-icon-mail-fill::after {
  -webkit-mask-image: url("../../icons/business/mail-fill.svg");
  mask-image: url("../../icons/business/mail-fill.svg");
}

.fr-icon-mail-line::before,
.fr-icon-mail-line::after {
  -webkit-mask-image: url("../../icons/business/mail-line.svg");
  mask-image: url("../../icons/business/mail-line.svg");
}

.fr-icon-mail-open-fill::before,
.fr-icon-mail-open-fill::after {
  -webkit-mask-image: url("../../icons/business/mail-open-fill.svg");
  mask-image: url("../../icons/business/mail-open-fill.svg");
}

.fr-icon-mail-open-line::before,
.fr-icon-mail-open-line::after {
  -webkit-mask-image: url("../../icons/business/mail-open-line.svg");
  mask-image: url("../../icons/business/mail-open-line.svg");
}

.fr-icon-medal-fill::before,
.fr-icon-medal-fill::after {
  -webkit-mask-image: url("../../icons/business/medal-fill.svg");
  mask-image: url("../../icons/business/medal-fill.svg");
}

.fr-icon-medal-line::before,
.fr-icon-medal-line::after {
  -webkit-mask-image: url("../../icons/business/medal-line.svg");
  mask-image: url("../../icons/business/medal-line.svg");
}

.fr-icon-pie-chart-2-fill::before,
.fr-icon-pie-chart-2-fill::after {
  -webkit-mask-image: url("../../icons/business/pie-chart-2-fill.svg");
  mask-image: url("../../icons/business/pie-chart-2-fill.svg");
}

.fr-icon-pie-chart-2-line::before,
.fr-icon-pie-chart-2-line::after {
  -webkit-mask-image: url("../../icons/business/pie-chart-2-line.svg");
  mask-image: url("../../icons/business/pie-chart-2-line.svg");
}

.fr-icon-pie-chart-box-fill::before,
.fr-icon-pie-chart-box-fill::after {
  -webkit-mask-image: url("../../icons/business/pie-chart-box-fill.svg");
  mask-image: url("../../icons/business/pie-chart-box-fill.svg");
}

.fr-icon-pie-chart-box-line::before,
.fr-icon-pie-chart-box-line::after {
  -webkit-mask-image: url("../../icons/business/pie-chart-box-line.svg");
  mask-image: url("../../icons/business/pie-chart-box-line.svg");
}

.fr-icon-printer-fill::before,
.fr-icon-printer-fill::after {
  -webkit-mask-image: url("../../icons/business/printer-fill.svg");
  mask-image: url("../../icons/business/printer-fill.svg");
}

.fr-icon-printer-line::before,
.fr-icon-printer-line::after {
  -webkit-mask-image: url("../../icons/business/printer-line.svg");
  mask-image: url("../../icons/business/printer-line.svg");
}

.fr-icon-profil-fill::before,
.fr-icon-profil-fill::after {
  -webkit-mask-image: url("../../icons/business/profil-fill.svg");
  mask-image: url("../../icons/business/profil-fill.svg");
}

.fr-icon-profil-line::before,
.fr-icon-profil-line::after {
  -webkit-mask-image: url("../../icons/business/profil-line.svg");
  mask-image: url("../../icons/business/profil-line.svg");
}

.fr-icon-projector-2-fill::before,
.fr-icon-projector-2-fill::after {
  -webkit-mask-image: url("../../icons/business/projector-2-fill.svg");
  mask-image: url("../../icons/business/projector-2-fill.svg");
}

.fr-icon-projector-2-line::before,
.fr-icon-projector-2-line::after {
  -webkit-mask-image: url("../../icons/business/projector-2-line.svg");
  mask-image: url("../../icons/business/projector-2-line.svg");
}

.fr-icon-send-plane-fill::before,
.fr-icon-send-plane-fill::after {
  -webkit-mask-image: url("../../icons/business/send-plane-fill.svg");
  mask-image: url("../../icons/business/send-plane-fill.svg");
}

.fr-icon-send-plane-line::before,
.fr-icon-send-plane-line::after {
  -webkit-mask-image: url("../../icons/business/send-plane-line.svg");
  mask-image: url("../../icons/business/send-plane-line.svg");
}

.fr-icon-slideshow-fill::before,
.fr-icon-slideshow-fill::after {
  -webkit-mask-image: url("../../icons/business/slideshow-fill.svg");
  mask-image: url("../../icons/business/slideshow-fill.svg");
}

.fr-icon-slideshow-line::before,
.fr-icon-slideshow-line::after {
  -webkit-mask-image: url("../../icons/business/slideshow-line.svg");
  mask-image: url("../../icons/business/slideshow-line.svg");
}

.fr-icon-window-fill::before,
.fr-icon-window-fill::after {
  -webkit-mask-image: url("../../icons/business/window-fill.svg");
  mask-image: url("../../icons/business/window-fill.svg");
}

.fr-icon-window-line::before,
.fr-icon-window-line::after {
  -webkit-mask-image: url("../../icons/business/window-line.svg");
  mask-image: url("../../icons/business/window-line.svg");
}

.fr-fi-attachment-fill::before,
.fr-fi-attachment-fill::after {
  -webkit-mask-image: url("../../icons/business/attachment-fill.svg");
  mask-image: url("../../icons/business/attachment-fill.svg");
}

.fr-fi-attachment-line::before,
.fr-fi-attachment-line::after {
  -webkit-mask-image: url("../../icons/business/attachment-line.svg");
  mask-image: url("../../icons/business/attachment-line.svg");
}

.fr-fi-calendar-fill::before,
.fr-fi-calendar-fill::after {
  -webkit-mask-image: url("../../icons/business/calendar-fill.svg");
  mask-image: url("../../icons/business/calendar-fill.svg");
}

.fr-fi-calendar-line::before,
.fr-fi-calendar-line::after {
  -webkit-mask-image: url("../../icons/business/calendar-line.svg");
  mask-image: url("../../icons/business/calendar-line.svg");
}

.fr-fi-line-chart-fill::before,
.fr-fi-line-chart-fill::after {
  -webkit-mask-image: url("../../icons/business/line-chart-fill.svg");
  mask-image: url("../../icons/business/line-chart-fill.svg");
}

.fr-fi-line-chart-line::before,
.fr-fi-line-chart-line::after {
  -webkit-mask-image: url("../../icons/business/line-chart-line.svg");
  mask-image: url("../../icons/business/line-chart-line.svg");
}

.fr-fi-links-fill::before,
.fr-fi-links-fill::after {
  -webkit-mask-image: url("../../icons/business/links-fill.svg");
  mask-image: url("../../icons/business/links-fill.svg");
}

.fr-fi-mail-fill::before,
.fr-fi-mail-fill::after {
  -webkit-mask-image: url("../../icons/business/mail-fill.svg");
  mask-image: url("../../icons/business/mail-fill.svg");
}

.fr-fi-mail-line::before,
.fr-fi-mail-line::after {
  -webkit-mask-image: url("../../icons/business/mail-line.svg");
  mask-image: url("../../icons/business/mail-line.svg");
}

.fr-fi-printer-fill::before,
.fr-fi-printer-fill::after {
  -webkit-mask-image: url("../../icons/business/printer-fill.svg");
  mask-image: url("../../icons/business/printer-fill.svg");
}

.fr-fi-printer-line::before,
.fr-fi-printer-line::after {
  -webkit-mask-image: url("../../icons/business/printer-line.svg");
  mask-image: url("../../icons/business/printer-line.svg");
}

/* ¯¯¯¯¯¯¯¯¯ *\
  ICON COMMUNICATION
\* ˍˍˍˍˍˍˍˍˍ */
.fr-icon-chat-2-fill::before,
.fr-icon-chat-2-fill::after {
  -webkit-mask-image: url("../../icons/communication/chat-2-fill.svg");
  mask-image: url("../../icons/communication/chat-2-fill.svg");
}

.fr-icon-chat-2-line::before,
.fr-icon-chat-2-line::after {
  -webkit-mask-image: url("../../icons/communication/chat-2-line.svg");
  mask-image: url("../../icons/communication/chat-2-line.svg");
}

.fr-icon-chat-3-fill::before,
.fr-icon-chat-3-fill::after {
  -webkit-mask-image: url("../../icons/communication/chat-3-fill.svg");
  mask-image: url("../../icons/communication/chat-3-fill.svg");
}

.fr-icon-chat-3-line::before,
.fr-icon-chat-3-line::after {
  -webkit-mask-image: url("../../icons/communication/chat-3-line.svg");
  mask-image: url("../../icons/communication/chat-3-line.svg");
}

.fr-icon-chat-check-fill::before,
.fr-icon-chat-check-fill::after {
  -webkit-mask-image: url("../../icons/communication/chat-check-fill.svg");
  mask-image: url("../../icons/communication/chat-check-fill.svg");
}

.fr-icon-chat-check-line::before,
.fr-icon-chat-check-line::after {
  -webkit-mask-image: url("../../icons/communication/chat-check-line.svg");
  mask-image: url("../../icons/communication/chat-check-line.svg");
}

.fr-icon-chat-delete-fill::before,
.fr-icon-chat-delete-fill::after {
  -webkit-mask-image: url("../../icons/communication/chat-delete-fill.svg");
  mask-image: url("../../icons/communication/chat-delete-fill.svg");
}

.fr-icon-chat-delete-line::before,
.fr-icon-chat-delete-line::after {
  -webkit-mask-image: url("../../icons/communication/chat-delete-line.svg");
  mask-image: url("../../icons/communication/chat-delete-line.svg");
}

.fr-icon-chat-poll-fill::before,
.fr-icon-chat-poll-fill::after {
  -webkit-mask-image: url("../../icons/communication/chat-poll-fill.svg");
  mask-image: url("../../icons/communication/chat-poll-fill.svg");
}

.fr-icon-chat-poll-line::before,
.fr-icon-chat-poll-line::after {
  -webkit-mask-image: url("../../icons/communication/chat-poll-line.svg");
  mask-image: url("../../icons/communication/chat-poll-line.svg");
}

.fr-icon-discuss-fill::before,
.fr-icon-discuss-fill::after {
  -webkit-mask-image: url("../../icons/communication/discuss-fill.svg");
  mask-image: url("../../icons/communication/discuss-fill.svg");
}

.fr-icon-discuss-line::before,
.fr-icon-discuss-line::after {
  -webkit-mask-image: url("../../icons/communication/discuss-line.svg");
  mask-image: url("../../icons/communication/discuss-line.svg");
}

.fr-icon-feedback-fill::before,
.fr-icon-feedback-fill::after {
  -webkit-mask-image: url("../../icons/communication/feedback-fill.svg");
  mask-image: url("../../icons/communication/feedback-fill.svg");
}

.fr-icon-feedback-line::before,
.fr-icon-feedback-line::after {
  -webkit-mask-image: url("../../icons/communication/feedback-line.svg");
  mask-image: url("../../icons/communication/feedback-line.svg");
}

.fr-icon-message-2-fill::before,
.fr-icon-message-2-fill::after {
  -webkit-mask-image: url("../../icons/communication/message-2-fill.svg");
  mask-image: url("../../icons/communication/message-2-fill.svg");
}

.fr-icon-message-2-line::before,
.fr-icon-message-2-line::after {
  -webkit-mask-image: url("../../icons/communication/message-2-line.svg");
  mask-image: url("../../icons/communication/message-2-line.svg");
}

.fr-icon-question-answer-fill::before,
.fr-icon-question-answer-fill::after {
  -webkit-mask-image: url("../../icons/communication/question-answer-fill.svg");
  mask-image: url("../../icons/communication/question-answer-fill.svg");
}

.fr-icon-question-answer-line::before,
.fr-icon-question-answer-line::after {
  -webkit-mask-image: url("../../icons/communication/question-answer-line.svg");
  mask-image: url("../../icons/communication/question-answer-line.svg");
}

.fr-icon-questionnaire-fill::before,
.fr-icon-questionnaire-fill::after {
  -webkit-mask-image: url("../../icons/communication/questionnaire-fill.svg");
  mask-image: url("../../icons/communication/questionnaire-fill.svg");
}

.fr-icon-questionnaire-line::before,
.fr-icon-questionnaire-line::after {
  -webkit-mask-image: url("../../icons/communication/questionnaire-line.svg");
  mask-image: url("../../icons/communication/questionnaire-line.svg");
}

.fr-icon-video-chat-fill::before,
.fr-icon-video-chat-fill::after {
  -webkit-mask-image: url("../../icons/communication/video-chat-fill.svg");
  mask-image: url("../../icons/communication/video-chat-fill.svg");
}

.fr-icon-video-chat-line::before,
.fr-icon-video-chat-line::after {
  -webkit-mask-image: url("../../icons/communication/video-chat-line.svg");
  mask-image: url("../../icons/communication/video-chat-line.svg");
}

/* ¯¯¯¯¯¯¯¯¯ *\
  ICON DESIGN
\* ˍˍˍˍˍˍˍˍˍ */
.fr-icon-ball-pen-fill::before,
.fr-icon-ball-pen-fill::after {
  -webkit-mask-image: url("../../icons/design/ball-pen-fill.svg");
  mask-image: url("../../icons/design/ball-pen-fill.svg");
}

.fr-icon-ball-pen-line::before,
.fr-icon-ball-pen-line::after {
  -webkit-mask-image: url("../../icons/design/ball-pen-line.svg");
  mask-image: url("../../icons/design/ball-pen-line.svg");
}

.fr-icon-brush-3-fill::before,
.fr-icon-brush-3-fill::after {
  -webkit-mask-image: url("../../icons/design/brush-3-fill.svg");
  mask-image: url("../../icons/design/brush-3-fill.svg");
}

.fr-icon-brush-3-line::before,
.fr-icon-brush-3-line::after {
  -webkit-mask-image: url("../../icons/design/brush-3-line.svg");
  mask-image: url("../../icons/design/brush-3-line.svg");
}

.fr-icon-brush-fill::before,
.fr-icon-brush-fill::after {
  -webkit-mask-image: url("../../icons/design/brush-fill.svg");
  mask-image: url("../../icons/design/brush-fill.svg");
}

.fr-icon-brush-line::before,
.fr-icon-brush-line::after {
  -webkit-mask-image: url("../../icons/design/brush-line.svg");
  mask-image: url("../../icons/design/brush-line.svg");
}

.fr-icon-contrast-fill::before,
.fr-icon-contrast-fill::after {
  -webkit-mask-image: url("../../icons/design/contrast-fill.svg");
  mask-image: url("../../icons/design/contrast-fill.svg");
}

.fr-icon-contrast-line::before,
.fr-icon-contrast-line::after {
  -webkit-mask-image: url("../../icons/design/contrast-line.svg");
  mask-image: url("../../icons/design/contrast-line.svg");
}

.fr-icon-crop-fill::before,
.fr-icon-crop-fill::after {
  -webkit-mask-image: url("../../icons/design/crop-fill.svg");
  mask-image: url("../../icons/design/crop-fill.svg");
}

.fr-icon-crop-line::before,
.fr-icon-crop-line::after {
  -webkit-mask-image: url("../../icons/design/crop-line.svg");
  mask-image: url("../../icons/design/crop-line.svg");
}

.fr-icon-drag-move-2-fill::before,
.fr-icon-drag-move-2-fill::after {
  -webkit-mask-image: url("../../icons/design/drag-move-2-fill.svg");
  mask-image: url("../../icons/design/drag-move-2-fill.svg");
}

.fr-icon-drag-move-2-line::before,
.fr-icon-drag-move-2-line::after {
  -webkit-mask-image: url("../../icons/design/drag-move-2-line.svg");
  mask-image: url("../../icons/design/drag-move-2-line.svg");
}

.fr-icon-drop-fill::before,
.fr-icon-drop-fill::after {
  -webkit-mask-image: url("../../icons/design/drop-fill.svg");
  mask-image: url("../../icons/design/drop-fill.svg");
}

.fr-icon-drop-line::before,
.fr-icon-drop-line::after {
  -webkit-mask-image: url("../../icons/design/drop-line.svg");
  mask-image: url("../../icons/design/drop-line.svg");
}

.fr-icon-edit-box-fill::before,
.fr-icon-edit-box-fill::after {
  -webkit-mask-image: url("../../icons/design/edit-box-fill.svg");
  mask-image: url("../../icons/design/edit-box-fill.svg");
}

.fr-icon-edit-box-line::before,
.fr-icon-edit-box-line::after {
  -webkit-mask-image: url("../../icons/design/edit-box-line.svg");
  mask-image: url("../../icons/design/edit-box-line.svg");
}

.fr-icon-edit-fill::before,
.fr-icon-edit-fill::after {
  -webkit-mask-image: url("../../icons/design/edit-fill.svg");
  mask-image: url("../../icons/design/edit-fill.svg");
}

.fr-icon-edit-line::before,
.fr-icon-edit-line::after {
  -webkit-mask-image: url("../../icons/design/edit-line.svg");
  mask-image: url("../../icons/design/edit-line.svg");
}

.fr-icon-ink-bottle-fill::before,
.fr-icon-ink-bottle-fill::after {
  -webkit-mask-image: url("../../icons/design/ink-bottle-fill.svg");
  mask-image: url("../../icons/design/ink-bottle-fill.svg");
}

.fr-icon-ink-bottle-line::before,
.fr-icon-ink-bottle-line::after {
  -webkit-mask-image: url("../../icons/design/ink-bottle-line.svg");
  mask-image: url("../../icons/design/ink-bottle-line.svg");
}

.fr-icon-layout-grid-fill::before,
.fr-icon-layout-grid-fill::after {
  -webkit-mask-image: url("../../icons/design/layout-grid-fill.svg");
  mask-image: url("../../icons/design/layout-grid-fill.svg");
}

.fr-icon-layout-grid-line::before,
.fr-icon-layout-grid-line::after {
  -webkit-mask-image: url("../../icons/design/layout-grid-line.svg");
  mask-image: url("../../icons/design/layout-grid-line.svg");
}

.fr-icon-mark-pen-fill::before,
.fr-icon-mark-pen-fill::after {
  -webkit-mask-image: url("../../icons/design/mark-pen-fill.svg");
  mask-image: url("../../icons/design/mark-pen-fill.svg");
}

.fr-icon-mark-pen-line::before,
.fr-icon-mark-pen-line::after {
  -webkit-mask-image: url("../../icons/design/mark-pen-line.svg");
  mask-image: url("../../icons/design/mark-pen-line.svg");
}

.fr-icon-paint-brush-fill::before,
.fr-icon-paint-brush-fill::after {
  -webkit-mask-image: url("../../icons/design/paint-brush-fill.svg");
  mask-image: url("../../icons/design/paint-brush-fill.svg");
}

.fr-icon-paint-brush-line::before,
.fr-icon-paint-brush-line::after {
  -webkit-mask-image: url("../../icons/design/paint-brush-line.svg");
  mask-image: url("../../icons/design/paint-brush-line.svg");
}

.fr-icon-paint-fill::before,
.fr-icon-paint-fill::after {
  -webkit-mask-image: url("../../icons/design/paint-fill.svg");
  mask-image: url("../../icons/design/paint-fill.svg");
}

.fr-icon-paint-line::before,
.fr-icon-paint-line::after {
  -webkit-mask-image: url("../../icons/design/paint-line.svg");
  mask-image: url("../../icons/design/paint-line.svg");
}

.fr-icon-palette-fill::before,
.fr-icon-palette-fill::after {
  -webkit-mask-image: url("../../icons/design/palette-fill.svg");
  mask-image: url("../../icons/design/palette-fill.svg");
}

.fr-icon-palette-line::before,
.fr-icon-palette-line::after {
  -webkit-mask-image: url("../../icons/design/palette-line.svg");
  mask-image: url("../../icons/design/palette-line.svg");
}

.fr-icon-pantone-fill::before,
.fr-icon-pantone-fill::after {
  -webkit-mask-image: url("../../icons/design/pantone-fill.svg");
  mask-image: url("../../icons/design/pantone-fill.svg");
}

.fr-icon-pantone-line::before,
.fr-icon-pantone-line::after {
  -webkit-mask-image: url("../../icons/design/pantone-line.svg");
  mask-image: url("../../icons/design/pantone-line.svg");
}

.fr-icon-pen-nib-fill::before,
.fr-icon-pen-nib-fill::after {
  -webkit-mask-image: url("../../icons/design/pen-nib-fill.svg");
  mask-image: url("../../icons/design/pen-nib-fill.svg");
}

.fr-icon-pen-nib-line::before,
.fr-icon-pen-nib-line::after {
  -webkit-mask-image: url("../../icons/design/pen-nib-line.svg");
  mask-image: url("../../icons/design/pen-nib-line.svg");
}

.fr-icon-pencil-fill::before,
.fr-icon-pencil-fill::after {
  -webkit-mask-image: url("../../icons/design/pencil-fill.svg");
  mask-image: url("../../icons/design/pencil-fill.svg");
}

.fr-icon-pencil-line::before,
.fr-icon-pencil-line::after {
  -webkit-mask-image: url("../../icons/design/pencil-line.svg");
  mask-image: url("../../icons/design/pencil-line.svg");
}

.fr-icon-pencil-ruler-fill::before,
.fr-icon-pencil-ruler-fill::after {
  -webkit-mask-image: url("../../icons/design/pencil-ruler-fill.svg");
  mask-image: url("../../icons/design/pencil-ruler-fill.svg");
}

.fr-icon-pencil-ruler-line::before,
.fr-icon-pencil-ruler-line::after {
  -webkit-mask-image: url("../../icons/design/pencil-ruler-line.svg");
  mask-image: url("../../icons/design/pencil-ruler-line.svg");
}

.fr-icon-shapes-fill::before,
.fr-icon-shapes-fill::after {
  -webkit-mask-image: url("../../icons/design/shapes-fill.svg");
  mask-image: url("../../icons/design/shapes-fill.svg");
}

.fr-icon-shapes-line::before,
.fr-icon-shapes-line::after {
  -webkit-mask-image: url("../../icons/design/shapes-line.svg");
  mask-image: url("../../icons/design/shapes-line.svg");
}

.fr-icon-sip-fill::before,
.fr-icon-sip-fill::after {
  -webkit-mask-image: url("../../icons/design/sip-fill.svg");
  mask-image: url("../../icons/design/sip-fill.svg");
}

.fr-icon-sip-line::before,
.fr-icon-sip-line::after {
  -webkit-mask-image: url("../../icons/design/sip-line.svg");
  mask-image: url("../../icons/design/sip-line.svg");
}

.fr-icon-table-fill::before,
.fr-icon-table-fill::after {
  -webkit-mask-image: url("../../icons/design/table-fill.svg");
  mask-image: url("../../icons/design/table-fill.svg");
}

.fr-icon-table-line::before,
.fr-icon-table-line::after {
  -webkit-mask-image: url("../../icons/design/table-line.svg");
  mask-image: url("../../icons/design/table-line.svg");
}

.fr-fi-edit-fill::before,
.fr-fi-edit-fill::after {
  -webkit-mask-image: url("../../icons/design/edit-fill.svg");
  mask-image: url("../../icons/design/edit-fill.svg");
}

.fr-fi-edit-line::before,
.fr-fi-edit-line::after {
  -webkit-mask-image: url("../../icons/design/edit-line.svg");
  mask-image: url("../../icons/design/edit-line.svg");
}

/* ¯¯¯¯¯¯¯¯¯ *\
  ICON COMMUNICATION
\* ˍˍˍˍˍˍˍˍˍ */
.fr-icon-bug-fill::before,
.fr-icon-bug-fill::after {
  -webkit-mask-image: url("../../icons/development/bug-fill.svg");
  mask-image: url("../../icons/development/bug-fill.svg");
}

.fr-icon-bug-line::before,
.fr-icon-bug-line::after {
  -webkit-mask-image: url("../../icons/development/bug-line.svg");
  mask-image: url("../../icons/development/bug-line.svg");
}

.fr-icon-code-box-fill::before,
.fr-icon-code-box-fill::after {
  -webkit-mask-image: url("../../icons/development/code-box-fill.svg");
  mask-image: url("../../icons/development/code-box-fill.svg");
}

.fr-icon-code-box-line::before,
.fr-icon-code-box-line::after {
  -webkit-mask-image: url("../../icons/development/code-box-line.svg");
  mask-image: url("../../icons/development/code-box-line.svg");
}

.fr-icon-code-s-slash-line::before,
.fr-icon-code-s-slash-line::after {
  -webkit-mask-image: url("../../icons/development/code-s-slash-line.svg");
  mask-image: url("../../icons/development/code-s-slash-line.svg");
}

.fr-icon-cursor-fill::before,
.fr-icon-cursor-fill::after {
  -webkit-mask-image: url("../../icons/development/cursor-fill.svg");
  mask-image: url("../../icons/development/cursor-fill.svg");
}

.fr-icon-cursor-line::before,
.fr-icon-cursor-line::after {
  -webkit-mask-image: url("../../icons/development/cursor-line.svg");
  mask-image: url("../../icons/development/cursor-line.svg");
}

.fr-icon-git-branch-fill::before,
.fr-icon-git-branch-fill::after {
  -webkit-mask-image: url("../../icons/development/git-branch-fill.svg");
  mask-image: url("../../icons/development/git-branch-fill.svg");
}

.fr-icon-git-branch-line::before,
.fr-icon-git-branch-line::after {
  -webkit-mask-image: url("../../icons/development/git-branch-line.svg");
  mask-image: url("../../icons/development/git-branch-line.svg");
}

.fr-icon-git-commit-fill::before,
.fr-icon-git-commit-fill::after {
  -webkit-mask-image: url("../../icons/development/git-commit-fill.svg");
  mask-image: url("../../icons/development/git-commit-fill.svg");
}

.fr-icon-git-commit-line::before,
.fr-icon-git-commit-line::after {
  -webkit-mask-image: url("../../icons/development/git-commit-line.svg");
  mask-image: url("../../icons/development/git-commit-line.svg");
}

.fr-icon-git-merge-fill::before,
.fr-icon-git-merge-fill::after {
  -webkit-mask-image: url("../../icons/development/git-merge-fill.svg");
  mask-image: url("../../icons/development/git-merge-fill.svg");
}

.fr-icon-git-merge-line::before,
.fr-icon-git-merge-line::after {
  -webkit-mask-image: url("../../icons/development/git-merge-line.svg");
  mask-image: url("../../icons/development/git-merge-line.svg");
}

.fr-icon-git-pull-request-fill::before,
.fr-icon-git-pull-request-fill::after {
  -webkit-mask-image: url("../../icons/development/git-pull-request-fill.svg");
  mask-image: url("../../icons/development/git-pull-request-fill.svg");
}

.fr-icon-git-pull-request-line::before,
.fr-icon-git-pull-request-line::after {
  -webkit-mask-image: url("../../icons/development/git-pull-request-line.svg");
  mask-image: url("../../icons/development/git-pull-request-line.svg");
}

.fr-icon-git-repository-commits-fill::before,
.fr-icon-git-repository-commits-fill::after {
  -webkit-mask-image: url("../../icons/development/git-repository-commits-fill.svg");
  mask-image: url("../../icons/development/git-repository-commits-fill.svg");
}

.fr-icon-git-repository-commits-line::before,
.fr-icon-git-repository-commits-line::after {
  -webkit-mask-image: url("../../icons/development/git-repository-commits-line.svg");
  mask-image: url("../../icons/development/git-repository-commits-line.svg");
}

.fr-icon-git-repository-fill::before,
.fr-icon-git-repository-fill::after {
  -webkit-mask-image: url("../../icons/development/git-repository-fill.svg");
  mask-image: url("../../icons/development/git-repository-fill.svg");
}

.fr-icon-git-repository-line::before,
.fr-icon-git-repository-line::after {
  -webkit-mask-image: url("../../icons/development/git-repository-line.svg");
  mask-image: url("../../icons/development/git-repository-line.svg");
}

.fr-icon-git-repository-private-fill::before,
.fr-icon-git-repository-private-fill::after {
  -webkit-mask-image: url("../../icons/development/git-repository-private-fill.svg");
  mask-image: url("../../icons/development/git-repository-private-fill.svg");
}

.fr-icon-git-repository-private-line::before,
.fr-icon-git-repository-private-line::after {
  -webkit-mask-image: url("../../icons/development/git-repository-private-line.svg");
  mask-image: url("../../icons/development/git-repository-private-line.svg");
}

.fr-icon-terminal-box-fill::before,
.fr-icon-terminal-box-fill::after {
  -webkit-mask-image: url("../../icons/development/terminal-box-fill.svg");
  mask-image: url("../../icons/development/terminal-box-fill.svg");
}

.fr-icon-terminal-box-line::before,
.fr-icon-terminal-box-line::after {
  -webkit-mask-image: url("../../icons/development/terminal-box-line.svg");
  mask-image: url("../../icons/development/terminal-box-line.svg");
}

.fr-icon-terminal-line::before,
.fr-icon-terminal-line::after {
  -webkit-mask-image: url("../../icons/development/terminal-line.svg");
  mask-image: url("../../icons/development/terminal-line.svg");
}

.fr-icon-terminal-window-fill::before,
.fr-icon-terminal-window-fill::after {
  -webkit-mask-image: url("../../icons/development/terminal-window-fill.svg");
  mask-image: url("../../icons/development/terminal-window-fill.svg");
}

.fr-icon-terminal-window-line::before,
.fr-icon-terminal-window-line::after {
  -webkit-mask-image: url("../../icons/development/terminal-window-line.svg");
  mask-image: url("../../icons/development/terminal-window-line.svg");
}

/* ¯¯¯¯¯¯¯¯¯ *\
  ICON COMMUNICATION
\* ˍˍˍˍˍˍˍˍˍ */
.fr-icon-bluetooth-fill::before,
.fr-icon-bluetooth-fill::after {
  -webkit-mask-image: url("../../icons/device/bluetooth-fill.svg");
  mask-image: url("../../icons/device/bluetooth-fill.svg");
}

.fr-icon-bluetooth-line::before,
.fr-icon-bluetooth-line::after {
  -webkit-mask-image: url("../../icons/device/bluetooth-line.svg");
  mask-image: url("../../icons/device/bluetooth-line.svg");
}

.fr-icon-computer-fill::before,
.fr-icon-computer-fill::after {
  -webkit-mask-image: url("../../icons/device/computer-fill.svg");
  mask-image: url("../../icons/device/computer-fill.svg");
}

.fr-icon-computer-line::before,
.fr-icon-computer-line::after {
  -webkit-mask-image: url("../../icons/device/computer-line.svg");
  mask-image: url("../../icons/device/computer-line.svg");
}

.fr-icon-dashboard-3-fill::before,
.fr-icon-dashboard-3-fill::after {
  -webkit-mask-image: url("../../icons/device/dashboard-3-fill.svg");
  mask-image: url("../../icons/device/dashboard-3-fill.svg");
}

.fr-icon-dashboard-3-line::before,
.fr-icon-dashboard-3-line::after {
  -webkit-mask-image: url("../../icons/device/dashboard-3-line.svg");
  mask-image: url("../../icons/device/dashboard-3-line.svg");
}

.fr-icon-database-fill::before,
.fr-icon-database-fill::after {
  -webkit-mask-image: url("../../icons/device/database-fill.svg");
  mask-image: url("../../icons/device/database-fill.svg");
}

.fr-icon-database-line::before,
.fr-icon-database-line::after {
  -webkit-mask-image: url("../../icons/device/database-line.svg");
  mask-image: url("../../icons/device/database-line.svg");
}

.fr-icon-device-fill::before,
.fr-icon-device-fill::after {
  -webkit-mask-image: url("../../icons/device/device-fill.svg");
  mask-image: url("../../icons/device/device-fill.svg");
}

.fr-icon-device-line::before,
.fr-icon-device-line::after {
  -webkit-mask-image: url("../../icons/device/device-line.svg");
  mask-image: url("../../icons/device/device-line.svg");
}

.fr-icon-hard-drive-2-fill::before,
.fr-icon-hard-drive-2-fill::after {
  -webkit-mask-image: url("../../icons/device/hard-drive-2-fill.svg");
  mask-image: url("../../icons/device/hard-drive-2-fill.svg");
}

.fr-icon-hard-drive-2-line::before,
.fr-icon-hard-drive-2-line::after {
  -webkit-mask-image: url("../../icons/device/hard-drive-2-line.svg");
  mask-image: url("../../icons/device/hard-drive-2-line.svg");
}

.fr-icon-mac-fill::before,
.fr-icon-mac-fill::after {
  -webkit-mask-image: url("../../icons/device/mac-fill.svg");
  mask-image: url("../../icons/device/mac-fill.svg");
}

.fr-icon-mac-line::before,
.fr-icon-mac-line::after {
  -webkit-mask-image: url("../../icons/device/mac-line.svg");
  mask-image: url("../../icons/device/mac-line.svg");
}

.fr-icon-phone-fill::before,
.fr-icon-phone-fill::after {
  -webkit-mask-image: url("../../icons/device/phone-fill.svg");
  mask-image: url("../../icons/device/phone-fill.svg");
}

.fr-icon-phone-line::before,
.fr-icon-phone-line::after {
  -webkit-mask-image: url("../../icons/device/phone-line.svg");
  mask-image: url("../../icons/device/phone-line.svg");
}

.fr-icon-qr-code-fill::before,
.fr-icon-qr-code-fill::after {
  -webkit-mask-image: url("../../icons/device/qr-code-fill.svg");
  mask-image: url("../../icons/device/qr-code-fill.svg");
}

.fr-icon-qr-code-line::before,
.fr-icon-qr-code-line::after {
  -webkit-mask-image: url("../../icons/device/qr-code-line.svg");
  mask-image: url("../../icons/device/qr-code-line.svg");
}

.fr-icon-rss-fill::before,
.fr-icon-rss-fill::after {
  -webkit-mask-image: url("../../icons/device/rss-fill.svg");
  mask-image: url("../../icons/device/rss-fill.svg");
}

.fr-icon-rss-line::before,
.fr-icon-rss-line::after {
  -webkit-mask-image: url("../../icons/device/rss-line.svg");
  mask-image: url("../../icons/device/rss-line.svg");
}

.fr-icon-save-3-fill::before,
.fr-icon-save-3-fill::after {
  -webkit-mask-image: url("../../icons/device/save-3-fill.svg");
  mask-image: url("../../icons/device/save-3-fill.svg");
}

.fr-icon-save-3-line::before,
.fr-icon-save-3-line::after {
  -webkit-mask-image: url("../../icons/device/save-3-line.svg");
  mask-image: url("../../icons/device/save-3-line.svg");
}

.fr-icon-save-fill::before,
.fr-icon-save-fill::after {
  -webkit-mask-image: url("../../icons/device/save-fill.svg");
  mask-image: url("../../icons/device/save-fill.svg");
}

.fr-icon-save-line::before,
.fr-icon-save-line::after {
  -webkit-mask-image: url("../../icons/device/save-line.svg");
  mask-image: url("../../icons/device/save-line.svg");
}

.fr-icon-server-fill::before,
.fr-icon-server-fill::after {
  -webkit-mask-image: url("../../icons/device/server-fill.svg");
  mask-image: url("../../icons/device/server-fill.svg");
}

.fr-icon-server-line::before,
.fr-icon-server-line::after {
  -webkit-mask-image: url("../../icons/device/server-line.svg");
  mask-image: url("../../icons/device/server-line.svg");
}

.fr-icon-smartphone-fill::before,
.fr-icon-smartphone-fill::after {
  -webkit-mask-image: url("../../icons/device/smartphone-fill.svg");
  mask-image: url("../../icons/device/smartphone-fill.svg");
}

.fr-icon-smartphone-line::before,
.fr-icon-smartphone-line::after {
  -webkit-mask-image: url("../../icons/device/smartphone-line.svg");
  mask-image: url("../../icons/device/smartphone-line.svg");
}

.fr-icon-tablet-fill::before,
.fr-icon-tablet-fill::after {
  -webkit-mask-image: url("../../icons/device/tablet-fill.svg");
  mask-image: url("../../icons/device/tablet-fill.svg");
}

.fr-icon-tablet-line::before,
.fr-icon-tablet-line::after {
  -webkit-mask-image: url("../../icons/device/tablet-line.svg");
  mask-image: url("../../icons/device/tablet-line.svg");
}

.fr-icon-tv-fill::before,
.fr-icon-tv-fill::after {
  -webkit-mask-image: url("../../icons/device/tv-fill.svg");
  mask-image: url("../../icons/device/tv-fill.svg");
}

.fr-icon-tv-line::before,
.fr-icon-tv-line::after {
  -webkit-mask-image: url("../../icons/device/tv-line.svg");
  mask-image: url("../../icons/device/tv-line.svg");
}

.fr-icon-wifi-fill::before,
.fr-icon-wifi-fill::after {
  -webkit-mask-image: url("../../icons/device/wifi-fill.svg");
  mask-image: url("../../icons/device/wifi-fill.svg");
}

.fr-icon-wifi-line::before,
.fr-icon-wifi-line::after {
  -webkit-mask-image: url("../../icons/device/wifi-line.svg");
  mask-image: url("../../icons/device/wifi-line.svg");
}

.fr-fi-phone-fill::before,
.fr-fi-phone-fill::after {
  -webkit-mask-image: url("../../icons/device/phone-fill.svg");
  mask-image: url("../../icons/device/phone-fill.svg");
}

.fr-fi-phone-line::before,
.fr-fi-phone-line::after {
  -webkit-mask-image: url("../../icons/device/phone-line.svg");
  mask-image: url("../../icons/device/phone-line.svg");
}

.fr-fi-rss-fill::before,
.fr-fi-rss-fill::after {
  -webkit-mask-image: url("../../icons/device/rss-fill.svg");
  mask-image: url("../../icons/device/rss-fill.svg");
}

.fr-fi-rss-line::before,
.fr-fi-rss-line::after {
  -webkit-mask-image: url("../../icons/device/rss-line.svg");
  mask-image: url("../../icons/device/rss-line.svg");
}

.fr-fi-save-fill::before,
.fr-fi-save-fill::after {
  -webkit-mask-image: url("../../icons/device/save-fill.svg");
  mask-image: url("../../icons/device/save-fill.svg");
}

.fr-fi-save-line::before,
.fr-fi-save-line::after {
  -webkit-mask-image: url("../../icons/device/save-line.svg");
  mask-image: url("../../icons/device/save-line.svg");
}

/* ¯¯¯¯¯¯¯¯¯ *\
  ICON COMMUNICATION
\* ˍˍˍˍˍˍˍˍˍ */
.fr-icon-article-fill::before,
.fr-icon-article-fill::after {
  -webkit-mask-image: url("../../icons/document/article-fill.svg");
  mask-image: url("../../icons/document/article-fill.svg");
}

.fr-icon-article-line::before,
.fr-icon-article-line::after {
  -webkit-mask-image: url("../../icons/document/article-line.svg");
  mask-image: url("../../icons/document/article-line.svg");
}

.fr-icon-book-2-fill::before,
.fr-icon-book-2-fill::after {
  -webkit-mask-image: url("../../icons/document/book-2-fill.svg");
  mask-image: url("../../icons/document/book-2-fill.svg");
}

.fr-icon-book-2-line::before,
.fr-icon-book-2-line::after {
  -webkit-mask-image: url("../../icons/document/book-2-line.svg");
  mask-image: url("../../icons/document/book-2-line.svg");
}

.fr-icon-booklet-fill::before,
.fr-icon-booklet-fill::after {
  -webkit-mask-image: url("../../icons/document/booklet-fill.svg");
  mask-image: url("../../icons/document/booklet-fill.svg");
}

.fr-icon-booklet-line::before,
.fr-icon-booklet-line::after {
  -webkit-mask-image: url("../../icons/document/booklet-line.svg");
  mask-image: url("../../icons/document/booklet-line.svg");
}

.fr-icon-clipboard-fill::before,
.fr-icon-clipboard-fill::after {
  -webkit-mask-image: url("../../icons/document/clipboard-fill.svg");
  mask-image: url("../../icons/document/clipboard-fill.svg");
}

.fr-icon-clipboard-line::before,
.fr-icon-clipboard-line::after {
  -webkit-mask-image: url("../../icons/document/clipboard-line.svg");
  mask-image: url("../../icons/document/clipboard-line.svg");
}

.fr-icon-draft-fill::before,
.fr-icon-draft-fill::after {
  -webkit-mask-image: url("../../icons/document/draft-fill.svg");
  mask-image: url("../../icons/document/draft-fill.svg");
}

.fr-icon-draft-line::before,
.fr-icon-draft-line::after {
  -webkit-mask-image: url("../../icons/document/draft-line.svg");
  mask-image: url("../../icons/document/draft-line.svg");
}

.fr-icon-file-add-fill::before,
.fr-icon-file-add-fill::after {
  -webkit-mask-image: url("../../icons/document/file-add-fill.svg");
  mask-image: url("../../icons/document/file-add-fill.svg");
}

.fr-icon-file-add-line::before,
.fr-icon-file-add-line::after {
  -webkit-mask-image: url("../../icons/document/file-add-line.svg");
  mask-image: url("../../icons/document/file-add-line.svg");
}

.fr-icon-file-download-fill::before,
.fr-icon-file-download-fill::after {
  -webkit-mask-image: url("../../icons/document/file-download-fill.svg");
  mask-image: url("../../icons/document/file-download-fill.svg");
}

.fr-icon-file-download-line::before,
.fr-icon-file-download-line::after {
  -webkit-mask-image: url("../../icons/document/file-download-line.svg");
  mask-image: url("../../icons/document/file-download-line.svg");
}

.fr-icon-file-fill::before,
.fr-icon-file-fill::after {
  -webkit-mask-image: url("../../icons/document/file-fill.svg");
  mask-image: url("../../icons/document/file-fill.svg");
}

.fr-icon-file-line::before,
.fr-icon-file-line::after {
  -webkit-mask-image: url("../../icons/document/file-line.svg");
  mask-image: url("../../icons/document/file-line.svg");
}

.fr-icon-file-pdf-fill::before,
.fr-icon-file-pdf-fill::after {
  -webkit-mask-image: url("../../icons/document/file-pdf-fill.svg");
  mask-image: url("../../icons/document/file-pdf-fill.svg");
}

.fr-icon-file-pdf-line::before,
.fr-icon-file-pdf-line::after {
  -webkit-mask-image: url("../../icons/document/file-pdf-line.svg");
  mask-image: url("../../icons/document/file-pdf-line.svg");
}

.fr-icon-file-text-fill::before,
.fr-icon-file-text-fill::after {
  -webkit-mask-image: url("../../icons/document/file-text-fill.svg");
  mask-image: url("../../icons/document/file-text-fill.svg");
}

.fr-icon-file-text-line::before,
.fr-icon-file-text-line::after {
  -webkit-mask-image: url("../../icons/document/file-text-line.svg");
  mask-image: url("../../icons/document/file-text-line.svg");
}

.fr-icon-folder-2-fill::before,
.fr-icon-folder-2-fill::after {
  -webkit-mask-image: url("../../icons/document/folder-2-fill.svg");
  mask-image: url("../../icons/document/folder-2-fill.svg");
}

.fr-icon-folder-2-line::before,
.fr-icon-folder-2-line::after {
  -webkit-mask-image: url("../../icons/document/folder-2-line.svg");
  mask-image: url("../../icons/document/folder-2-line.svg");
}

.fr-icon-newspaper-fill::before,
.fr-icon-newspaper-fill::after {
  -webkit-mask-image: url("../../icons/document/newspaper-fill.svg");
  mask-image: url("../../icons/document/newspaper-fill.svg");
}

.fr-icon-newspaper-line::before,
.fr-icon-newspaper-line::after {
  -webkit-mask-image: url("../../icons/document/newspaper-line.svg");
  mask-image: url("../../icons/document/newspaper-line.svg");
}

.fr-icon-survey-fill::before,
.fr-icon-survey-fill::after {
  -webkit-mask-image: url("../../icons/document/survey-fill.svg");
  mask-image: url("../../icons/document/survey-fill.svg");
}

.fr-icon-survey-line::before,
.fr-icon-survey-line::after {
  -webkit-mask-image: url("../../icons/document/survey-line.svg");
  mask-image: url("../../icons/document/survey-line.svg");
}

.fr-icon-todo-fill::before,
.fr-icon-todo-fill::after {
  -webkit-mask-image: url("../../icons/document/todo-fill.svg");
  mask-image: url("../../icons/document/todo-fill.svg");
}

.fr-icon-todo-line::before,
.fr-icon-todo-line::after {
  -webkit-mask-image: url("../../icons/document/todo-line.svg");
  mask-image: url("../../icons/document/todo-line.svg");
}

.fr-fi-file-download-fill::before,
.fr-fi-file-download-fill::after {
  -webkit-mask-image: url("../../icons/document/file-download-fill.svg");
  mask-image: url("../../icons/document/file-download-fill.svg");
}

.fr-fi-file-download-line::before,
.fr-fi-file-download-line::after {
  -webkit-mask-image: url("../../icons/document/file-download-line.svg");
  mask-image: url("../../icons/document/file-download-line.svg");
}

.fr-fi-file-fill::before,
.fr-fi-file-fill::after {
  -webkit-mask-image: url("../../icons/document/file-fill.svg");
  mask-image: url("../../icons/document/file-fill.svg");
}

.fr-fi-file-line::before,
.fr-fi-file-line::after {
  -webkit-mask-image: url("../../icons/document/file-line.svg");
  mask-image: url("../../icons/document/file-line.svg");
}

.fr-fi-file-pdf-fill::before,
.fr-fi-file-pdf-fill::after {
  -webkit-mask-image: url("../../icons/document/file-pdf-fill.svg");
  mask-image: url("../../icons/document/file-pdf-fill.svg");
}

.fr-fi-file-pdf-line::before,
.fr-fi-file-pdf-line::after {
  -webkit-mask-image: url("../../icons/document/file-pdf-line.svg");
  mask-image: url("../../icons/document/file-pdf-line.svg");
}

/* ¯¯¯¯¯¯¯¯¯ *\
  ICON COMMUNICATION
\* ˍˍˍˍˍˍˍˍˍ */
.fr-icon-code-view::before,
.fr-icon-code-view::after {
  -webkit-mask-image: url("../../icons/editor/code-view.svg");
  mask-image: url("../../icons/editor/code-view.svg");
}

.fr-icon-font-size::before,
.fr-icon-font-size::after {
  -webkit-mask-image: url("../../icons/editor/font-size.svg");
  mask-image: url("../../icons/editor/font-size.svg");
}

.fr-icon-bold::before,
.fr-icon-bold::after {
  -webkit-mask-image: url("../../icons/editor/fr--bold.svg");
  mask-image: url("../../icons/editor/fr--bold.svg");
}

.fr-icon-highlight::before,
.fr-icon-highlight::after {
  -webkit-mask-image: url("../../icons/editor/fr--highlight.svg");
  mask-image: url("../../icons/editor/fr--highlight.svg");
}

.fr-icon-quote-fill::before,
.fr-icon-quote-fill::after {
  -webkit-mask-image: url("../../icons/editor/fr--quote-fill.svg");
  mask-image: url("../../icons/editor/fr--quote-fill.svg");
}

.fr-icon-quote-line::before,
.fr-icon-quote-line::after {
  -webkit-mask-image: url("../../icons/editor/fr--quote-line.svg");
  mask-image: url("../../icons/editor/fr--quote-line.svg");
}

.fr-icon-h-1::before,
.fr-icon-h-1::after {
  -webkit-mask-image: url("../../icons/editor/h-1.svg");
  mask-image: url("../../icons/editor/h-1.svg");
}

.fr-icon-h-2::before,
.fr-icon-h-2::after {
  -webkit-mask-image: url("../../icons/editor/h-2.svg");
  mask-image: url("../../icons/editor/h-2.svg");
}

.fr-icon-h-3::before,
.fr-icon-h-3::after {
  -webkit-mask-image: url("../../icons/editor/h-3.svg");
  mask-image: url("../../icons/editor/h-3.svg");
}

.fr-icon-h-4::before,
.fr-icon-h-4::after {
  -webkit-mask-image: url("../../icons/editor/h-4.svg");
  mask-image: url("../../icons/editor/h-4.svg");
}

.fr-icon-h-5::before,
.fr-icon-h-5::after {
  -webkit-mask-image: url("../../icons/editor/h-5.svg");
  mask-image: url("../../icons/editor/h-5.svg");
}

.fr-icon-h-6::before,
.fr-icon-h-6::after {
  -webkit-mask-image: url("../../icons/editor/h-6.svg");
  mask-image: url("../../icons/editor/h-6.svg");
}

.fr-icon-hashtag::before,
.fr-icon-hashtag::after {
  -webkit-mask-image: url("../../icons/editor/hashtag.svg");
  mask-image: url("../../icons/editor/hashtag.svg");
}

.fr-icon-italic::before,
.fr-icon-italic::after {
  -webkit-mask-image: url("../../icons/editor/italic.svg");
  mask-image: url("../../icons/editor/italic.svg");
}

.fr-icon-link-unlink::before,
.fr-icon-link-unlink::after {
  -webkit-mask-image: url("../../icons/editor/link-unlink.svg");
  mask-image: url("../../icons/editor/link-unlink.svg");
}

.fr-icon-link::before,
.fr-icon-link::after {
  -webkit-mask-image: url("../../icons/editor/link.svg");
  mask-image: url("../../icons/editor/link.svg");
}

.fr-icon-list-ordered::before,
.fr-icon-list-ordered::after {
  -webkit-mask-image: url("../../icons/editor/list-ordered.svg");
  mask-image: url("../../icons/editor/list-ordered.svg");
}

.fr-icon-list-unordered::before,
.fr-icon-list-unordered::after {
  -webkit-mask-image: url("../../icons/editor/list-unordered.svg");
  mask-image: url("../../icons/editor/list-unordered.svg");
}

.fr-icon-question-mark::before,
.fr-icon-question-mark::after {
  -webkit-mask-image: url("../../icons/editor/question-mark.svg");
  mask-image: url("../../icons/editor/question-mark.svg");
}

.fr-icon-separator::before,
.fr-icon-separator::after {
  -webkit-mask-image: url("../../icons/editor/separator.svg");
  mask-image: url("../../icons/editor/separator.svg");
}

.fr-icon-space::before,
.fr-icon-space::after {
  -webkit-mask-image: url("../../icons/editor/space.svg");
  mask-image: url("../../icons/editor/space.svg");
}

.fr-icon-subscript::before,
.fr-icon-subscript::after {
  -webkit-mask-image: url("../../icons/editor/subscript.svg");
  mask-image: url("../../icons/editor/subscript.svg");
}

.fr-icon-superscript::before,
.fr-icon-superscript::after {
  -webkit-mask-image: url("../../icons/editor/superscript.svg");
  mask-image: url("../../icons/editor/superscript.svg");
}

.fr-icon-table-2::before,
.fr-icon-table-2::after {
  -webkit-mask-image: url("../../icons/editor/table-2.svg");
  mask-image: url("../../icons/editor/table-2.svg");
}

.fr-icon-translate-2::before,
.fr-icon-translate-2::after {
  -webkit-mask-image: url("../../icons/editor/translate-2.svg");
  mask-image: url("../../icons/editor/translate-2.svg");
}

.fr-fi-font-size::before,
.fr-fi-font-size::after {
  -webkit-mask-image: url("../../icons/editor/font-size.svg");
  mask-image: url("../../icons/editor/font-size.svg");
}

.fr-fi-bold::before,
.fr-fi-bold::after {
  -webkit-mask-image: url("../../icons/editor/fr--bold.svg");
  mask-image: url("../../icons/editor/fr--bold.svg");
}

.fr-fi-highlight::before,
.fr-fi-highlight::after {
  -webkit-mask-image: url("../../icons/editor/fr--highlight.svg");
  mask-image: url("../../icons/editor/fr--highlight.svg");
}

.fr-fi-italic::before,
.fr-fi-italic::after {
  -webkit-mask-image: url("../../icons/editor/italic.svg");
  mask-image: url("../../icons/editor/italic.svg");
}

.fr-fi-link-unlink::before,
.fr-fi-link-unlink::after {
  -webkit-mask-image: url("../../icons/editor/link-unlink.svg");
  mask-image: url("../../icons/editor/link-unlink.svg");
}

.fr-fi-list-ordered::before,
.fr-fi-list-ordered::after {
  -webkit-mask-image: url("../../icons/editor/list-ordered.svg");
  mask-image: url("../../icons/editor/list-ordered.svg");
}

.fr-fi-list-unordered::before,
.fr-fi-list-unordered::after {
  -webkit-mask-image: url("../../icons/editor/list-unordered.svg");
  mask-image: url("../../icons/editor/list-unordered.svg");
}

/* ¯¯¯¯¯¯¯¯¯ *\
  ICON COMMUNICATION
\* ˍˍˍˍˍˍˍˍˍ */
.fr-icon-bank-card-fill::before,
.fr-icon-bank-card-fill::after {
  -webkit-mask-image: url("../../icons/finance/bank-card-fill.svg");
  mask-image: url("../../icons/finance/bank-card-fill.svg");
}

.fr-icon-bank-card-line::before,
.fr-icon-bank-card-line::after {
  -webkit-mask-image: url("../../icons/finance/bank-card-line.svg");
  mask-image: url("../../icons/finance/bank-card-line.svg");
}

.fr-icon-coin-fill::before,
.fr-icon-coin-fill::after {
  -webkit-mask-image: url("../../icons/finance/coin-fill.svg");
  mask-image: url("../../icons/finance/coin-fill.svg");
}

.fr-icon-gift-fill::before,
.fr-icon-gift-fill::after {
  -webkit-mask-image: url("../../icons/finance/gift-fill.svg");
  mask-image: url("../../icons/finance/gift-fill.svg");
}

.fr-icon-gift-line::before,
.fr-icon-gift-line::after {
  -webkit-mask-image: url("../../icons/finance/gift-line.svg");
  mask-image: url("../../icons/finance/gift-line.svg");
}

.fr-icon-money-euro-box-fill::before,
.fr-icon-money-euro-box-fill::after {
  -webkit-mask-image: url("../../icons/finance/money-euro-box-fill.svg");
  mask-image: url("../../icons/finance/money-euro-box-fill.svg");
}

.fr-icon-money-euro-box-line::before,
.fr-icon-money-euro-box-line::after {
  -webkit-mask-image: url("../../icons/finance/money-euro-box-line.svg");
  mask-image: url("../../icons/finance/money-euro-box-line.svg");
}

.fr-icon-money-euro-circle-fill::before,
.fr-icon-money-euro-circle-fill::after {
  -webkit-mask-image: url("../../icons/finance/money-euro-circle-fill.svg");
  mask-image: url("../../icons/finance/money-euro-circle-fill.svg");
}

.fr-icon-money-euro-circle-line::before,
.fr-icon-money-euro-circle-line::after {
  -webkit-mask-image: url("../../icons/finance/money-euro-circle-line.svg");
  mask-image: url("../../icons/finance/money-euro-circle-line.svg");
}

.fr-icon-secure-payment-fill::before,
.fr-icon-secure-payment-fill::after {
  -webkit-mask-image: url("../../icons/finance/secure-payment-fill.svg");
  mask-image: url("../../icons/finance/secure-payment-fill.svg");
}

.fr-icon-secure-payment-line::before,
.fr-icon-secure-payment-line::after {
  -webkit-mask-image: url("../../icons/finance/secure-payment-line.svg");
  mask-image: url("../../icons/finance/secure-payment-line.svg");
}

.fr-icon-shopping-bag-fill::before,
.fr-icon-shopping-bag-fill::after {
  -webkit-mask-image: url("../../icons/finance/shopping-bag-fill.svg");
  mask-image: url("../../icons/finance/shopping-bag-fill.svg");
}

.fr-icon-shopping-bag-line::before,
.fr-icon-shopping-bag-line::after {
  -webkit-mask-image: url("../../icons/finance/shopping-bag-line.svg");
  mask-image: url("../../icons/finance/shopping-bag-line.svg");
}

.fr-icon-shopping-cart-2-fill::before,
.fr-icon-shopping-cart-2-fill::after {
  -webkit-mask-image: url("../../icons/finance/shopping-cart-2-fill.svg");
  mask-image: url("../../icons/finance/shopping-cart-2-fill.svg");
}

.fr-icon-shopping-cart-2-line::before,
.fr-icon-shopping-cart-2-line::after {
  -webkit-mask-image: url("../../icons/finance/shopping-cart-2-line.svg");
  mask-image: url("../../icons/finance/shopping-cart-2-line.svg");
}

.fr-icon-trophy-fill::before,
.fr-icon-trophy-fill::after {
  -webkit-mask-image: url("../../icons/finance/trophy-fill.svg");
  mask-image: url("../../icons/finance/trophy-fill.svg");
}

.fr-icon-trophy-line::before,
.fr-icon-trophy-line::after {
  -webkit-mask-image: url("../../icons/finance/trophy-line.svg");
  mask-image: url("../../icons/finance/trophy-line.svg");
}

/* ¯¯¯¯¯¯¯¯¯ *\
  ICON COMMUNICATION
\* ˍˍˍˍˍˍˍˍˍ */
.fr-icon-capsule-fill::before,
.fr-icon-capsule-fill::after {
  -webkit-mask-image: url("../../icons/health/capsule-fill.svg");
  mask-image: url("../../icons/health/capsule-fill.svg");
}

.fr-icon-capsule-line::before,
.fr-icon-capsule-line::after {
  -webkit-mask-image: url("../../icons/health/capsule-line.svg");
  mask-image: url("../../icons/health/capsule-line.svg");
}

.fr-icon-dislike-fill::before,
.fr-icon-dislike-fill::after {
  -webkit-mask-image: url("../../icons/health/dislike-fill.svg");
  mask-image: url("../../icons/health/dislike-fill.svg");
}

.fr-icon-dislike-line::before,
.fr-icon-dislike-line::after {
  -webkit-mask-image: url("../../icons/health/dislike-line.svg");
  mask-image: url("../../icons/health/dislike-line.svg");
}

.fr-icon-dossier-fill::before,
.fr-icon-dossier-fill::after {
  -webkit-mask-image: url("../../icons/health/dossier-fill.svg");
  mask-image: url("../../icons/health/dossier-fill.svg");
}

.fr-icon-dossier-line::before,
.fr-icon-dossier-line::after {
  -webkit-mask-image: url("../../icons/health/dossier-line.svg");
  mask-image: url("../../icons/health/dossier-line.svg");
}

.fr-icon-first-aid-kit-fill::before,
.fr-icon-first-aid-kit-fill::after {
  -webkit-mask-image: url("../../icons/health/first-aid-kit-fill.svg");
  mask-image: url("../../icons/health/first-aid-kit-fill.svg");
}

.fr-icon-first-aid-kit-line::before,
.fr-icon-first-aid-kit-line::after {
  -webkit-mask-image: url("../../icons/health/first-aid-kit-line.svg");
  mask-image: url("../../icons/health/first-aid-kit-line.svg");
}

.fr-icon-hand-sanitizer-fill::before,
.fr-icon-hand-sanitizer-fill::after {
  -webkit-mask-image: url("../../icons/health/hand-sanitizer-fill.svg");
  mask-image: url("../../icons/health/hand-sanitizer-fill.svg");
}

.fr-icon-hand-sanitizer-line::before,
.fr-icon-hand-sanitizer-line::after {
  -webkit-mask-image: url("../../icons/health/hand-sanitizer-line.svg");
  mask-image: url("../../icons/health/hand-sanitizer-line.svg");
}

.fr-icon-health-book-fill::before,
.fr-icon-health-book-fill::after {
  -webkit-mask-image: url("../../icons/health/health-book-fill.svg");
  mask-image: url("../../icons/health/health-book-fill.svg");
}

.fr-icon-health-book-line::before,
.fr-icon-health-book-line::after {
  -webkit-mask-image: url("../../icons/health/health-book-line.svg");
  mask-image: url("../../icons/health/health-book-line.svg");
}

.fr-icon-heart-fill::before,
.fr-icon-heart-fill::after {
  -webkit-mask-image: url("../../icons/health/heart-fill.svg");
  mask-image: url("../../icons/health/heart-fill.svg");
}

.fr-icon-heart-line::before,
.fr-icon-heart-line::after {
  -webkit-mask-image: url("../../icons/health/heart-line.svg");
  mask-image: url("../../icons/health/heart-line.svg");
}

.fr-icon-heart-pulse-fill::before,
.fr-icon-heart-pulse-fill::after {
  -webkit-mask-image: url("../../icons/health/heart-pulse-fill.svg");
  mask-image: url("../../icons/health/heart-pulse-fill.svg");
}

.fr-icon-heart-pulse-line::before,
.fr-icon-heart-pulse-line::after {
  -webkit-mask-image: url("../../icons/health/heart-pulse-line.svg");
  mask-image: url("../../icons/health/heart-pulse-line.svg");
}

.fr-icon-lungs-fill::before,
.fr-icon-lungs-fill::after {
  -webkit-mask-image: url("../../icons/health/lungs-fill.svg");
  mask-image: url("../../icons/health/lungs-fill.svg");
}

.fr-icon-lungs-line::before,
.fr-icon-lungs-line::after {
  -webkit-mask-image: url("../../icons/health/lungs-line.svg");
  mask-image: url("../../icons/health/lungs-line.svg");
}

.fr-icon-medicine-bottle-fill::before,
.fr-icon-medicine-bottle-fill::after {
  -webkit-mask-image: url("../../icons/health/medicine-bottle-fill.svg");
  mask-image: url("../../icons/health/medicine-bottle-fill.svg");
}

.fr-icon-medicine-bottle-line::before,
.fr-icon-medicine-bottle-line::after {
  -webkit-mask-image: url("../../icons/health/medicine-bottle-line.svg");
  mask-image: url("../../icons/health/medicine-bottle-line.svg");
}

.fr-icon-mental-health-fill::before,
.fr-icon-mental-health-fill::after {
  -webkit-mask-image: url("../../icons/health/mental-health-fill.svg");
  mask-image: url("../../icons/health/mental-health-fill.svg");
}

.fr-icon-mental-health-line::before,
.fr-icon-mental-health-line::after {
  -webkit-mask-image: url("../../icons/health/mental-health-line.svg");
  mask-image: url("../../icons/health/mental-health-line.svg");
}

.fr-icon-microscope-fill::before,
.fr-icon-microscope-fill::after {
  -webkit-mask-image: url("../../icons/health/microscope-fill.svg");
  mask-image: url("../../icons/health/microscope-fill.svg");
}

.fr-icon-microscope-line::before,
.fr-icon-microscope-line::after {
  -webkit-mask-image: url("../../icons/health/microscope-line.svg");
  mask-image: url("../../icons/health/microscope-line.svg");
}

.fr-icon-psychotherapy-fill::before,
.fr-icon-psychotherapy-fill::after {
  -webkit-mask-image: url("../../icons/health/psychotherapy-fill.svg");
  mask-image: url("../../icons/health/psychotherapy-fill.svg");
}

.fr-icon-psychotherapy-line::before,
.fr-icon-psychotherapy-line::after {
  -webkit-mask-image: url("../../icons/health/psychotherapy-line.svg");
  mask-image: url("../../icons/health/psychotherapy-line.svg");
}

.fr-icon-pulse-line::before,
.fr-icon-pulse-line::after {
  -webkit-mask-image: url("../../icons/health/pulse-line.svg");
  mask-image: url("../../icons/health/pulse-line.svg");
}

.fr-icon-stethoscope-fill::before,
.fr-icon-stethoscope-fill::after {
  -webkit-mask-image: url("../../icons/health/stethoscope-fill.svg");
  mask-image: url("../../icons/health/stethoscope-fill.svg");
}

.fr-icon-stethoscope-line::before,
.fr-icon-stethoscope-line::after {
  -webkit-mask-image: url("../../icons/health/stethoscope-line.svg");
  mask-image: url("../../icons/health/stethoscope-line.svg");
}

.fr-icon-surgical-mask-fill::before,
.fr-icon-surgical-mask-fill::after {
  -webkit-mask-image: url("../../icons/health/surgical-mask-fill.svg");
  mask-image: url("../../icons/health/surgical-mask-fill.svg");
}

.fr-icon-surgical-mask-line::before,
.fr-icon-surgical-mask-line::after {
  -webkit-mask-image: url("../../icons/health/surgical-mask-line.svg");
  mask-image: url("../../icons/health/surgical-mask-line.svg");
}

.fr-icon-syringe-fill::before,
.fr-icon-syringe-fill::after {
  -webkit-mask-image: url("../../icons/health/syringe-fill.svg");
  mask-image: url("../../icons/health/syringe-fill.svg");
}

.fr-icon-syringe-line::before,
.fr-icon-syringe-line::after {
  -webkit-mask-image: url("../../icons/health/syringe-line.svg");
  mask-image: url("../../icons/health/syringe-line.svg");
}

.fr-icon-test-tube-fill::before,
.fr-icon-test-tube-fill::after {
  -webkit-mask-image: url("../../icons/health/test-tube-fill.svg");
  mask-image: url("../../icons/health/test-tube-fill.svg");
}

.fr-icon-test-tube-line::before,
.fr-icon-test-tube-line::after {
  -webkit-mask-image: url("../../icons/health/test-tube-line.svg");
  mask-image: url("../../icons/health/test-tube-line.svg");
}

.fr-icon-thermometer-fill::before,
.fr-icon-thermometer-fill::after {
  -webkit-mask-image: url("../../icons/health/thermometer-fill.svg");
  mask-image: url("../../icons/health/thermometer-fill.svg");
}

.fr-icon-thermometer-line::before,
.fr-icon-thermometer-line::after {
  -webkit-mask-image: url("../../icons/health/thermometer-line.svg");
  mask-image: url("../../icons/health/thermometer-line.svg");
}

.fr-icon-virus-fill::before,
.fr-icon-virus-fill::after {
  -webkit-mask-image: url("../../icons/health/virus-fill.svg");
  mask-image: url("../../icons/health/virus-fill.svg");
}

.fr-icon-virus-line::before,
.fr-icon-virus-line::after {
  -webkit-mask-image: url("../../icons/health/virus-line.svg");
  mask-image: url("../../icons/health/virus-line.svg");
}

/* ¯¯¯¯¯¯¯¯¯ *\
  ICON COMMUNICATION
\* ˍˍˍˍˍˍˍˍˍ */
.fr-icon-chrome-fill::before,
.fr-icon-chrome-fill::after {
  -webkit-mask-image: url("../../icons/logo/chrome-fill.svg");
  mask-image: url("../../icons/logo/chrome-fill.svg");
}

.fr-icon-chrome-line::before,
.fr-icon-chrome-line::after {
  -webkit-mask-image: url("../../icons/logo/chrome-line.svg");
  mask-image: url("../../icons/logo/chrome-line.svg");
}

.fr-icon-edge-fill::before,
.fr-icon-edge-fill::after {
  -webkit-mask-image: url("../../icons/logo/edge-fill.svg");
  mask-image: url("../../icons/logo/edge-fill.svg");
}

.fr-icon-edge-line::before,
.fr-icon-edge-line::after {
  -webkit-mask-image: url("../../icons/logo/edge-line.svg");
  mask-image: url("../../icons/logo/edge-line.svg");
}

.fr-icon-facebook-circle-fill::before,
.fr-icon-facebook-circle-fill::after {
  -webkit-mask-image: url("../../icons/logo/facebook-circle-fill.svg");
  mask-image: url("../../icons/logo/facebook-circle-fill.svg");
}

.fr-icon-facebook-circle-line::before,
.fr-icon-facebook-circle-line::after {
  -webkit-mask-image: url("../../icons/logo/facebook-circle-line.svg");
  mask-image: url("../../icons/logo/facebook-circle-line.svg");
}

.fr-icon-firefox-fill::before,
.fr-icon-firefox-fill::after {
  -webkit-mask-image: url("../../icons/logo/firefox-fill.svg");
  mask-image: url("../../icons/logo/firefox-fill.svg");
}

.fr-icon-firefox-line::before,
.fr-icon-firefox-line::after {
  -webkit-mask-image: url("../../icons/logo/firefox-line.svg");
  mask-image: url("../../icons/logo/firefox-line.svg");
}

.fr-icon-dailymotion-fill::before,
.fr-icon-dailymotion-fill::after {
  -webkit-mask-image: url("../../icons/logo/fr--dailymotion-fill.svg");
  mask-image: url("../../icons/logo/fr--dailymotion-fill.svg");
}

.fr-icon-dailymotion-line::before,
.fr-icon-dailymotion-line::after {
  -webkit-mask-image: url("../../icons/logo/fr--dailymotion-line.svg");
  mask-image: url("../../icons/logo/fr--dailymotion-line.svg");
}

.fr-icon-tiktok-fill::before,
.fr-icon-tiktok-fill::after {
  -webkit-mask-image: url("../../icons/logo/fr--tiktok-fill.svg");
  mask-image: url("../../icons/logo/fr--tiktok-fill.svg");
}

.fr-icon-tiktok-line::before,
.fr-icon-tiktok-line::after {
  -webkit-mask-image: url("../../icons/logo/fr--tiktok-line.svg");
  mask-image: url("../../icons/logo/fr--tiktok-line.svg");
}

.fr-icon-github-fill::before,
.fr-icon-github-fill::after {
  -webkit-mask-image: url("../../icons/logo/github-fill.svg");
  mask-image: url("../../icons/logo/github-fill.svg");
}

.fr-icon-github-line::before,
.fr-icon-github-line::after {
  -webkit-mask-image: url("../../icons/logo/github-line.svg");
  mask-image: url("../../icons/logo/github-line.svg");
}

.fr-icon-google-fill::before,
.fr-icon-google-fill::after {
  -webkit-mask-image: url("../../icons/logo/google-fill.svg");
  mask-image: url("../../icons/logo/google-fill.svg");
}

.fr-icon-google-line::before,
.fr-icon-google-line::after {
  -webkit-mask-image: url("../../icons/logo/google-line.svg");
  mask-image: url("../../icons/logo/google-line.svg");
}

.fr-icon-ie-fill::before,
.fr-icon-ie-fill::after {
  -webkit-mask-image: url("../../icons/logo/ie-fill.svg");
  mask-image: url("../../icons/logo/ie-fill.svg");
}

.fr-icon-ie-line::before,
.fr-icon-ie-line::after {
  -webkit-mask-image: url("../../icons/logo/ie-line.svg");
  mask-image: url("../../icons/logo/ie-line.svg");
}

.fr-icon-instagram-fill::before,
.fr-icon-instagram-fill::after {
  -webkit-mask-image: url("../../icons/logo/instagram-fill.svg");
  mask-image: url("../../icons/logo/instagram-fill.svg");
}

.fr-icon-instagram-line::before,
.fr-icon-instagram-line::after {
  -webkit-mask-image: url("../../icons/logo/instagram-line.svg");
  mask-image: url("../../icons/logo/instagram-line.svg");
}

.fr-icon-linkedin-box-fill::before,
.fr-icon-linkedin-box-fill::after {
  -webkit-mask-image: url("../../icons/logo/linkedin-box-fill.svg");
  mask-image: url("../../icons/logo/linkedin-box-fill.svg");
}

.fr-icon-linkedin-box-line::before,
.fr-icon-linkedin-box-line::after {
  -webkit-mask-image: url("../../icons/logo/linkedin-box-line.svg");
  mask-image: url("../../icons/logo/linkedin-box-line.svg");
}

.fr-icon-mastodon-fill::before,
.fr-icon-mastodon-fill::after {
  -webkit-mask-image: url("../../icons/logo/mastodon-fill.svg");
  mask-image: url("../../icons/logo/mastodon-fill.svg");
}

.fr-icon-mastodon-line::before,
.fr-icon-mastodon-line::after {
  -webkit-mask-image: url("../../icons/logo/mastodon-line.svg");
  mask-image: url("../../icons/logo/mastodon-line.svg");
}

.fr-icon-npmjs-fill::before,
.fr-icon-npmjs-fill::after {
  -webkit-mask-image: url("../../icons/logo/npmjs-fill.svg");
  mask-image: url("../../icons/logo/npmjs-fill.svg");
}

.fr-icon-npmjs-line::before,
.fr-icon-npmjs-line::after {
  -webkit-mask-image: url("../../icons/logo/npmjs-line.svg");
  mask-image: url("../../icons/logo/npmjs-line.svg");
}

.fr-icon-remixicon-fill::before,
.fr-icon-remixicon-fill::after {
  -webkit-mask-image: url("../../icons/logo/remixicon-fill.svg");
  mask-image: url("../../icons/logo/remixicon-fill.svg");
}

.fr-icon-remixicon-line::before,
.fr-icon-remixicon-line::after {
  -webkit-mask-image: url("../../icons/logo/remixicon-line.svg");
  mask-image: url("../../icons/logo/remixicon-line.svg");
}

.fr-icon-safari-fill::before,
.fr-icon-safari-fill::after {
  -webkit-mask-image: url("../../icons/logo/safari-fill.svg");
  mask-image: url("../../icons/logo/safari-fill.svg");
}

.fr-icon-safari-line::before,
.fr-icon-safari-line::after {
  -webkit-mask-image: url("../../icons/logo/safari-line.svg");
  mask-image: url("../../icons/logo/safari-line.svg");
}

.fr-icon-slack-fill::before,
.fr-icon-slack-fill::after {
  -webkit-mask-image: url("../../icons/logo/slack-fill.svg");
  mask-image: url("../../icons/logo/slack-fill.svg");
}

.fr-icon-slack-line::before,
.fr-icon-slack-line::after {
  -webkit-mask-image: url("../../icons/logo/slack-line.svg");
  mask-image: url("../../icons/logo/slack-line.svg");
}

.fr-icon-snapchat-fill::before,
.fr-icon-snapchat-fill::after {
  -webkit-mask-image: url("../../icons/logo/snapchat-fill.svg");
  mask-image: url("../../icons/logo/snapchat-fill.svg");
}

.fr-icon-snapchat-line::before,
.fr-icon-snapchat-line::after {
  -webkit-mask-image: url("../../icons/logo/snapchat-line.svg");
  mask-image: url("../../icons/logo/snapchat-line.svg");
}

.fr-icon-telegram-fill::before,
.fr-icon-telegram-fill::after {
  -webkit-mask-image: url("../../icons/logo/telegram-fill.svg");
  mask-image: url("../../icons/logo/telegram-fill.svg");
}

.fr-icon-telegram-line::before,
.fr-icon-telegram-line::after {
  -webkit-mask-image: url("../../icons/logo/telegram-line.svg");
  mask-image: url("../../icons/logo/telegram-line.svg");
}

.fr-icon-threads-fill::before,
.fr-icon-threads-fill::after {
  -webkit-mask-image: url("../../icons/logo/threads-fill.svg");
  mask-image: url("../../icons/logo/threads-fill.svg");
}

.fr-icon-threads-line::before,
.fr-icon-threads-line::after {
  -webkit-mask-image: url("../../icons/logo/threads-line.svg");
  mask-image: url("../../icons/logo/threads-line.svg");
}

.fr-icon-twitch-fill::before,
.fr-icon-twitch-fill::after {
  -webkit-mask-image: url("../../icons/logo/twitch-fill.svg");
  mask-image: url("../../icons/logo/twitch-fill.svg");
}

.fr-icon-twitch-line::before,
.fr-icon-twitch-line::after {
  -webkit-mask-image: url("../../icons/logo/twitch-line.svg");
  mask-image: url("../../icons/logo/twitch-line.svg");
}

.fr-icon-twitter-fill::before,
.fr-icon-twitter-fill::after {
  -webkit-mask-image: url("../../icons/logo/twitter-fill.svg");
  mask-image: url("../../icons/logo/twitter-fill.svg");
}

.fr-icon-twitter-line::before,
.fr-icon-twitter-line::after {
  -webkit-mask-image: url("../../icons/logo/twitter-line.svg");
  mask-image: url("../../icons/logo/twitter-line.svg");
}

.fr-icon-twitter-x-fill::before,
.fr-icon-twitter-x-fill::after {
  -webkit-mask-image: url("../../icons/logo/twitter-x-fill.svg");
  mask-image: url("../../icons/logo/twitter-x-fill.svg");
}

.fr-icon-twitter-x-line::before,
.fr-icon-twitter-x-line::after {
  -webkit-mask-image: url("../../icons/logo/twitter-x-line.svg");
  mask-image: url("../../icons/logo/twitter-x-line.svg");
}

.fr-icon-vimeo-fill::before,
.fr-icon-vimeo-fill::after {
  -webkit-mask-image: url("../../icons/logo/vimeo-fill.svg");
  mask-image: url("../../icons/logo/vimeo-fill.svg");
}

.fr-icon-vimeo-line::before,
.fr-icon-vimeo-line::after {
  -webkit-mask-image: url("../../icons/logo/vimeo-line.svg");
  mask-image: url("../../icons/logo/vimeo-line.svg");
}

.fr-icon-vuejs-fill::before,
.fr-icon-vuejs-fill::after {
  -webkit-mask-image: url("../../icons/logo/vuejs-fill.svg");
  mask-image: url("../../icons/logo/vuejs-fill.svg");
}

.fr-icon-vuejs-line::before,
.fr-icon-vuejs-line::after {
  -webkit-mask-image: url("../../icons/logo/vuejs-line.svg");
  mask-image: url("../../icons/logo/vuejs-line.svg");
}

.fr-icon-whatsapp-fill::before,
.fr-icon-whatsapp-fill::after {
  -webkit-mask-image: url("../../icons/logo/whatsapp-fill.svg");
  mask-image: url("../../icons/logo/whatsapp-fill.svg");
}

.fr-icon-whatsapp-line::before,
.fr-icon-whatsapp-line::after {
  -webkit-mask-image: url("../../icons/logo/whatsapp-line.svg");
  mask-image: url("../../icons/logo/whatsapp-line.svg");
}

.fr-icon-youtube-fill::before,
.fr-icon-youtube-fill::after {
  -webkit-mask-image: url("../../icons/logo/youtube-fill.svg");
  mask-image: url("../../icons/logo/youtube-fill.svg");
}

.fr-icon-youtube-line::before,
.fr-icon-youtube-line::after {
  -webkit-mask-image: url("../../icons/logo/youtube-line.svg");
  mask-image: url("../../icons/logo/youtube-line.svg");
}

.fr-fi-facebook-circle-fill::before,
.fr-fi-facebook-circle-fill::after {
  -webkit-mask-image: url("../../icons/logo/facebook-circle-fill.svg");
  mask-image: url("../../icons/logo/facebook-circle-fill.svg");
}

.fr-fi-facebook-circle-line::before,
.fr-fi-facebook-circle-line::after {
  -webkit-mask-image: url("../../icons/logo/facebook-circle-line.svg");
  mask-image: url("../../icons/logo/facebook-circle-line.svg");
}

.fr-fi-dailymotion-fill::before,
.fr-fi-dailymotion-fill::after {
  -webkit-mask-image: url("../../icons/logo/fr--dailymotion-fill.svg");
  mask-image: url("../../icons/logo/fr--dailymotion-fill.svg");
}

.fr-fi-dailymotion-line::before,
.fr-fi-dailymotion-line::after {
  -webkit-mask-image: url("../../icons/logo/fr--dailymotion-line.svg");
  mask-image: url("../../icons/logo/fr--dailymotion-line.svg");
}

.fr-fi-github-fill::before,
.fr-fi-github-fill::after {
  -webkit-mask-image: url("../../icons/logo/github-fill.svg");
  mask-image: url("../../icons/logo/github-fill.svg");
}

.fr-fi-github-line::before,
.fr-fi-github-line::after {
  -webkit-mask-image: url("../../icons/logo/github-line.svg");
  mask-image: url("../../icons/logo/github-line.svg");
}

.fr-fi-instagram-fill::before,
.fr-fi-instagram-fill::after {
  -webkit-mask-image: url("../../icons/logo/instagram-fill.svg");
  mask-image: url("../../icons/logo/instagram-fill.svg");
}

.fr-fi-instagram-line::before,
.fr-fi-instagram-line::after {
  -webkit-mask-image: url("../../icons/logo/instagram-line.svg");
  mask-image: url("../../icons/logo/instagram-line.svg");
}

.fr-fi-linkedin-box-fill::before,
.fr-fi-linkedin-box-fill::after {
  -webkit-mask-image: url("../../icons/logo/linkedin-box-fill.svg");
  mask-image: url("../../icons/logo/linkedin-box-fill.svg");
}

.fr-fi-linkedin-box-line::before,
.fr-fi-linkedin-box-line::after {
  -webkit-mask-image: url("../../icons/logo/linkedin-box-line.svg");
  mask-image: url("../../icons/logo/linkedin-box-line.svg");
}

.fr-fi-npmjs-fill::before,
.fr-fi-npmjs-fill::after {
  -webkit-mask-image: url("../../icons/logo/npmjs-fill.svg");
  mask-image: url("../../icons/logo/npmjs-fill.svg");
}

.fr-fi-npmjs-line::before,
.fr-fi-npmjs-line::after {
  -webkit-mask-image: url("../../icons/logo/npmjs-line.svg");
  mask-image: url("../../icons/logo/npmjs-line.svg");
}

.fr-fi-remixicon-fill::before,
.fr-fi-remixicon-fill::after {
  -webkit-mask-image: url("../../icons/logo/remixicon-fill.svg");
  mask-image: url("../../icons/logo/remixicon-fill.svg");
}

.fr-fi-remixicon-line::before,
.fr-fi-remixicon-line::after {
  -webkit-mask-image: url("../../icons/logo/remixicon-line.svg");
  mask-image: url("../../icons/logo/remixicon-line.svg");
}

.fr-fi-slack-fill::before,
.fr-fi-slack-fill::after {
  -webkit-mask-image: url("../../icons/logo/slack-fill.svg");
  mask-image: url("../../icons/logo/slack-fill.svg");
}

.fr-fi-slack-line::before,
.fr-fi-slack-line::after {
  -webkit-mask-image: url("../../icons/logo/slack-line.svg");
  mask-image: url("../../icons/logo/slack-line.svg");
}

.fr-fi-snapchat-fill::before,
.fr-fi-snapchat-fill::after {
  -webkit-mask-image: url("../../icons/logo/snapchat-fill.svg");
  mask-image: url("../../icons/logo/snapchat-fill.svg");
}

.fr-fi-snapchat-line::before,
.fr-fi-snapchat-line::after {
  -webkit-mask-image: url("../../icons/logo/snapchat-line.svg");
  mask-image: url("../../icons/logo/snapchat-line.svg");
}

.fr-fi-telegram-fill::before,
.fr-fi-telegram-fill::after {
  -webkit-mask-image: url("../../icons/logo/telegram-fill.svg");
  mask-image: url("../../icons/logo/telegram-fill.svg");
}

.fr-fi-telegram-line::before,
.fr-fi-telegram-line::after {
  -webkit-mask-image: url("../../icons/logo/telegram-line.svg");
  mask-image: url("../../icons/logo/telegram-line.svg");
}

.fr-fi-twitch-fill::before,
.fr-fi-twitch-fill::after {
  -webkit-mask-image: url("../../icons/logo/twitch-fill.svg");
  mask-image: url("../../icons/logo/twitch-fill.svg");
}

.fr-fi-twitch-line::before,
.fr-fi-twitch-line::after {
  -webkit-mask-image: url("../../icons/logo/twitch-line.svg");
  mask-image: url("../../icons/logo/twitch-line.svg");
}

.fr-fi-twitter-fill::before,
.fr-fi-twitter-fill::after {
  -webkit-mask-image: url("../../icons/logo/twitter-fill.svg");
  mask-image: url("../../icons/logo/twitter-fill.svg");
}

.fr-fi-twitter-line::before,
.fr-fi-twitter-line::after {
  -webkit-mask-image: url("../../icons/logo/twitter-line.svg");
  mask-image: url("../../icons/logo/twitter-line.svg");
}

.fr-fi-vimeo-fill::before,
.fr-fi-vimeo-fill::after {
  -webkit-mask-image: url("../../icons/logo/vimeo-fill.svg");
  mask-image: url("../../icons/logo/vimeo-fill.svg");
}

.fr-fi-vimeo-line::before,
.fr-fi-vimeo-line::after {
  -webkit-mask-image: url("../../icons/logo/vimeo-line.svg");
  mask-image: url("../../icons/logo/vimeo-line.svg");
}

.fr-fi-youtube-fill::before,
.fr-fi-youtube-fill::after {
  -webkit-mask-image: url("../../icons/logo/youtube-fill.svg");
  mask-image: url("../../icons/logo/youtube-fill.svg");
}

.fr-fi-youtube-line::before,
.fr-fi-youtube-line::after {
  -webkit-mask-image: url("../../icons/logo/youtube-line.svg");
  mask-image: url("../../icons/logo/youtube-line.svg");
}

/* ¯¯¯¯¯¯¯¯¯ *\
  ICON COMMUNICATION
\* ˍˍˍˍˍˍˍˍˍ */
.fr-icon-anchor-fill::before,
.fr-icon-anchor-fill::after {
  -webkit-mask-image: url("../../icons/map/anchor-fill.svg");
  mask-image: url("../../icons/map/anchor-fill.svg");
}

.fr-icon-anchor-line::before,
.fr-icon-anchor-line::after {
  -webkit-mask-image: url("../../icons/map/anchor-line.svg");
  mask-image: url("../../icons/map/anchor-line.svg");
}

.fr-icon-bike-fill::before,
.fr-icon-bike-fill::after {
  -webkit-mask-image: url("../../icons/map/bike-fill.svg");
  mask-image: url("../../icons/map/bike-fill.svg");
}

.fr-icon-bike-line::before,
.fr-icon-bike-line::after {
  -webkit-mask-image: url("../../icons/map/bike-line.svg");
  mask-image: url("../../icons/map/bike-line.svg");
}

.fr-icon-bus-fill::before,
.fr-icon-bus-fill::after {
  -webkit-mask-image: url("../../icons/map/bus-fill.svg");
  mask-image: url("../../icons/map/bus-fill.svg");
}

.fr-icon-bus-line::before,
.fr-icon-bus-line::after {
  -webkit-mask-image: url("../../icons/map/bus-line.svg");
  mask-image: url("../../icons/map/bus-line.svg");
}

.fr-icon-car-fill::before,
.fr-icon-car-fill::after {
  -webkit-mask-image: url("../../icons/map/car-fill.svg");
  mask-image: url("../../icons/map/car-fill.svg");
}

.fr-icon-car-line::before,
.fr-icon-car-line::after {
  -webkit-mask-image: url("../../icons/map/car-line.svg");
  mask-image: url("../../icons/map/car-line.svg");
}

.fr-icon-caravan-fill::before,
.fr-icon-caravan-fill::after {
  -webkit-mask-image: url("../../icons/map/caravan-fill.svg");
  mask-image: url("../../icons/map/caravan-fill.svg");
}

.fr-icon-caravan-line::before,
.fr-icon-caravan-line::after {
  -webkit-mask-image: url("../../icons/map/caravan-line.svg");
  mask-image: url("../../icons/map/caravan-line.svg");
}

.fr-icon-charging-pile-2-fill::before,
.fr-icon-charging-pile-2-fill::after {
  -webkit-mask-image: url("../../icons/map/charging-pile-2-fill.svg");
  mask-image: url("../../icons/map/charging-pile-2-fill.svg");
}

.fr-icon-charging-pile-2-line::before,
.fr-icon-charging-pile-2-line::after {
  -webkit-mask-image: url("../../icons/map/charging-pile-2-line.svg");
  mask-image: url("../../icons/map/charging-pile-2-line.svg");
}

.fr-icon-compass-3-fill::before,
.fr-icon-compass-3-fill::after {
  -webkit-mask-image: url("../../icons/map/compass-3-fill.svg");
  mask-image: url("../../icons/map/compass-3-fill.svg");
}

.fr-icon-compass-3-line::before,
.fr-icon-compass-3-line::after {
  -webkit-mask-image: url("../../icons/map/compass-3-line.svg");
  mask-image: url("../../icons/map/compass-3-line.svg");
}

.fr-icon-cup-fill::before,
.fr-icon-cup-fill::after {
  -webkit-mask-image: url("../../icons/map/cup-fill.svg");
  mask-image: url("../../icons/map/cup-fill.svg");
}

.fr-icon-cup-line::before,
.fr-icon-cup-line::after {
  -webkit-mask-image: url("../../icons/map/cup-line.svg");
  mask-image: url("../../icons/map/cup-line.svg");
}

.fr-icon-earth-fill::before,
.fr-icon-earth-fill::after {
  -webkit-mask-image: url("../../icons/map/earth-fill.svg");
  mask-image: url("../../icons/map/earth-fill.svg");
}

.fr-icon-earth-line::before,
.fr-icon-earth-line::after {
  -webkit-mask-image: url("../../icons/map/earth-line.svg");
  mask-image: url("../../icons/map/earth-line.svg");
}

.fr-icon-france-fill::before,
.fr-icon-france-fill::after {
  -webkit-mask-image: url("../../icons/map/france-fill.svg");
  mask-image: url("../../icons/map/france-fill.svg");
}

.fr-icon-france-line::before,
.fr-icon-france-line::after {
  -webkit-mask-image: url("../../icons/map/france-line.svg");
  mask-image: url("../../icons/map/france-line.svg");
}

.fr-icon-gas-station-fill::before,
.fr-icon-gas-station-fill::after {
  -webkit-mask-image: url("../../icons/map/gas-station-fill.svg");
  mask-image: url("../../icons/map/gas-station-fill.svg");
}

.fr-icon-gas-station-line::before,
.fr-icon-gas-station-line::after {
  -webkit-mask-image: url("../../icons/map/gas-station-line.svg");
  mask-image: url("../../icons/map/gas-station-line.svg");
}

.fr-icon-goblet-fill::before,
.fr-icon-goblet-fill::after {
  -webkit-mask-image: url("../../icons/map/goblet-fill.svg");
  mask-image: url("../../icons/map/goblet-fill.svg");
}

.fr-icon-goblet-line::before,
.fr-icon-goblet-line::after {
  -webkit-mask-image: url("../../icons/map/goblet-line.svg");
  mask-image: url("../../icons/map/goblet-line.svg");
}

.fr-icon-map-pin-2-fill::before,
.fr-icon-map-pin-2-fill::after {
  -webkit-mask-image: url("../../icons/map/map-pin-2-fill.svg");
  mask-image: url("../../icons/map/map-pin-2-fill.svg");
}

.fr-icon-map-pin-2-line::before,
.fr-icon-map-pin-2-line::after {
  -webkit-mask-image: url("../../icons/map/map-pin-2-line.svg");
  mask-image: url("../../icons/map/map-pin-2-line.svg");
}

.fr-icon-map-pin-user-fill::before,
.fr-icon-map-pin-user-fill::after {
  -webkit-mask-image: url("../../icons/map/map-pin-user-fill.svg");
  mask-image: url("../../icons/map/map-pin-user-fill.svg");
}

.fr-icon-map-pin-user-line::before,
.fr-icon-map-pin-user-line::after {
  -webkit-mask-image: url("../../icons/map/map-pin-user-line.svg");
  mask-image: url("../../icons/map/map-pin-user-line.svg");
}

.fr-icon-motorbike-fill::before,
.fr-icon-motorbike-fill::after {
  -webkit-mask-image: url("../../icons/map/motorbike-fill.svg");
  mask-image: url("../../icons/map/motorbike-fill.svg");
}

.fr-icon-motorbike-line::before,
.fr-icon-motorbike-line::after {
  -webkit-mask-image: url("../../icons/map/motorbike-line.svg");
  mask-image: url("../../icons/map/motorbike-line.svg");
}

.fr-icon-passport-fill::before,
.fr-icon-passport-fill::after {
  -webkit-mask-image: url("../../icons/map/passport-fill.svg");
  mask-image: url("../../icons/map/passport-fill.svg");
}

.fr-icon-passport-line::before,
.fr-icon-passport-line::after {
  -webkit-mask-image: url("../../icons/map/passport-line.svg");
  mask-image: url("../../icons/map/passport-line.svg");
}

.fr-icon-restaurant-fill::before,
.fr-icon-restaurant-fill::after {
  -webkit-mask-image: url("../../icons/map/restaurant-fill.svg");
  mask-image: url("../../icons/map/restaurant-fill.svg");
}

.fr-icon-restaurant-line::before,
.fr-icon-restaurant-line::after {
  -webkit-mask-image: url("../../icons/map/restaurant-line.svg");
  mask-image: url("../../icons/map/restaurant-line.svg");
}

.fr-icon-road-map-fill::before,
.fr-icon-road-map-fill::after {
  -webkit-mask-image: url("../../icons/map/road-map-fill.svg");
  mask-image: url("../../icons/map/road-map-fill.svg");
}

.fr-icon-road-map-line::before,
.fr-icon-road-map-line::after {
  -webkit-mask-image: url("../../icons/map/road-map-line.svg");
  mask-image: url("../../icons/map/road-map-line.svg");
}

.fr-icon-sailboat-fill::before,
.fr-icon-sailboat-fill::after {
  -webkit-mask-image: url("../../icons/map/sailboat-fill.svg");
  mask-image: url("../../icons/map/sailboat-fill.svg");
}

.fr-icon-sailboat-line::before,
.fr-icon-sailboat-line::after {
  -webkit-mask-image: url("../../icons/map/sailboat-line.svg");
  mask-image: url("../../icons/map/sailboat-line.svg");
}

.fr-icon-ship-2-fill::before,
.fr-icon-ship-2-fill::after {
  -webkit-mask-image: url("../../icons/map/ship-2-fill.svg");
  mask-image: url("../../icons/map/ship-2-fill.svg");
}

.fr-icon-ship-2-line::before,
.fr-icon-ship-2-line::after {
  -webkit-mask-image: url("../../icons/map/ship-2-line.svg");
  mask-image: url("../../icons/map/ship-2-line.svg");
}

.fr-icon-signal-tower-fill::before,
.fr-icon-signal-tower-fill::after {
  -webkit-mask-image: url("../../icons/map/signal-tower-fill.svg");
  mask-image: url("../../icons/map/signal-tower-fill.svg");
}

.fr-icon-signal-tower-line::before,
.fr-icon-signal-tower-line::after {
  -webkit-mask-image: url("../../icons/map/signal-tower-line.svg");
  mask-image: url("../../icons/map/signal-tower-line.svg");
}

.fr-icon-suitcase-2-fill::before,
.fr-icon-suitcase-2-fill::after {
  -webkit-mask-image: url("../../icons/map/suitcase-2-fill.svg");
  mask-image: url("../../icons/map/suitcase-2-fill.svg");
}

.fr-icon-suitcase-2-line::before,
.fr-icon-suitcase-2-line::after {
  -webkit-mask-image: url("../../icons/map/suitcase-2-line.svg");
  mask-image: url("../../icons/map/suitcase-2-line.svg");
}

.fr-icon-taxi-fill::before,
.fr-icon-taxi-fill::after {
  -webkit-mask-image: url("../../icons/map/taxi-fill.svg");
  mask-image: url("../../icons/map/taxi-fill.svg");
}

.fr-icon-taxi-line::before,
.fr-icon-taxi-line::after {
  -webkit-mask-image: url("../../icons/map/taxi-line.svg");
  mask-image: url("../../icons/map/taxi-line.svg");
}

.fr-icon-train-fill::before,
.fr-icon-train-fill::after {
  -webkit-mask-image: url("../../icons/map/train-fill.svg");
  mask-image: url("../../icons/map/train-fill.svg");
}

.fr-icon-train-line::before,
.fr-icon-train-line::after {
  -webkit-mask-image: url("../../icons/map/train-line.svg");
  mask-image: url("../../icons/map/train-line.svg");
}

.fr-fi-map-pin-2-fill::before,
.fr-fi-map-pin-2-fill::after {
  -webkit-mask-image: url("../../icons/map/map-pin-2-fill.svg");
  mask-image: url("../../icons/map/map-pin-2-fill.svg");
}

.fr-fi-map-pin-2-line::before,
.fr-fi-map-pin-2-line::after {
  -webkit-mask-image: url("../../icons/map/map-pin-2-line.svg");
  mask-image: url("../../icons/map/map-pin-2-line.svg");
}

.fr-fi-road-map-fill::before,
.fr-fi-road-map-fill::after {
  -webkit-mask-image: url("../../icons/map/road-map-fill.svg");
  mask-image: url("../../icons/map/road-map-fill.svg");
}

.fr-fi-road-map-line::before,
.fr-fi-road-map-line::after {
  -webkit-mask-image: url("../../icons/map/road-map-line.svg");
  mask-image: url("../../icons/map/road-map-line.svg");
}

/* ¯¯¯¯¯¯¯¯¯ *\
  ICON COMMUNICATION
\* ˍˍˍˍˍˍˍˍˍ */
.fr-icon-align-left::before,
.fr-icon-align-left::after {
  -webkit-mask-image: url("../../icons/media/align-left.svg");
  mask-image: url("../../icons/media/align-left.svg");
}

.fr-icon-camera-fill::before,
.fr-icon-camera-fill::after {
  -webkit-mask-image: url("../../icons/media/camera-fill.svg");
  mask-image: url("../../icons/media/camera-fill.svg");
}

.fr-icon-camera-line::before,
.fr-icon-camera-line::after {
  -webkit-mask-image: url("../../icons/media/camera-line.svg");
  mask-image: url("../../icons/media/camera-line.svg");
}

.fr-icon-clapperboard-fill::before,
.fr-icon-clapperboard-fill::after {
  -webkit-mask-image: url("../../icons/media/clapperboard-fill.svg");
  mask-image: url("../../icons/media/clapperboard-fill.svg");
}

.fr-icon-clapperboard-line::before,
.fr-icon-clapperboard-line::after {
  -webkit-mask-image: url("../../icons/media/clapperboard-line.svg");
  mask-image: url("../../icons/media/clapperboard-line.svg");
}

.fr-icon-equalizer-fill::before,
.fr-icon-equalizer-fill::after {
  -webkit-mask-image: url("../../icons/media/equalizer-fill.svg");
  mask-image: url("../../icons/media/equalizer-fill.svg");
}

.fr-icon-equalizer-line::before,
.fr-icon-equalizer-line::after {
  -webkit-mask-image: url("../../icons/media/equalizer-line.svg");
  mask-image: url("../../icons/media/equalizer-line.svg");
}

.fr-icon-film-fill::before,
.fr-icon-film-fill::after {
  -webkit-mask-image: url("../../icons/media/film-fill.svg");
  mask-image: url("../../icons/media/film-fill.svg");
}

.fr-icon-film-line::before,
.fr-icon-film-line::after {
  -webkit-mask-image: url("../../icons/media/film-line.svg");
  mask-image: url("../../icons/media/film-line.svg");
}

.fr-icon-fullscreen-line::before,
.fr-icon-fullscreen-line::after {
  -webkit-mask-image: url("../../icons/media/fullscreen-line.svg");
  mask-image: url("../../icons/media/fullscreen-line.svg");
}

.fr-icon-gallery-fill::before,
.fr-icon-gallery-fill::after {
  -webkit-mask-image: url("../../icons/media/gallery-fill.svg");
  mask-image: url("../../icons/media/gallery-fill.svg");
}

.fr-icon-gallery-line::before,
.fr-icon-gallery-line::after {
  -webkit-mask-image: url("../../icons/media/gallery-line.svg");
  mask-image: url("../../icons/media/gallery-line.svg");
}

.fr-icon-headphone-fill::before,
.fr-icon-headphone-fill::after {
  -webkit-mask-image: url("../../icons/media/headphone-fill.svg");
  mask-image: url("../../icons/media/headphone-fill.svg");
}

.fr-icon-headphone-line::before,
.fr-icon-headphone-line::after {
  -webkit-mask-image: url("../../icons/media/headphone-line.svg");
  mask-image: url("../../icons/media/headphone-line.svg");
}

.fr-icon-image-add-fill::before,
.fr-icon-image-add-fill::after {
  -webkit-mask-image: url("../../icons/media/image-add-fill.svg");
  mask-image: url("../../icons/media/image-add-fill.svg");
}

.fr-icon-image-add-line::before,
.fr-icon-image-add-line::after {
  -webkit-mask-image: url("../../icons/media/image-add-line.svg");
  mask-image: url("../../icons/media/image-add-line.svg");
}

.fr-icon-image-edit-fill::before,
.fr-icon-image-edit-fill::after {
  -webkit-mask-image: url("../../icons/media/image-edit-fill.svg");
  mask-image: url("../../icons/media/image-edit-fill.svg");
}

.fr-icon-image-edit-line::before,
.fr-icon-image-edit-line::after {
  -webkit-mask-image: url("../../icons/media/image-edit-line.svg");
  mask-image: url("../../icons/media/image-edit-line.svg");
}

.fr-icon-image-fill::before,
.fr-icon-image-fill::after {
  -webkit-mask-image: url("../../icons/media/image-fill.svg");
  mask-image: url("../../icons/media/image-fill.svg");
}

.fr-icon-image-line::before,
.fr-icon-image-line::after {
  -webkit-mask-image: url("../../icons/media/image-line.svg");
  mask-image: url("../../icons/media/image-line.svg");
}

.fr-icon-live-fill::before,
.fr-icon-live-fill::after {
  -webkit-mask-image: url("../../icons/media/live-fill.svg");
  mask-image: url("../../icons/media/live-fill.svg");
}

.fr-icon-live-line::before,
.fr-icon-live-line::after {
  -webkit-mask-image: url("../../icons/media/live-line.svg");
  mask-image: url("../../icons/media/live-line.svg");
}

.fr-icon-mic-fill::before,
.fr-icon-mic-fill::after {
  -webkit-mask-image: url("../../icons/media/mic-fill.svg");
  mask-image: url("../../icons/media/mic-fill.svg");
}

.fr-icon-mic-line::before,
.fr-icon-mic-line::after {
  -webkit-mask-image: url("../../icons/media/mic-line.svg");
  mask-image: url("../../icons/media/mic-line.svg");
}

.fr-icon-music-2-fill::before,
.fr-icon-music-2-fill::after {
  -webkit-mask-image: url("../../icons/media/music-2-fill.svg");
  mask-image: url("../../icons/media/music-2-fill.svg");
}

.fr-icon-music-2-line::before,
.fr-icon-music-2-line::after {
  -webkit-mask-image: url("../../icons/media/music-2-line.svg");
  mask-image: url("../../icons/media/music-2-line.svg");
}

.fr-icon-notification-3-fill::before,
.fr-icon-notification-3-fill::after {
  -webkit-mask-image: url("../../icons/media/notification-3-fill.svg");
  mask-image: url("../../icons/media/notification-3-fill.svg");
}

.fr-icon-notification-3-line::before,
.fr-icon-notification-3-line::after {
  -webkit-mask-image: url("../../icons/media/notification-3-line.svg");
  mask-image: url("../../icons/media/notification-3-line.svg");
}

.fr-icon-pause-circle-fill::before,
.fr-icon-pause-circle-fill::after {
  -webkit-mask-image: url("../../icons/media/pause-circle-fill.svg");
  mask-image: url("../../icons/media/pause-circle-fill.svg");
}

.fr-icon-pause-circle-line::before,
.fr-icon-pause-circle-line::after {
  -webkit-mask-image: url("../../icons/media/pause-circle-line.svg");
  mask-image: url("../../icons/media/pause-circle-line.svg");
}

.fr-icon-play-circle-fill::before,
.fr-icon-play-circle-fill::after {
  -webkit-mask-image: url("../../icons/media/play-circle-fill.svg");
  mask-image: url("../../icons/media/play-circle-fill.svg");
}

.fr-icon-play-circle-line::before,
.fr-icon-play-circle-line::after {
  -webkit-mask-image: url("../../icons/media/play-circle-line.svg");
  mask-image: url("../../icons/media/play-circle-line.svg");
}

.fr-icon-stop-circle-fill::before,
.fr-icon-stop-circle-fill::after {
  -webkit-mask-image: url("../../icons/media/stop-circle-fill.svg");
  mask-image: url("../../icons/media/stop-circle-fill.svg");
}

.fr-icon-stop-circle-line::before,
.fr-icon-stop-circle-line::after {
  -webkit-mask-image: url("../../icons/media/stop-circle-line.svg");
  mask-image: url("../../icons/media/stop-circle-line.svg");
}

.fr-icon-volume-down-fill::before,
.fr-icon-volume-down-fill::after {
  -webkit-mask-image: url("../../icons/media/volume-down-fill.svg");
  mask-image: url("../../icons/media/volume-down-fill.svg");
}

.fr-icon-volume-down-line::before,
.fr-icon-volume-down-line::after {
  -webkit-mask-image: url("../../icons/media/volume-down-line.svg");
  mask-image: url("../../icons/media/volume-down-line.svg");
}

.fr-icon-volume-mute-fill::before,
.fr-icon-volume-mute-fill::after {
  -webkit-mask-image: url("../../icons/media/volume-mute-fill.svg");
  mask-image: url("../../icons/media/volume-mute-fill.svg");
}

.fr-icon-volume-mute-line::before,
.fr-icon-volume-mute-line::after {
  -webkit-mask-image: url("../../icons/media/volume-mute-line.svg");
  mask-image: url("../../icons/media/volume-mute-line.svg");
}

.fr-icon-volume-up-fill::before,
.fr-icon-volume-up-fill::after {
  -webkit-mask-image: url("../../icons/media/volume-up-fill.svg");
  mask-image: url("../../icons/media/volume-up-fill.svg");
}

.fr-icon-volume-up-line::before,
.fr-icon-volume-up-line::after {
  -webkit-mask-image: url("../../icons/media/volume-up-line.svg");
  mask-image: url("../../icons/media/volume-up-line.svg");
}

.fr-fi-equalizer-fill::before,
.fr-fi-equalizer-fill::after {
  -webkit-mask-image: url("../../icons/media/equalizer-fill.svg");
  mask-image: url("../../icons/media/equalizer-fill.svg");
}

.fr-fi-equalizer-line::before,
.fr-fi-equalizer-line::after {
  -webkit-mask-image: url("../../icons/media/equalizer-line.svg");
  mask-image: url("../../icons/media/equalizer-line.svg");
}

.fr-fi-image-fill::before,
.fr-fi-image-fill::after {
  -webkit-mask-image: url("../../icons/media/image-fill.svg");
  mask-image: url("../../icons/media/image-fill.svg");
}

.fr-fi-image-line::before,
.fr-fi-image-line::after {
  -webkit-mask-image: url("../../icons/media/image-line.svg");
  mask-image: url("../../icons/media/image-line.svg");
}

.fr-fi-pause-circle-fill::before,
.fr-fi-pause-circle-fill::after {
  -webkit-mask-image: url("../../icons/media/pause-circle-fill.svg");
  mask-image: url("../../icons/media/pause-circle-fill.svg");
}

.fr-fi-pause-circle-line::before,
.fr-fi-pause-circle-line::after {
  -webkit-mask-image: url("../../icons/media/pause-circle-line.svg");
  mask-image: url("../../icons/media/pause-circle-line.svg");
}

.fr-fi-volume-down-fill::before,
.fr-fi-volume-down-fill::after {
  -webkit-mask-image: url("../../icons/media/volume-down-fill.svg");
  mask-image: url("../../icons/media/volume-down-fill.svg");
}

.fr-fi-volume-down-line::before,
.fr-fi-volume-down-line::after {
  -webkit-mask-image: url("../../icons/media/volume-down-line.svg");
  mask-image: url("../../icons/media/volume-down-line.svg");
}

.fr-fi-volume-mute-fill::before,
.fr-fi-volume-mute-fill::after {
  -webkit-mask-image: url("../../icons/media/volume-mute-fill.svg");
  mask-image: url("../../icons/media/volume-mute-fill.svg");
}

.fr-fi-volume-mute-line::before,
.fr-fi-volume-mute-line::after {
  -webkit-mask-image: url("../../icons/media/volume-mute-line.svg");
  mask-image: url("../../icons/media/volume-mute-line.svg");
}

.fr-fi-volume-up-fill::before,
.fr-fi-volume-up-fill::after {
  -webkit-mask-image: url("../../icons/media/volume-up-fill.svg");
  mask-image: url("../../icons/media/volume-up-fill.svg");
}

.fr-fi-volume-up-line::before,
.fr-fi-volume-up-line::after {
  -webkit-mask-image: url("../../icons/media/volume-up-line.svg");
  mask-image: url("../../icons/media/volume-up-line.svg");
}

.fr-fi-play-line::before,
.fr-fi-play-line::after {
  -webkit-mask-image: url("../../icons/media/play-circle-line.svg");
  mask-image: url("../../icons/media/play-circle-line.svg");
}

.fr-fi-play-fill::before,
.fr-fi-play-fill::after {
  -webkit-mask-image: url("../../icons/media/play-circle-fill.svg");
  mask-image: url("../../icons/media/play-circle-fill.svg");
}

.fr-icon-play-line::before,
.fr-icon-play-line::after {
  -webkit-mask-image: url("../../icons/media/play-circle-line.svg");
  mask-image: url("../../icons/media/play-circle-line.svg");
}

.fr-icon-play-fill::before,
.fr-icon-play-fill::after {
  -webkit-mask-image: url("../../icons/media/play-circle-fill.svg");
  mask-image: url("../../icons/media/play-circle-fill.svg");
}

/* ¯¯¯¯¯¯¯¯¯ *\
  ICON COMMUNICATION
\* ˍˍˍˍˍˍˍˍˍ */
.fr-icon-accessibility-fill::before,
.fr-icon-accessibility-fill::after {
  -webkit-mask-image: url("../../icons/others/fr--accessibility-fill.svg");
  mask-image: url("../../icons/others/fr--accessibility-fill.svg");
}

.fr-icon-accessibility-line::before,
.fr-icon-accessibility-line::after {
  -webkit-mask-image: url("../../icons/others/fr--accessibility-line.svg");
  mask-image: url("../../icons/others/fr--accessibility-line.svg");
}

.fr-icon-ear-off-fill::before,
.fr-icon-ear-off-fill::after {
  -webkit-mask-image: url("../../icons/others/fr--ear-off-fill.svg");
  mask-image: url("../../icons/others/fr--ear-off-fill.svg");
}

.fr-icon-ear-off-line::before,
.fr-icon-ear-off-line::after {
  -webkit-mask-image: url("../../icons/others/fr--ear-off-line.svg");
  mask-image: url("../../icons/others/fr--ear-off-line.svg");
}

.fr-icon-mental-disabilities-fill::before,
.fr-icon-mental-disabilities-fill::after {
  -webkit-mask-image: url("../../icons/others/fr--mental-disabilities-fill.svg");
  mask-image: url("../../icons/others/fr--mental-disabilities-fill.svg");
}

.fr-icon-mental-disabilities-line::before,
.fr-icon-mental-disabilities-line::after {
  -webkit-mask-image: url("../../icons/others/fr--mental-disabilities-line.svg");
  mask-image: url("../../icons/others/fr--mental-disabilities-line.svg");
}

.fr-icon-sign-language-fill::before,
.fr-icon-sign-language-fill::after {
  -webkit-mask-image: url("../../icons/others/fr--sign-language-fill.svg");
  mask-image: url("../../icons/others/fr--sign-language-fill.svg");
}

.fr-icon-sign-language-line::before,
.fr-icon-sign-language-line::after {
  -webkit-mask-image: url("../../icons/others/fr--sign-language-line.svg");
  mask-image: url("../../icons/others/fr--sign-language-line.svg");
}

.fr-icon-leaf-fill::before,
.fr-icon-leaf-fill::after {
  -webkit-mask-image: url("../../icons/others/leaf-fill.svg");
  mask-image: url("../../icons/others/leaf-fill.svg");
}

.fr-icon-leaf-line::before,
.fr-icon-leaf-line::after {
  -webkit-mask-image: url("../../icons/others/leaf-line.svg");
  mask-image: url("../../icons/others/leaf-line.svg");
}

.fr-icon-lightbulb-fill::before,
.fr-icon-lightbulb-fill::after {
  -webkit-mask-image: url("../../icons/others/lightbulb-fill.svg");
  mask-image: url("../../icons/others/lightbulb-fill.svg");
}

.fr-icon-lightbulb-line::before,
.fr-icon-lightbulb-line::after {
  -webkit-mask-image: url("../../icons/others/lightbulb-line.svg");
  mask-image: url("../../icons/others/lightbulb-line.svg");
}

.fr-icon-plant-fill::before,
.fr-icon-plant-fill::after {
  -webkit-mask-image: url("../../icons/others/plant-fill.svg");
  mask-image: url("../../icons/others/plant-fill.svg");
}

.fr-icon-plant-line::before,
.fr-icon-plant-line::after {
  -webkit-mask-image: url("../../icons/others/plant-line.svg");
  mask-image: url("../../icons/others/plant-line.svg");
}

.fr-icon-recycle-fill::before,
.fr-icon-recycle-fill::after {
  -webkit-mask-image: url("../../icons/others/recycle-fill.svg");
  mask-image: url("../../icons/others/recycle-fill.svg");
}

.fr-icon-recycle-line::before,
.fr-icon-recycle-line::after {
  -webkit-mask-image: url("../../icons/others/recycle-line.svg");
  mask-image: url("../../icons/others/recycle-line.svg");
}

.fr-icon-scales-3-fill::before,
.fr-icon-scales-3-fill::after {
  -webkit-mask-image: url("../../icons/others/scales-3-fill.svg");
  mask-image: url("../../icons/others/scales-3-fill.svg");
}

.fr-icon-scales-3-line::before,
.fr-icon-scales-3-line::after {
  -webkit-mask-image: url("../../icons/others/scales-3-line.svg");
  mask-image: url("../../icons/others/scales-3-line.svg");
}

.fr-icon-seedling-fill::before,
.fr-icon-seedling-fill::after {
  -webkit-mask-image: url("../../icons/others/seedling-fill.svg");
  mask-image: url("../../icons/others/seedling-fill.svg");
}

.fr-icon-seedling-line::before,
.fr-icon-seedling-line::after {
  -webkit-mask-image: url("../../icons/others/seedling-line.svg");
  mask-image: url("../../icons/others/seedling-line.svg");
}

.fr-icon-umbrella-fill::before,
.fr-icon-umbrella-fill::after {
  -webkit-mask-image: url("../../icons/others/umbrella-fill.svg");
  mask-image: url("../../icons/others/umbrella-fill.svg");
}

.fr-icon-umbrella-line::before,
.fr-icon-umbrella-line::after {
  -webkit-mask-image: url("../../icons/others/umbrella-line.svg");
  mask-image: url("../../icons/others/umbrella-line.svg");
}

.fr-icon-wheelchair-fill::before,
.fr-icon-wheelchair-fill::after {
  -webkit-mask-image: url("../../icons/others/wheelchair-fill.svg");
  mask-image: url("../../icons/others/wheelchair-fill.svg");
}

.fr-icon-wheelchair-line::before,
.fr-icon-wheelchair-line::after {
  -webkit-mask-image: url("../../icons/others/wheelchair-line.svg");
  mask-image: url("../../icons/others/wheelchair-line.svg");
}

/* ¯¯¯¯¯¯¯¯¯ *\
  ICON SYSTEM
\* ˍˍˍˍˍˍˍˍˍ */
.fr-icon-add-circle-fill::before,
.fr-icon-add-circle-fill::after {
  -webkit-mask-image: url("../../icons/system/add-circle-fill.svg");
  mask-image: url("../../icons/system/add-circle-fill.svg");
}

.fr-icon-add-circle-line::before,
.fr-icon-add-circle-line::after {
  -webkit-mask-image: url("../../icons/system/add-circle-line.svg");
  mask-image: url("../../icons/system/add-circle-line.svg");
}

.fr-icon-add-line::before,
.fr-icon-add-line::after {
  -webkit-mask-image: url("../../icons/system/add-line.svg");
  mask-image: url("../../icons/system/add-line.svg");
}

.fr-icon-alarm-warning-fill::before,
.fr-icon-alarm-warning-fill::after {
  -webkit-mask-image: url("../../icons/system/alarm-warning-fill.svg");
  mask-image: url("../../icons/system/alarm-warning-fill.svg");
}

.fr-icon-alarm-warning-line::before,
.fr-icon-alarm-warning-line::after {
  -webkit-mask-image: url("../../icons/system/alarm-warning-line.svg");
  mask-image: url("../../icons/system/alarm-warning-line.svg");
}

.fr-icon-alert-fill::before,
.fr-icon-alert-fill::after {
  -webkit-mask-image: url("../../icons/system/alert-fill.svg");
  mask-image: url("../../icons/system/alert-fill.svg");
}

.fr-icon-alert-line::before,
.fr-icon-alert-line::after {
  -webkit-mask-image: url("../../icons/system/alert-line.svg");
  mask-image: url("../../icons/system/alert-line.svg");
}

.fr-icon-arrow-down-fill::before,
.fr-icon-arrow-down-fill::after {
  -webkit-mask-image: url("../../icons/system/arrow-down-fill.svg");
  mask-image: url("../../icons/system/arrow-down-fill.svg");
}

.fr-icon-arrow-down-line::before,
.fr-icon-arrow-down-line::after {
  -webkit-mask-image: url("../../icons/system/arrow-down-line.svg");
  mask-image: url("../../icons/system/arrow-down-line.svg");
}

.fr-icon-arrow-down-s-fill::before,
.fr-icon-arrow-down-s-fill::after {
  -webkit-mask-image: url("../../icons/system/arrow-down-s-fill.svg");
  mask-image: url("../../icons/system/arrow-down-s-fill.svg");
}

.fr-icon-arrow-down-s-line::before,
.fr-icon-arrow-down-s-line::after {
  -webkit-mask-image: url("../../icons/system/arrow-down-s-line.svg");
  mask-image: url("../../icons/system/arrow-down-s-line.svg");
}

.fr-icon-arrow-go-back-fill::before,
.fr-icon-arrow-go-back-fill::after {
  -webkit-mask-image: url("../../icons/system/arrow-go-back-fill.svg");
  mask-image: url("../../icons/system/arrow-go-back-fill.svg");
}

.fr-icon-arrow-go-back-line::before,
.fr-icon-arrow-go-back-line::after {
  -webkit-mask-image: url("../../icons/system/arrow-go-back-line.svg");
  mask-image: url("../../icons/system/arrow-go-back-line.svg");
}

.fr-icon-arrow-go-forward-fill::before,
.fr-icon-arrow-go-forward-fill::after {
  -webkit-mask-image: url("../../icons/system/arrow-go-forward-fill.svg");
  mask-image: url("../../icons/system/arrow-go-forward-fill.svg");
}

.fr-icon-arrow-go-forward-line::before,
.fr-icon-arrow-go-forward-line::after {
  -webkit-mask-image: url("../../icons/system/arrow-go-forward-line.svg");
  mask-image: url("../../icons/system/arrow-go-forward-line.svg");
}

.fr-icon-arrow-left-fill::before,
.fr-icon-arrow-left-fill::after {
  -webkit-mask-image: url("../../icons/system/arrow-left-fill.svg");
  mask-image: url("../../icons/system/arrow-left-fill.svg");
}

.fr-icon-arrow-left-line::before,
.fr-icon-arrow-left-line::after {
  -webkit-mask-image: url("../../icons/system/arrow-left-line.svg");
  mask-image: url("../../icons/system/arrow-left-line.svg");
}

.fr-icon-arrow-left-s-fill::before,
.fr-icon-arrow-left-s-fill::after {
  -webkit-mask-image: url("../../icons/system/arrow-left-s-fill.svg");
  mask-image: url("../../icons/system/arrow-left-s-fill.svg");
}

.fr-icon-arrow-left-s-line::before,
.fr-icon-arrow-left-s-line::after {
  -webkit-mask-image: url("../../icons/system/arrow-left-s-line.svg");
  mask-image: url("../../icons/system/arrow-left-s-line.svg");
}

.fr-icon-arrow-right-fill::before,
.fr-icon-arrow-right-fill::after {
  -webkit-mask-image: url("../../icons/system/arrow-right-fill.svg");
  mask-image: url("../../icons/system/arrow-right-fill.svg");
}

.fr-icon-arrow-right-line::before,
.fr-icon-arrow-right-line::after {
  -webkit-mask-image: url("../../icons/system/arrow-right-line.svg");
  mask-image: url("../../icons/system/arrow-right-line.svg");
}

.fr-icon-arrow-right-s-fill::before,
.fr-icon-arrow-right-s-fill::after {
  -webkit-mask-image: url("../../icons/system/arrow-right-s-fill.svg");
  mask-image: url("../../icons/system/arrow-right-s-fill.svg");
}

.fr-icon-arrow-right-s-line::before,
.fr-icon-arrow-right-s-line::after {
  -webkit-mask-image: url("../../icons/system/arrow-right-s-line.svg");
  mask-image: url("../../icons/system/arrow-right-s-line.svg");
}

.fr-icon-arrow-right-up-line::before,
.fr-icon-arrow-right-up-line::after {
  -webkit-mask-image: url("../../icons/system/arrow-right-up-line.svg");
  mask-image: url("../../icons/system/arrow-right-up-line.svg");
}

.fr-icon-arrow-up-down-line::before,
.fr-icon-arrow-up-down-line::after {
  -webkit-mask-image: url("../../icons/system/arrow-up-down-line.svg");
  mask-image: url("../../icons/system/arrow-up-down-line.svg");
}

.fr-icon-arrow-up-fill::before,
.fr-icon-arrow-up-fill::after {
  -webkit-mask-image: url("../../icons/system/arrow-up-fill.svg");
  mask-image: url("../../icons/system/arrow-up-fill.svg");
}

.fr-icon-arrow-up-line::before,
.fr-icon-arrow-up-line::after {
  -webkit-mask-image: url("../../icons/system/arrow-up-line.svg");
  mask-image: url("../../icons/system/arrow-up-line.svg");
}

.fr-icon-arrow-up-s-fill::before,
.fr-icon-arrow-up-s-fill::after {
  -webkit-mask-image: url("../../icons/system/arrow-up-s-fill.svg");
  mask-image: url("../../icons/system/arrow-up-s-fill.svg");
}

.fr-icon-arrow-up-s-line::before,
.fr-icon-arrow-up-s-line::after {
  -webkit-mask-image: url("../../icons/system/arrow-up-s-line.svg");
  mask-image: url("../../icons/system/arrow-up-s-line.svg");
}

.fr-icon-check-line::before,
.fr-icon-check-line::after {
  -webkit-mask-image: url("../../icons/system/check-line.svg");
  mask-image: url("../../icons/system/check-line.svg");
}

.fr-icon-checkbox-circle-fill::before,
.fr-icon-checkbox-circle-fill::after {
  -webkit-mask-image: url("../../icons/system/checkbox-circle-fill.svg");
  mask-image: url("../../icons/system/checkbox-circle-fill.svg");
}

.fr-icon-checkbox-circle-line::before,
.fr-icon-checkbox-circle-line::after {
  -webkit-mask-image: url("../../icons/system/checkbox-circle-line.svg");
  mask-image: url("../../icons/system/checkbox-circle-line.svg");
}

.fr-icon-checkbox-fill::before,
.fr-icon-checkbox-fill::after {
  -webkit-mask-image: url("../../icons/system/checkbox-fill.svg");
  mask-image: url("../../icons/system/checkbox-fill.svg");
}

.fr-icon-checkbox-line::before,
.fr-icon-checkbox-line::after {
  -webkit-mask-image: url("../../icons/system/checkbox-line.svg");
  mask-image: url("../../icons/system/checkbox-line.svg");
}

.fr-icon-close-circle-fill::before,
.fr-icon-close-circle-fill::after {
  -webkit-mask-image: url("../../icons/system/close-circle-fill.svg");
  mask-image: url("../../icons/system/close-circle-fill.svg");
}

.fr-icon-close-circle-line::before,
.fr-icon-close-circle-line::after {
  -webkit-mask-image: url("../../icons/system/close-circle-line.svg");
  mask-image: url("../../icons/system/close-circle-line.svg");
}

.fr-icon-close-line::before,
.fr-icon-close-line::after {
  -webkit-mask-image: url("../../icons/system/close-line.svg");
  mask-image: url("../../icons/system/close-line.svg");
}

.fr-icon-delete-bin-fill::before,
.fr-icon-delete-bin-fill::after {
  -webkit-mask-image: url("../../icons/system/delete-bin-fill.svg");
  mask-image: url("../../icons/system/delete-bin-fill.svg");
}

.fr-icon-delete-bin-line::before,
.fr-icon-delete-bin-line::after {
  -webkit-mask-image: url("../../icons/system/delete-bin-line.svg");
  mask-image: url("../../icons/system/delete-bin-line.svg");
}

.fr-icon-download-fill::before,
.fr-icon-download-fill::after {
  -webkit-mask-image: url("../../icons/system/download-fill.svg");
  mask-image: url("../../icons/system/download-fill.svg");
}

.fr-icon-download-line::before,
.fr-icon-download-line::after {
  -webkit-mask-image: url("../../icons/system/download-line.svg");
  mask-image: url("../../icons/system/download-line.svg");
}

.fr-icon-error-warning-fill::before,
.fr-icon-error-warning-fill::after {
  -webkit-mask-image: url("../../icons/system/error-warning-fill.svg");
  mask-image: url("../../icons/system/error-warning-fill.svg");
}

.fr-icon-error-warning-line::before,
.fr-icon-error-warning-line::after {
  -webkit-mask-image: url("../../icons/system/error-warning-line.svg");
  mask-image: url("../../icons/system/error-warning-line.svg");
}

.fr-icon-external-link-fill::before,
.fr-icon-external-link-fill::after {
  -webkit-mask-image: url("../../icons/system/external-link-fill.svg");
  mask-image: url("../../icons/system/external-link-fill.svg");
}

.fr-icon-external-link-line::before,
.fr-icon-external-link-line::after {
  -webkit-mask-image: url("../../icons/system/external-link-line.svg");
  mask-image: url("../../icons/system/external-link-line.svg");
}

.fr-icon-eye-fill::before,
.fr-icon-eye-fill::after {
  -webkit-mask-image: url("../../icons/system/eye-fill.svg");
  mask-image: url("../../icons/system/eye-fill.svg");
}

.fr-icon-eye-line::before,
.fr-icon-eye-line::after {
  -webkit-mask-image: url("../../icons/system/eye-line.svg");
  mask-image: url("../../icons/system/eye-line.svg");
}

.fr-icon-eye-off-fill::before,
.fr-icon-eye-off-fill::after {
  -webkit-mask-image: url("../../icons/system/eye-off-fill.svg");
  mask-image: url("../../icons/system/eye-off-fill.svg");
}

.fr-icon-eye-off-line::before,
.fr-icon-eye-off-line::after {
  -webkit-mask-image: url("../../icons/system/eye-off-line.svg");
  mask-image: url("../../icons/system/eye-off-line.svg");
}

.fr-icon-filter-fill::before,
.fr-icon-filter-fill::after {
  -webkit-mask-image: url("../../icons/system/filter-fill.svg");
  mask-image: url("../../icons/system/filter-fill.svg");
}

.fr-icon-filter-line::before,
.fr-icon-filter-line::after {
  -webkit-mask-image: url("../../icons/system/filter-line.svg");
  mask-image: url("../../icons/system/filter-line.svg");
}

.fr-icon-alert-warning-2-fill::before,
.fr-icon-alert-warning-2-fill::after {
  -webkit-mask-image: url("../../icons/system/fr--alert-warning-2-fill.svg");
  mask-image: url("../../icons/system/fr--alert-warning-2-fill.svg");
}

.fr-icon-alert-warning-fill::before,
.fr-icon-alert-warning-fill::after {
  -webkit-mask-image: url("../../icons/system/fr--alert-warning-fill.svg");
  mask-image: url("../../icons/system/fr--alert-warning-fill.svg");
}

.fr-icon-arrow-left-s-first-line::before,
.fr-icon-arrow-left-s-first-line::after {
  -webkit-mask-image: url("../../icons/system/fr--arrow-left-s-first-line.svg");
  mask-image: url("../../icons/system/fr--arrow-left-s-first-line.svg");
}

.fr-icon-arrow-left-s-line-double::before,
.fr-icon-arrow-left-s-line-double::after {
  -webkit-mask-image: url("../../icons/system/fr--arrow-left-s-line-double.svg");
  mask-image: url("../../icons/system/fr--arrow-left-s-line-double.svg");
}

.fr-icon-arrow-right-down-circle-fill::before,
.fr-icon-arrow-right-down-circle-fill::after {
  -webkit-mask-image: url("../../icons/system/fr--arrow-right-down-circle-fill.svg");
  mask-image: url("../../icons/system/fr--arrow-right-down-circle-fill.svg");
}

.fr-icon-arrow-right-s-last-line::before,
.fr-icon-arrow-right-s-last-line::after {
  -webkit-mask-image: url("../../icons/system/fr--arrow-right-s-last-line.svg");
  mask-image: url("../../icons/system/fr--arrow-right-s-last-line.svg");
}

.fr-icon-arrow-right-s-line-double::before,
.fr-icon-arrow-right-s-line-double::after {
  -webkit-mask-image: url("../../icons/system/fr--arrow-right-s-line-double.svg");
  mask-image: url("../../icons/system/fr--arrow-right-s-line-double.svg");
}

.fr-icon-arrow-right-up-circle-fill::before,
.fr-icon-arrow-right-up-circle-fill::after {
  -webkit-mask-image: url("../../icons/system/fr--arrow-right-up-circle-fill.svg");
  mask-image: url("../../icons/system/fr--arrow-right-up-circle-fill.svg");
}

.fr-icon-capslock-line::before,
.fr-icon-capslock-line::after {
  -webkit-mask-image: url("../../icons/system/fr--capslock-line.svg");
  mask-image: url("../../icons/system/fr--capslock-line.svg");
}

.fr-icon-equal-circle-fill::before,
.fr-icon-equal-circle-fill::after {
  -webkit-mask-image: url("../../icons/system/fr--equal-circle-fill.svg");
  mask-image: url("../../icons/system/fr--equal-circle-fill.svg");
}

.fr-icon-error-fill::before,
.fr-icon-error-fill::after {
  -webkit-mask-image: url("../../icons/system/fr--error-fill.svg");
  mask-image: url("../../icons/system/fr--error-fill.svg");
}

.fr-icon-error-line::before,
.fr-icon-error-line::after {
  -webkit-mask-image: url("../../icons/system/fr--error-line.svg");
  mask-image: url("../../icons/system/fr--error-line.svg");
}

.fr-icon-info-fill::before,
.fr-icon-info-fill::after {
  -webkit-mask-image: url("../../icons/system/fr--info-fill.svg");
  mask-image: url("../../icons/system/fr--info-fill.svg");
}

.fr-icon-info-line::before,
.fr-icon-info-line::after {
  -webkit-mask-image: url("../../icons/system/fr--info-line.svg");
  mask-image: url("../../icons/system/fr--info-line.svg");
}

.fr-icon-success-fill::before,
.fr-icon-success-fill::after {
  -webkit-mask-image: url("../../icons/system/fr--success-fill.svg");
  mask-image: url("../../icons/system/fr--success-fill.svg");
}

.fr-icon-success-line::before,
.fr-icon-success-line::after {
  -webkit-mask-image: url("../../icons/system/fr--success-line.svg");
  mask-image: url("../../icons/system/fr--success-line.svg");
}

.fr-icon-theme-fill::before,
.fr-icon-theme-fill::after {
  -webkit-mask-image: url("../../icons/system/fr--theme-fill.svg");
  mask-image: url("../../icons/system/fr--theme-fill.svg");
}

.fr-icon-warning-fill::before,
.fr-icon-warning-fill::after {
  -webkit-mask-image: url("../../icons/system/fr--warning-fill.svg");
  mask-image: url("../../icons/system/fr--warning-fill.svg");
}

.fr-icon-warning-line::before,
.fr-icon-warning-line::after {
  -webkit-mask-image: url("../../icons/system/fr--warning-line.svg");
  mask-image: url("../../icons/system/fr--warning-line.svg");
}

.fr-icon-information-fill::before,
.fr-icon-information-fill::after {
  -webkit-mask-image: url("../../icons/system/information-fill.svg");
  mask-image: url("../../icons/system/information-fill.svg");
}

.fr-icon-information-line::before,
.fr-icon-information-line::after {
  -webkit-mask-image: url("../../icons/system/information-line.svg");
  mask-image: url("../../icons/system/information-line.svg");
}

.fr-icon-lock-fill::before,
.fr-icon-lock-fill::after {
  -webkit-mask-image: url("../../icons/system/lock-fill.svg");
  mask-image: url("../../icons/system/lock-fill.svg");
}

.fr-icon-lock-line::before,
.fr-icon-lock-line::after {
  -webkit-mask-image: url("../../icons/system/lock-line.svg");
  mask-image: url("../../icons/system/lock-line.svg");
}

.fr-icon-lock-unlock-fill::before,
.fr-icon-lock-unlock-fill::after {
  -webkit-mask-image: url("../../icons/system/lock-unlock-fill.svg");
  mask-image: url("../../icons/system/lock-unlock-fill.svg");
}

.fr-icon-lock-unlock-line::before,
.fr-icon-lock-unlock-line::after {
  -webkit-mask-image: url("../../icons/system/lock-unlock-line.svg");
  mask-image: url("../../icons/system/lock-unlock-line.svg");
}

.fr-icon-logout-box-r-fill::before,
.fr-icon-logout-box-r-fill::after {
  -webkit-mask-image: url("../../icons/system/logout-box-r-fill.svg");
  mask-image: url("../../icons/system/logout-box-r-fill.svg");
}

.fr-icon-logout-box-r-line::before,
.fr-icon-logout-box-r-line::after {
  -webkit-mask-image: url("../../icons/system/logout-box-r-line.svg");
  mask-image: url("../../icons/system/logout-box-r-line.svg");
}

.fr-icon-menu-2-fill::before,
.fr-icon-menu-2-fill::after {
  -webkit-mask-image: url("../../icons/system/menu-2-fill.svg");
  mask-image: url("../../icons/system/menu-2-fill.svg");
}

.fr-icon-menu-fill::before,
.fr-icon-menu-fill::after {
  -webkit-mask-image: url("../../icons/system/menu-fill.svg");
  mask-image: url("../../icons/system/menu-fill.svg");
}

.fr-icon-more-fill::before,
.fr-icon-more-fill::after {
  -webkit-mask-image: url("../../icons/system/more-fill.svg");
  mask-image: url("../../icons/system/more-fill.svg");
}

.fr-icon-more-line::before,
.fr-icon-more-line::after {
  -webkit-mask-image: url("../../icons/system/more-line.svg");
  mask-image: url("../../icons/system/more-line.svg");
}

.fr-icon-notification-badge-fill::before,
.fr-icon-notification-badge-fill::after {
  -webkit-mask-image: url("../../icons/system/notification-badge-fill.svg");
  mask-image: url("../../icons/system/notification-badge-fill.svg");
}

.fr-icon-notification-badge-line::before,
.fr-icon-notification-badge-line::after {
  -webkit-mask-image: url("../../icons/system/notification-badge-line.svg");
  mask-image: url("../../icons/system/notification-badge-line.svg");
}

.fr-icon-question-fill::before,
.fr-icon-question-fill::after {
  -webkit-mask-image: url("../../icons/system/question-fill.svg");
  mask-image: url("../../icons/system/question-fill.svg");
}

.fr-icon-question-line::before,
.fr-icon-question-line::after {
  -webkit-mask-image: url("../../icons/system/question-line.svg");
  mask-image: url("../../icons/system/question-line.svg");
}

.fr-icon-refresh-fill::before,
.fr-icon-refresh-fill::after {
  -webkit-mask-image: url("../../icons/system/refresh-fill.svg");
  mask-image: url("../../icons/system/refresh-fill.svg");
}

.fr-icon-refresh-line::before,
.fr-icon-refresh-line::after {
  -webkit-mask-image: url("../../icons/system/refresh-line.svg");
  mask-image: url("../../icons/system/refresh-line.svg");
}

.fr-icon-search-fill::before,
.fr-icon-search-fill::after {
  -webkit-mask-image: url("../../icons/system/search-fill.svg");
  mask-image: url("../../icons/system/search-fill.svg");
}

.fr-icon-search-line::before,
.fr-icon-search-line::after {
  -webkit-mask-image: url("../../icons/system/search-line.svg");
  mask-image: url("../../icons/system/search-line.svg");
}

.fr-icon-settings-5-fill::before,
.fr-icon-settings-5-fill::after {
  -webkit-mask-image: url("../../icons/system/settings-5-fill.svg");
  mask-image: url("../../icons/system/settings-5-fill.svg");
}

.fr-icon-settings-5-line::before,
.fr-icon-settings-5-line::after {
  -webkit-mask-image: url("../../icons/system/settings-5-line.svg");
  mask-image: url("../../icons/system/settings-5-line.svg");
}

.fr-icon-shield-fill::before,
.fr-icon-shield-fill::after {
  -webkit-mask-image: url("../../icons/system/shield-fill.svg");
  mask-image: url("../../icons/system/shield-fill.svg");
}

.fr-icon-shield-line::before,
.fr-icon-shield-line::after {
  -webkit-mask-image: url("../../icons/system/shield-line.svg");
  mask-image: url("../../icons/system/shield-line.svg");
}

.fr-icon-star-fill::before,
.fr-icon-star-fill::after {
  -webkit-mask-image: url("../../icons/system/star-fill.svg");
  mask-image: url("../../icons/system/star-fill.svg");
}

.fr-icon-star-line::before,
.fr-icon-star-line::after {
  -webkit-mask-image: url("../../icons/system/star-line.svg");
  mask-image: url("../../icons/system/star-line.svg");
}

.fr-icon-star-s-fill::before,
.fr-icon-star-s-fill::after {
  -webkit-mask-image: url("../../icons/system/star-s-fill.svg");
  mask-image: url("../../icons/system/star-s-fill.svg");
}

.fr-icon-star-s-line::before,
.fr-icon-star-s-line::after {
  -webkit-mask-image: url("../../icons/system/star-s-line.svg");
  mask-image: url("../../icons/system/star-s-line.svg");
}

.fr-icon-subtract-line::before,
.fr-icon-subtract-line::after {
  -webkit-mask-image: url("../../icons/system/subtract-line.svg");
  mask-image: url("../../icons/system/subtract-line.svg");
}

.fr-icon-thumb-down-fill::before,
.fr-icon-thumb-down-fill::after {
  -webkit-mask-image: url("../../icons/system/thumb-down-fill.svg");
  mask-image: url("../../icons/system/thumb-down-fill.svg");
}

.fr-icon-thumb-down-line::before,
.fr-icon-thumb-down-line::after {
  -webkit-mask-image: url("../../icons/system/thumb-down-line.svg");
  mask-image: url("../../icons/system/thumb-down-line.svg");
}

.fr-icon-thumb-up-fill::before,
.fr-icon-thumb-up-fill::after {
  -webkit-mask-image: url("../../icons/system/thumb-up-fill.svg");
  mask-image: url("../../icons/system/thumb-up-fill.svg");
}

.fr-icon-thumb-up-line::before,
.fr-icon-thumb-up-line::after {
  -webkit-mask-image: url("../../icons/system/thumb-up-line.svg");
  mask-image: url("../../icons/system/thumb-up-line.svg");
}

.fr-icon-time-fill::before,
.fr-icon-time-fill::after {
  -webkit-mask-image: url("../../icons/system/time-fill.svg");
  mask-image: url("../../icons/system/time-fill.svg");
}

.fr-icon-time-line::before,
.fr-icon-time-line::after {
  -webkit-mask-image: url("../../icons/system/time-line.svg");
  mask-image: url("../../icons/system/time-line.svg");
}

.fr-icon-timer-fill::before,
.fr-icon-timer-fill::after {
  -webkit-mask-image: url("../../icons/system/timer-fill.svg");
  mask-image: url("../../icons/system/timer-fill.svg");
}

.fr-icon-timer-line::before,
.fr-icon-timer-line::after {
  -webkit-mask-image: url("../../icons/system/timer-line.svg");
  mask-image: url("../../icons/system/timer-line.svg");
}

.fr-icon-upload-2-fill::before,
.fr-icon-upload-2-fill::after {
  -webkit-mask-image: url("../../icons/system/upload-2-fill.svg");
  mask-image: url("../../icons/system/upload-2-fill.svg");
}

.fr-icon-upload-2-line::before,
.fr-icon-upload-2-line::after {
  -webkit-mask-image: url("../../icons/system/upload-2-line.svg");
  mask-image: url("../../icons/system/upload-2-line.svg");
}

.fr-icon-upload-fill::before,
.fr-icon-upload-fill::after {
  -webkit-mask-image: url("../../icons/system/upload-fill.svg");
  mask-image: url("../../icons/system/upload-fill.svg");
}

.fr-icon-upload-line::before,
.fr-icon-upload-line::after {
  -webkit-mask-image: url("../../icons/system/upload-line.svg");
  mask-image: url("../../icons/system/upload-line.svg");
}

.fr-icon-zoom-in-fill::before,
.fr-icon-zoom-in-fill::after {
  -webkit-mask-image: url("../../icons/system/zoom-in-fill.svg");
  mask-image: url("../../icons/system/zoom-in-fill.svg");
}

.fr-icon-zoom-in-line::before,
.fr-icon-zoom-in-line::after {
  -webkit-mask-image: url("../../icons/system/zoom-in-line.svg");
  mask-image: url("../../icons/system/zoom-in-line.svg");
}

.fr-icon-zoom-out-fill::before,
.fr-icon-zoom-out-fill::after {
  -webkit-mask-image: url("../../icons/system/zoom-out-fill.svg");
  mask-image: url("../../icons/system/zoom-out-fill.svg");
}

.fr-icon-zoom-out-line::before,
.fr-icon-zoom-out-line::after {
  -webkit-mask-image: url("../../icons/system/zoom-out-line.svg");
  mask-image: url("../../icons/system/zoom-out-line.svg");
}

.fr-fi-add-circle-fill::before,
.fr-fi-add-circle-fill::after {
  -webkit-mask-image: url("../../icons/system/add-circle-fill.svg");
  mask-image: url("../../icons/system/add-circle-fill.svg");
}

.fr-fi-add-circle-line::before,
.fr-fi-add-circle-line::after {
  -webkit-mask-image: url("../../icons/system/add-circle-line.svg");
  mask-image: url("../../icons/system/add-circle-line.svg");
}

.fr-fi-add-line::before,
.fr-fi-add-line::after {
  -webkit-mask-image: url("../../icons/system/add-line.svg");
  mask-image: url("../../icons/system/add-line.svg");
}

.fr-fi-arrow-down-line::before,
.fr-fi-arrow-down-line::after {
  -webkit-mask-image: url("../../icons/system/arrow-down-line.svg");
  mask-image: url("../../icons/system/arrow-down-line.svg");
}

.fr-fi-arrow-down-s-line::before,
.fr-fi-arrow-down-s-line::after {
  -webkit-mask-image: url("../../icons/system/arrow-down-s-line.svg");
  mask-image: url("../../icons/system/arrow-down-s-line.svg");
}

.fr-fi-arrow-go-back-fill::before,
.fr-fi-arrow-go-back-fill::after {
  -webkit-mask-image: url("../../icons/system/arrow-go-back-fill.svg");
  mask-image: url("../../icons/system/arrow-go-back-fill.svg");
}

.fr-fi-arrow-go-back-line::before,
.fr-fi-arrow-go-back-line::after {
  -webkit-mask-image: url("../../icons/system/arrow-go-back-line.svg");
  mask-image: url("../../icons/system/arrow-go-back-line.svg");
}

.fr-fi-arrow-left-line::before,
.fr-fi-arrow-left-line::after {
  -webkit-mask-image: url("../../icons/system/arrow-left-line.svg");
  mask-image: url("../../icons/system/arrow-left-line.svg");
}

.fr-fi-arrow-left-s-line::before,
.fr-fi-arrow-left-s-line::after {
  -webkit-mask-image: url("../../icons/system/arrow-left-s-line.svg");
  mask-image: url("../../icons/system/arrow-left-s-line.svg");
}

.fr-fi-arrow-right-line::before,
.fr-fi-arrow-right-line::after {
  -webkit-mask-image: url("../../icons/system/arrow-right-line.svg");
  mask-image: url("../../icons/system/arrow-right-line.svg");
}

.fr-fi-arrow-right-s-line::before,
.fr-fi-arrow-right-s-line::after {
  -webkit-mask-image: url("../../icons/system/arrow-right-s-line.svg");
  mask-image: url("../../icons/system/arrow-right-s-line.svg");
}

.fr-fi-arrow-right-up-line::before,
.fr-fi-arrow-right-up-line::after {
  -webkit-mask-image: url("../../icons/system/arrow-right-up-line.svg");
  mask-image: url("../../icons/system/arrow-right-up-line.svg");
}

.fr-fi-arrow-up-fill::before,
.fr-fi-arrow-up-fill::after {
  -webkit-mask-image: url("../../icons/system/arrow-up-fill.svg");
  mask-image: url("../../icons/system/arrow-up-fill.svg");
}

.fr-fi-arrow-up-line::before,
.fr-fi-arrow-up-line::after {
  -webkit-mask-image: url("../../icons/system/arrow-up-line.svg");
  mask-image: url("../../icons/system/arrow-up-line.svg");
}

.fr-fi-arrow-up-s-line::before,
.fr-fi-arrow-up-s-line::after {
  -webkit-mask-image: url("../../icons/system/arrow-up-s-line.svg");
  mask-image: url("../../icons/system/arrow-up-s-line.svg");
}

.fr-fi-check-line::before,
.fr-fi-check-line::after {
  -webkit-mask-image: url("../../icons/system/check-line.svg");
  mask-image: url("../../icons/system/check-line.svg");
}

.fr-fi-checkbox-circle-line::before,
.fr-fi-checkbox-circle-line::after {
  -webkit-mask-image: url("../../icons/system/checkbox-circle-line.svg");
  mask-image: url("../../icons/system/checkbox-circle-line.svg");
}

.fr-fi-close-line::before,
.fr-fi-close-line::after {
  -webkit-mask-image: url("../../icons/system/close-line.svg");
  mask-image: url("../../icons/system/close-line.svg");
}

.fr-fi-download-line::before,
.fr-fi-download-line::after {
  -webkit-mask-image: url("../../icons/system/download-line.svg");
  mask-image: url("../../icons/system/download-line.svg");
}

.fr-fi-error-warning-fill::before,
.fr-fi-error-warning-fill::after {
  -webkit-mask-image: url("../../icons/system/error-warning-fill.svg");
  mask-image: url("../../icons/system/error-warning-fill.svg");
}

.fr-fi-error-warning-line::before,
.fr-fi-error-warning-line::after {
  -webkit-mask-image: url("../../icons/system/error-warning-line.svg");
  mask-image: url("../../icons/system/error-warning-line.svg");
}

.fr-fi-external-link-line::before,
.fr-fi-external-link-line::after {
  -webkit-mask-image: url("../../icons/system/external-link-line.svg");
  mask-image: url("../../icons/system/external-link-line.svg");
}

.fr-fi-eye-fill::before,
.fr-fi-eye-fill::after {
  -webkit-mask-image: url("../../icons/system/eye-fill.svg");
  mask-image: url("../../icons/system/eye-fill.svg");
}

.fr-fi-eye-line::before,
.fr-fi-eye-line::after {
  -webkit-mask-image: url("../../icons/system/eye-line.svg");
  mask-image: url("../../icons/system/eye-line.svg");
}

.fr-fi-eye-off-fill::before,
.fr-fi-eye-off-fill::after {
  -webkit-mask-image: url("../../icons/system/eye-off-fill.svg");
  mask-image: url("../../icons/system/eye-off-fill.svg");
}

.fr-fi-eye-off-line::before,
.fr-fi-eye-off-line::after {
  -webkit-mask-image: url("../../icons/system/eye-off-line.svg");
  mask-image: url("../../icons/system/eye-off-line.svg");
}

.fr-fi-filter-fill::before,
.fr-fi-filter-fill::after {
  -webkit-mask-image: url("../../icons/system/filter-fill.svg");
  mask-image: url("../../icons/system/filter-fill.svg");
}

.fr-fi-filter-line::before,
.fr-fi-filter-line::after {
  -webkit-mask-image: url("../../icons/system/filter-line.svg");
  mask-image: url("../../icons/system/filter-line.svg");
}

.fr-fi-arrow-left-s-first-line::before,
.fr-fi-arrow-left-s-first-line::after {
  -webkit-mask-image: url("../../icons/system/fr--arrow-left-s-first-line.svg");
  mask-image: url("../../icons/system/fr--arrow-left-s-first-line.svg");
}

.fr-fi-arrow-left-s-line-double::before,
.fr-fi-arrow-left-s-line-double::after {
  -webkit-mask-image: url("../../icons/system/fr--arrow-left-s-line-double.svg");
  mask-image: url("../../icons/system/fr--arrow-left-s-line-double.svg");
}

.fr-fi-arrow-right-s-last-line::before,
.fr-fi-arrow-right-s-last-line::after {
  -webkit-mask-image: url("../../icons/system/fr--arrow-right-s-last-line.svg");
  mask-image: url("../../icons/system/fr--arrow-right-s-last-line.svg");
}

.fr-fi-arrow-right-s-line-double::before,
.fr-fi-arrow-right-s-line-double::after {
  -webkit-mask-image: url("../../icons/system/fr--arrow-right-s-line-double.svg");
  mask-image: url("../../icons/system/fr--arrow-right-s-line-double.svg");
}

.fr-fi-error-fill::before,
.fr-fi-error-fill::after {
  -webkit-mask-image: url("../../icons/system/fr--error-fill.svg");
  mask-image: url("../../icons/system/fr--error-fill.svg");
}

.fr-fi-error-line::before,
.fr-fi-error-line::after {
  -webkit-mask-image: url("../../icons/system/fr--error-line.svg");
  mask-image: url("../../icons/system/fr--error-line.svg");
}

.fr-fi-info-fill::before,
.fr-fi-info-fill::after {
  -webkit-mask-image: url("../../icons/system/fr--info-fill.svg");
  mask-image: url("../../icons/system/fr--info-fill.svg");
}

.fr-fi-info-line::before,
.fr-fi-info-line::after {
  -webkit-mask-image: url("../../icons/system/fr--info-line.svg");
  mask-image: url("../../icons/system/fr--info-line.svg");
}

.fr-fi-success-fill::before,
.fr-fi-success-fill::after {
  -webkit-mask-image: url("../../icons/system/fr--success-fill.svg");
  mask-image: url("../../icons/system/fr--success-fill.svg");
}

.fr-fi-success-line::before,
.fr-fi-success-line::after {
  -webkit-mask-image: url("../../icons/system/fr--success-line.svg");
  mask-image: url("../../icons/system/fr--success-line.svg");
}

.fr-fi-theme-fill::before,
.fr-fi-theme-fill::after {
  -webkit-mask-image: url("../../icons/system/fr--theme-fill.svg");
  mask-image: url("../../icons/system/fr--theme-fill.svg");
}

.fr-fi-warning-fill::before,
.fr-fi-warning-fill::after {
  -webkit-mask-image: url("../../icons/system/fr--warning-fill.svg");
  mask-image: url("../../icons/system/fr--warning-fill.svg");
}

.fr-fi-warning-line::before,
.fr-fi-warning-line::after {
  -webkit-mask-image: url("../../icons/system/fr--warning-line.svg");
  mask-image: url("../../icons/system/fr--warning-line.svg");
}

.fr-fi-information-fill::before,
.fr-fi-information-fill::after {
  -webkit-mask-image: url("../../icons/system/information-fill.svg");
  mask-image: url("../../icons/system/information-fill.svg");
}

.fr-fi-information-line::before,
.fr-fi-information-line::after {
  -webkit-mask-image: url("../../icons/system/information-line.svg");
  mask-image: url("../../icons/system/information-line.svg");
}

.fr-fi-lock-fill::before,
.fr-fi-lock-fill::after {
  -webkit-mask-image: url("../../icons/system/lock-fill.svg");
  mask-image: url("../../icons/system/lock-fill.svg");
}

.fr-fi-lock-line::before,
.fr-fi-lock-line::after {
  -webkit-mask-image: url("../../icons/system/lock-line.svg");
  mask-image: url("../../icons/system/lock-line.svg");
}

.fr-fi-logout-box-r-fill::before,
.fr-fi-logout-box-r-fill::after {
  -webkit-mask-image: url("../../icons/system/logout-box-r-fill.svg");
  mask-image: url("../../icons/system/logout-box-r-fill.svg");
}

.fr-fi-logout-box-r-line::before,
.fr-fi-logout-box-r-line::after {
  -webkit-mask-image: url("../../icons/system/logout-box-r-line.svg");
  mask-image: url("../../icons/system/logout-box-r-line.svg");
}

.fr-fi-menu-2-fill::before,
.fr-fi-menu-2-fill::after {
  -webkit-mask-image: url("../../icons/system/menu-2-fill.svg");
  mask-image: url("../../icons/system/menu-2-fill.svg");
}

.fr-fi-menu-fill::before,
.fr-fi-menu-fill::after {
  -webkit-mask-image: url("../../icons/system/menu-fill.svg");
  mask-image: url("../../icons/system/menu-fill.svg");
}

.fr-fi-question-fill::before,
.fr-fi-question-fill::after {
  -webkit-mask-image: url("../../icons/system/question-fill.svg");
  mask-image: url("../../icons/system/question-fill.svg");
}

.fr-fi-question-line::before,
.fr-fi-question-line::after {
  -webkit-mask-image: url("../../icons/system/question-line.svg");
  mask-image: url("../../icons/system/question-line.svg");
}

.fr-fi-refresh-fill::before,
.fr-fi-refresh-fill::after {
  -webkit-mask-image: url("../../icons/system/refresh-fill.svg");
  mask-image: url("../../icons/system/refresh-fill.svg");
}

.fr-fi-refresh-line::before,
.fr-fi-refresh-line::after {
  -webkit-mask-image: url("../../icons/system/refresh-line.svg");
  mask-image: url("../../icons/system/refresh-line.svg");
}

.fr-fi-search-fill::before,
.fr-fi-search-fill::after {
  -webkit-mask-image: url("../../icons/system/search-fill.svg");
  mask-image: url("../../icons/system/search-fill.svg");
}

.fr-fi-search-line::before,
.fr-fi-search-line::after {
  -webkit-mask-image: url("../../icons/system/search-line.svg");
  mask-image: url("../../icons/system/search-line.svg");
}

.fr-fi-subtract-line::before,
.fr-fi-subtract-line::after {
  -webkit-mask-image: url("../../icons/system/subtract-line.svg");
  mask-image: url("../../icons/system/subtract-line.svg");
}

.fr-fi-timer-fill::before,
.fr-fi-timer-fill::after {
  -webkit-mask-image: url("../../icons/system/timer-fill.svg");
  mask-image: url("../../icons/system/timer-fill.svg");
}

.fr-fi-timer-line::before,
.fr-fi-timer-line::after {
  -webkit-mask-image: url("../../icons/system/timer-line.svg");
  mask-image: url("../../icons/system/timer-line.svg");
}

.fr-fi-upload-2-fill::before,
.fr-fi-upload-2-fill::after {
  -webkit-mask-image: url("../../icons/system/upload-2-fill.svg");
  mask-image: url("../../icons/system/upload-2-fill.svg");
}

.fr-fi-upload-2-line::before,
.fr-fi-upload-2-line::after {
  -webkit-mask-image: url("../../icons/system/upload-2-line.svg");
  mask-image: url("../../icons/system/upload-2-line.svg");
}

.fr-fi-zoom-in-fill::before,
.fr-fi-zoom-in-fill::after {
  -webkit-mask-image: url("../../icons/system/zoom-in-fill.svg");
  mask-image: url("../../icons/system/zoom-in-fill.svg");
}

.fr-fi-zoom-in-line::before,
.fr-fi-zoom-in-line::after {
  -webkit-mask-image: url("../../icons/system/zoom-in-line.svg");
  mask-image: url("../../icons/system/zoom-in-line.svg");
}

.fr-fi-zoom-out-fill::before,
.fr-fi-zoom-out-fill::after {
  -webkit-mask-image: url("../../icons/system/zoom-out-fill.svg");
  mask-image: url("../../icons/system/zoom-out-fill.svg");
}

.fr-fi-zoom-out-line::before,
.fr-fi-zoom-out-line::after {
  -webkit-mask-image: url("../../icons/system/zoom-out-line.svg");
  mask-image: url("../../icons/system/zoom-out-line.svg");
}

.fr-fi-delete-line::before,
.fr-fi-delete-line::after {
  -webkit-mask-image: url("../../icons/system/delete-bin-line.svg");
  mask-image: url("../../icons/system/delete-bin-line.svg");
}

.fr-fi-delete-fill::before,
.fr-fi-delete-fill::after {
  -webkit-mask-image: url("../../icons/system/delete-bin-fill.svg");
  mask-image: url("../../icons/system/delete-bin-fill.svg");
}

.fr-icon-delete-line::before,
.fr-icon-delete-line::after {
  -webkit-mask-image: url("../../icons/system/delete-bin-line.svg");
  mask-image: url("../../icons/system/delete-bin-line.svg");
}

.fr-icon-delete-fill::before,
.fr-icon-delete-fill::after {
  -webkit-mask-image: url("../../icons/system/delete-bin-fill.svg");
  mask-image: url("../../icons/system/delete-bin-fill.svg");
}

/* ¯¯¯¯¯¯¯¯¯ *\
  ICON COMMUNICATION
\* ˍˍˍˍˍˍˍˍˍ */
.fr-icon-account-circle-fill::before,
.fr-icon-account-circle-fill::after {
  -webkit-mask-image: url("../../icons/user/account-circle-fill.svg");
  mask-image: url("../../icons/user/account-circle-fill.svg");
}

.fr-icon-account-circle-line::before,
.fr-icon-account-circle-line::after {
  -webkit-mask-image: url("../../icons/user/account-circle-line.svg");
  mask-image: url("../../icons/user/account-circle-line.svg");
}

.fr-icon-account-pin-circle-fill::before,
.fr-icon-account-pin-circle-fill::after {
  -webkit-mask-image: url("../../icons/user/account-pin-circle-fill.svg");
  mask-image: url("../../icons/user/account-pin-circle-fill.svg");
}

.fr-icon-account-pin-circle-line::before,
.fr-icon-account-pin-circle-line::after {
  -webkit-mask-image: url("../../icons/user/account-pin-circle-line.svg");
  mask-image: url("../../icons/user/account-pin-circle-line.svg");
}

.fr-icon-admin-fill::before,
.fr-icon-admin-fill::after {
  -webkit-mask-image: url("../../icons/user/admin-fill.svg");
  mask-image: url("../../icons/user/admin-fill.svg");
}

.fr-icon-admin-line::before,
.fr-icon-admin-line::after {
  -webkit-mask-image: url("../../icons/user/admin-line.svg");
  mask-image: url("../../icons/user/admin-line.svg");
}

.fr-icon-group-fill::before,
.fr-icon-group-fill::after {
  -webkit-mask-image: url("../../icons/user/group-fill.svg");
  mask-image: url("../../icons/user/group-fill.svg");
}

.fr-icon-group-line::before,
.fr-icon-group-line::after {
  -webkit-mask-image: url("../../icons/user/group-line.svg");
  mask-image: url("../../icons/user/group-line.svg");
}

.fr-icon-parent-fill::before,
.fr-icon-parent-fill::after {
  -webkit-mask-image: url("../../icons/user/parent-fill.svg");
  mask-image: url("../../icons/user/parent-fill.svg");
}

.fr-icon-parent-line::before,
.fr-icon-parent-line::after {
  -webkit-mask-image: url("../../icons/user/parent-line.svg");
  mask-image: url("../../icons/user/parent-line.svg");
}

.fr-icon-team-fill::before,
.fr-icon-team-fill::after {
  -webkit-mask-image: url("../../icons/user/team-fill.svg");
  mask-image: url("../../icons/user/team-fill.svg");
}

.fr-icon-team-line::before,
.fr-icon-team-line::after {
  -webkit-mask-image: url("../../icons/user/team-line.svg");
  mask-image: url("../../icons/user/team-line.svg");
}

.fr-icon-user-add-fill::before,
.fr-icon-user-add-fill::after {
  -webkit-mask-image: url("../../icons/user/user-add-fill.svg");
  mask-image: url("../../icons/user/user-add-fill.svg");
}

.fr-icon-user-add-line::before,
.fr-icon-user-add-line::after {
  -webkit-mask-image: url("../../icons/user/user-add-line.svg");
  mask-image: url("../../icons/user/user-add-line.svg");
}

.fr-icon-user-fill::before,
.fr-icon-user-fill::after {
  -webkit-mask-image: url("../../icons/user/user-fill.svg");
  mask-image: url("../../icons/user/user-fill.svg");
}

.fr-icon-user-heart-fill::before,
.fr-icon-user-heart-fill::after {
  -webkit-mask-image: url("../../icons/user/user-heart-fill.svg");
  mask-image: url("../../icons/user/user-heart-fill.svg");
}

.fr-icon-user-heart-line::before,
.fr-icon-user-heart-line::after {
  -webkit-mask-image: url("../../icons/user/user-heart-line.svg");
  mask-image: url("../../icons/user/user-heart-line.svg");
}

.fr-icon-user-line::before,
.fr-icon-user-line::after {
  -webkit-mask-image: url("../../icons/user/user-line.svg");
  mask-image: url("../../icons/user/user-line.svg");
}

.fr-icon-user-search-fill::before,
.fr-icon-user-search-fill::after {
  -webkit-mask-image: url("../../icons/user/user-search-fill.svg");
  mask-image: url("../../icons/user/user-search-fill.svg");
}

.fr-icon-user-search-line::before,
.fr-icon-user-search-line::after {
  -webkit-mask-image: url("../../icons/user/user-search-line.svg");
  mask-image: url("../../icons/user/user-search-line.svg");
}

.fr-icon-user-setting-fill::before,
.fr-icon-user-setting-fill::after {
  -webkit-mask-image: url("../../icons/user/user-setting-fill.svg");
  mask-image: url("../../icons/user/user-setting-fill.svg");
}

.fr-icon-user-setting-line::before,
.fr-icon-user-setting-line::after {
  -webkit-mask-image: url("../../icons/user/user-setting-line.svg");
  mask-image: url("../../icons/user/user-setting-line.svg");
}

.fr-icon-user-star-fill::before,
.fr-icon-user-star-fill::after {
  -webkit-mask-image: url("../../icons/user/user-star-fill.svg");
  mask-image: url("../../icons/user/user-star-fill.svg");
}

.fr-icon-user-star-line::before,
.fr-icon-user-star-line::after {
  -webkit-mask-image: url("../../icons/user/user-star-line.svg");
  mask-image: url("../../icons/user/user-star-line.svg");
}

.fr-fi-user-fill::before,
.fr-fi-user-fill::after {
  -webkit-mask-image: url("../../icons/user/user-fill.svg");
  mask-image: url("../../icons/user/user-fill.svg");
}

.fr-fi-user-line::before,
.fr-fi-user-line::after {
  -webkit-mask-image: url("../../icons/user/user-line.svg");
  mask-image: url("../../icons/user/user-line.svg");
}

.fr-fi-account-line::before,
.fr-fi-account-line::after {
  -webkit-mask-image: url("../../icons/user/account-circle-line.svg");
  mask-image: url("../../icons/user/account-circle-line.svg");
}

.fr-fi-account-fill::before,
.fr-fi-account-fill::after {
  -webkit-mask-image: url("../../icons/user/account-circle-fill.svg");
  mask-image: url("../../icons/user/account-circle-fill.svg");
}

.fr-icon-account-line::before,
.fr-icon-account-line::after {
  -webkit-mask-image: url("../../icons/user/account-circle-line.svg");
  mask-image: url("../../icons/user/account-circle-line.svg");
}

.fr-icon-account-fill::before,
.fr-icon-account-fill::after {
  -webkit-mask-image: url("../../icons/user/account-circle-fill.svg");
  mask-image: url("../../icons/user/account-circle-fill.svg");
}

/* ¯¯¯¯¯¯¯¯¯ *\
  ICON COMMUNICATION
\* ˍˍˍˍˍˍˍˍˍ */
.fr-icon-cloudy-2-fill::before,
.fr-icon-cloudy-2-fill::after {
  -webkit-mask-image: url("../../icons/weather/cloudy-2-fill.svg");
  mask-image: url("../../icons/weather/cloudy-2-fill.svg");
}

.fr-icon-cloudy-2-line::before,
.fr-icon-cloudy-2-line::after {
  -webkit-mask-image: url("../../icons/weather/cloudy-2-line.svg");
  mask-image: url("../../icons/weather/cloudy-2-line.svg");
}

.fr-icon-fire-fill::before,
.fr-icon-fire-fill::after {
  -webkit-mask-image: url("../../icons/weather/fire-fill.svg");
  mask-image: url("../../icons/weather/fire-fill.svg");
}

.fr-icon-fire-line::before,
.fr-icon-fire-line::after {
  -webkit-mask-image: url("../../icons/weather/fire-line.svg");
  mask-image: url("../../icons/weather/fire-line.svg");
}

.fr-icon-flashlight-fill::before,
.fr-icon-flashlight-fill::after {
  -webkit-mask-image: url("../../icons/weather/flashlight-fill.svg");
  mask-image: url("../../icons/weather/flashlight-fill.svg");
}

.fr-icon-flashlight-line::before,
.fr-icon-flashlight-line::after {
  -webkit-mask-image: url("../../icons/weather/flashlight-line.svg");
  mask-image: url("../../icons/weather/flashlight-line.svg");
}

.fr-icon-flood-fill::before,
.fr-icon-flood-fill::after {
  -webkit-mask-image: url("../../icons/weather/flood-fill.svg");
  mask-image: url("../../icons/weather/flood-fill.svg");
}

.fr-icon-flood-line::before,
.fr-icon-flood-line::after {
  -webkit-mask-image: url("../../icons/weather/flood-line.svg");
  mask-image: url("../../icons/weather/flood-line.svg");
}

.fr-icon-avalanches-fill::before,
.fr-icon-avalanches-fill::after {
  -webkit-mask-image: url("../../icons/weather/fr--avalanches-fill.svg");
  mask-image: url("../../icons/weather/fr--avalanches-fill.svg");
}

.fr-icon-submersion-fill::before,
.fr-icon-submersion-fill::after {
  -webkit-mask-image: url("../../icons/weather/fr--submersion-fill.svg");
  mask-image: url("../../icons/weather/fr--submersion-fill.svg");
}

.fr-icon-heavy-showers-fill::before,
.fr-icon-heavy-showers-fill::after {
  -webkit-mask-image: url("../../icons/weather/heavy-showers-fill.svg");
  mask-image: url("../../icons/weather/heavy-showers-fill.svg");
}

.fr-icon-heavy-showers-line::before,
.fr-icon-heavy-showers-line::after {
  -webkit-mask-image: url("../../icons/weather/heavy-showers-line.svg");
  mask-image: url("../../icons/weather/heavy-showers-line.svg");
}

.fr-icon-moon-fill::before,
.fr-icon-moon-fill::after {
  -webkit-mask-image: url("../../icons/weather/moon-fill.svg");
  mask-image: url("../../icons/weather/moon-fill.svg");
}

.fr-icon-moon-line::before,
.fr-icon-moon-line::after {
  -webkit-mask-image: url("../../icons/weather/moon-line.svg");
  mask-image: url("../../icons/weather/moon-line.svg");
}

.fr-icon-snowy-fill::before,
.fr-icon-snowy-fill::after {
  -webkit-mask-image: url("../../icons/weather/snowy-fill.svg");
  mask-image: url("../../icons/weather/snowy-fill.svg");
}

.fr-icon-snowy-line::before,
.fr-icon-snowy-line::after {
  -webkit-mask-image: url("../../icons/weather/snowy-line.svg");
  mask-image: url("../../icons/weather/snowy-line.svg");
}

.fr-icon-sparkling-2-fill::before,
.fr-icon-sparkling-2-fill::after {
  -webkit-mask-image: url("../../icons/weather/sparkling-2-fill.svg");
  mask-image: url("../../icons/weather/sparkling-2-fill.svg");
}

.fr-icon-sparkling-2-line::before,
.fr-icon-sparkling-2-line::after {
  -webkit-mask-image: url("../../icons/weather/sparkling-2-line.svg");
  mask-image: url("../../icons/weather/sparkling-2-line.svg");
}

.fr-icon-sun-fill::before,
.fr-icon-sun-fill::after {
  -webkit-mask-image: url("../../icons/weather/sun-fill.svg");
  mask-image: url("../../icons/weather/sun-fill.svg");
}

.fr-icon-sun-line::before,
.fr-icon-sun-line::after {
  -webkit-mask-image: url("../../icons/weather/sun-line.svg");
  mask-image: url("../../icons/weather/sun-line.svg");
}

.fr-icon-temp-cold-fill::before,
.fr-icon-temp-cold-fill::after {
  -webkit-mask-image: url("../../icons/weather/temp-cold-fill.svg");
  mask-image: url("../../icons/weather/temp-cold-fill.svg");
}

.fr-icon-temp-cold-line::before,
.fr-icon-temp-cold-line::after {
  -webkit-mask-image: url("../../icons/weather/temp-cold-line.svg");
  mask-image: url("../../icons/weather/temp-cold-line.svg");
}

.fr-icon-thunderstorms-fill::before,
.fr-icon-thunderstorms-fill::after {
  -webkit-mask-image: url("../../icons/weather/thunderstorms-fill.svg");
  mask-image: url("../../icons/weather/thunderstorms-fill.svg");
}

.fr-icon-thunderstorms-line::before,
.fr-icon-thunderstorms-line::after {
  -webkit-mask-image: url("../../icons/weather/thunderstorms-line.svg");
  mask-image: url("../../icons/weather/thunderstorms-line.svg");
}

.fr-icon-tornado-fill::before,
.fr-icon-tornado-fill::after {
  -webkit-mask-image: url("../../icons/weather/tornado-fill.svg");
  mask-image: url("../../icons/weather/tornado-fill.svg");
}

.fr-icon-tornado-line::before,
.fr-icon-tornado-line::after {
  -webkit-mask-image: url("../../icons/weather/tornado-line.svg");
  mask-image: url("../../icons/weather/tornado-line.svg");
}

.fr-icon-typhoon-fill::before,
.fr-icon-typhoon-fill::after {
  -webkit-mask-image: url("../../icons/weather/typhoon-fill.svg");
  mask-image: url("../../icons/weather/typhoon-fill.svg");
}

.fr-icon-typhoon-line::before,
.fr-icon-typhoon-line::after {
  -webkit-mask-image: url("../../icons/weather/typhoon-line.svg");
  mask-image: url("../../icons/weather/typhoon-line.svg");
}

.fr-icon-windy-fill::before,
.fr-icon-windy-fill::after {
  -webkit-mask-image: url("../../icons/weather/windy-fill.svg");
  mask-image: url("../../icons/weather/windy-fill.svg");
}

.fr-icon-windy-line::before,
.fr-icon-windy-line::after {
  -webkit-mask-image: url("../../icons/weather/windy-line.svg");
  mask-image: url("../../icons/weather/windy-line.svg");
}

.fr-fi-flashlight-fill::before,
.fr-fi-flashlight-fill::after {
  -webkit-mask-image: url("../../icons/weather/flashlight-fill.svg");
  mask-image: url("../../icons/weather/flashlight-fill.svg");
}

.fr-fi-flashlight-line::before,
.fr-fi-flashlight-line::after {
  -webkit-mask-image: url("../../icons/weather/flashlight-line.svg");
  mask-image: url("../../icons/weather/flashlight-line.svg");
}

@media (min-width: 36em) {
  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */

  /*! media sm */
}

@media (min-width: 48em) {
  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */

  /*! media md */
}

@media (min-width: 62em) {
  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */

  /*! media lg */
}

@media (min-width: 78em) {
  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */

  /*! media xl */
}

@media screen and (min-width: 0\0) and (min-resolution: 72dpi) {
  .fr-icon-ancient-gate-fill::before,
  .fr-icon-ancient-gate-fill::after {
    background-image: url("../../icons/buildings/ancient-gate-fill.svg");
  }

  .fr-icon-ancient-gate-line::before,
  .fr-icon-ancient-gate-line::after {
    background-image: url("../../icons/buildings/ancient-gate-line.svg");
  }

  .fr-icon-ancient-pavilion-fill::before,
  .fr-icon-ancient-pavilion-fill::after {
    background-image: url("../../icons/buildings/ancient-pavilion-fill.svg");
  }

  .fr-icon-ancient-pavilion-line::before,
  .fr-icon-ancient-pavilion-line::after {
    background-image: url("../../icons/buildings/ancient-pavilion-line.svg");
  }

  .fr-icon-bank-fill::before,
  .fr-icon-bank-fill::after {
    background-image: url("../../icons/buildings/bank-fill.svg");
  }

  .fr-icon-bank-line::before,
  .fr-icon-bank-line::after {
    background-image: url("../../icons/buildings/bank-line.svg");
  }

  .fr-icon-building-fill::before,
  .fr-icon-building-fill::after {
    background-image: url("../../icons/buildings/building-fill.svg");
  }

  .fr-icon-building-line::before,
  .fr-icon-building-line::after {
    background-image: url("../../icons/buildings/building-line.svg");
  }

  .fr-icon-community-fill::before,
  .fr-icon-community-fill::after {
    background-image: url("../../icons/buildings/community-fill.svg");
  }

  .fr-icon-community-line::before,
  .fr-icon-community-line::after {
    background-image: url("../../icons/buildings/community-line.svg");
  }

  .fr-icon-government-fill::before,
  .fr-icon-government-fill::after {
    background-image: url("../../icons/buildings/government-fill.svg");
  }

  .fr-icon-government-line::before,
  .fr-icon-government-line::after {
    background-image: url("../../icons/buildings/government-line.svg");
  }

  .fr-icon-home-4-fill::before,
  .fr-icon-home-4-fill::after {
    background-image: url("../../icons/buildings/home-4-fill.svg");
  }

  .fr-icon-home-4-line::before,
  .fr-icon-home-4-line::after {
    background-image: url("../../icons/buildings/home-4-line.svg");
  }

  .fr-icon-hospital-fill::before,
  .fr-icon-hospital-fill::after {
    background-image: url("../../icons/buildings/hospital-fill.svg");
  }

  .fr-icon-hospital-line::before,
  .fr-icon-hospital-line::after {
    background-image: url("../../icons/buildings/hospital-line.svg");
  }

  .fr-icon-hotel-fill::before,
  .fr-icon-hotel-fill::after {
    background-image: url("../../icons/buildings/hotel-fill.svg");
  }

  .fr-icon-hotel-line::before,
  .fr-icon-hotel-line::after {
    background-image: url("../../icons/buildings/hotel-line.svg");
  }

  .fr-icon-store-fill::before,
  .fr-icon-store-fill::after {
    background-image: url("../../icons/buildings/store-fill.svg");
  }

  .fr-icon-store-line::before,
  .fr-icon-store-line::after {
    background-image: url("../../icons/buildings/store-line.svg");
  }

  .fr-fi-home-4-fill::before {
    background-image: url("../../icons/buildings/home-4-fill.svg");
  }

  .fr-fi-home-4-line::before {
    background-image: url("../../icons/buildings/home-4-line.svg");
  }

  .fr-icon-archive-fill::before,
  .fr-icon-archive-fill::after {
    background-image: url("../../icons/business/archive-fill.svg");
  }

  .fr-icon-archive-line::before,
  .fr-icon-archive-line::after {
    background-image: url("../../icons/business/archive-line.svg");
  }

  .fr-icon-attachment-fill::before,
  .fr-icon-attachment-fill::after {
    background-image: url("../../icons/business/attachment-fill.svg");
  }

  .fr-icon-attachment-line::before,
  .fr-icon-attachment-line::after {
    background-image: url("../../icons/business/attachment-line.svg");
  }

  .fr-icon-award-fill::before,
  .fr-icon-award-fill::after {
    background-image: url("../../icons/business/award-fill.svg");
  }

  .fr-icon-award-line::before,
  .fr-icon-award-line::after {
    background-image: url("../../icons/business/award-line.svg");
  }

  .fr-icon-bar-chart-box-fill::before,
  .fr-icon-bar-chart-box-fill::after {
    background-image: url("../../icons/business/bar-chart-box-fill.svg");
  }

  .fr-icon-bar-chart-box-line::before,
  .fr-icon-bar-chart-box-line::after {
    background-image: url("../../icons/business/bar-chart-box-line.svg");
  }

  .fr-icon-bookmark-fill::before,
  .fr-icon-bookmark-fill::after {
    background-image: url("../../icons/business/bookmark-fill.svg");
  }

  .fr-icon-bookmark-line::before,
  .fr-icon-bookmark-line::after {
    background-image: url("../../icons/business/bookmark-line.svg");
  }

  .fr-icon-briefcase-fill::before,
  .fr-icon-briefcase-fill::after {
    background-image: url("../../icons/business/briefcase-fill.svg");
  }

  .fr-icon-briefcase-line::before,
  .fr-icon-briefcase-line::after {
    background-image: url("../../icons/business/briefcase-line.svg");
  }

  .fr-icon-calendar-2-fill::before,
  .fr-icon-calendar-2-fill::after {
    background-image: url("../../icons/business/calendar-2-fill.svg");
  }

  .fr-icon-calendar-2-line::before,
  .fr-icon-calendar-2-line::after {
    background-image: url("../../icons/business/calendar-2-line.svg");
  }

  .fr-icon-calendar-event-fill::before,
  .fr-icon-calendar-event-fill::after {
    background-image: url("../../icons/business/calendar-event-fill.svg");
  }

  .fr-icon-calendar-event-line::before,
  .fr-icon-calendar-event-line::after {
    background-image: url("../../icons/business/calendar-event-line.svg");
  }

  .fr-icon-calendar-fill::before,
  .fr-icon-calendar-fill::after {
    background-image: url("../../icons/business/calendar-fill.svg");
  }

  .fr-icon-calendar-line::before,
  .fr-icon-calendar-line::after {
    background-image: url("../../icons/business/calendar-line.svg");
  }

  .fr-icon-cloud-fill::before,
  .fr-icon-cloud-fill::after {
    background-image: url("../../icons/business/cloud-fill.svg");
  }

  .fr-icon-cloud-line::before,
  .fr-icon-cloud-line::after {
    background-image: url("../../icons/business/cloud-line.svg");
  }

  .fr-icon-copyright-fill::before,
  .fr-icon-copyright-fill::after {
    background-image: url("../../icons/business/copyright-fill.svg");
  }

  .fr-icon-copyright-line::before,
  .fr-icon-copyright-line::after {
    background-image: url("../../icons/business/copyright-line.svg");
  }

  .fr-icon-customer-service-fill::before,
  .fr-icon-customer-service-fill::after {
    background-image: url("../../icons/business/customer-service-fill.svg");
  }

  .fr-icon-customer-service-line::before,
  .fr-icon-customer-service-line::after {
    background-image: url("../../icons/business/customer-service-line.svg");
  }

  .fr-icon-flag-fill::before,
  .fr-icon-flag-fill::after {
    background-image: url("../../icons/business/flag-fill.svg");
  }

  .fr-icon-flag-line::before,
  .fr-icon-flag-line::after {
    background-image: url("../../icons/business/flag-line.svg");
  }

  .fr-icon-global-fill::before,
  .fr-icon-global-fill::after {
    background-image: url("../../icons/business/global-fill.svg");
  }

  .fr-icon-global-line::before,
  .fr-icon-global-line::after {
    background-image: url("../../icons/business/global-line.svg");
  }

  .fr-icon-line-chart-fill::before,
  .fr-icon-line-chart-fill::after {
    background-image: url("../../icons/business/line-chart-fill.svg");
  }

  .fr-icon-line-chart-line::before,
  .fr-icon-line-chart-line::after {
    background-image: url("../../icons/business/line-chart-line.svg");
  }

  .fr-icon-links-fill::before,
  .fr-icon-links-fill::after {
    background-image: url("../../icons/business/links-fill.svg");
  }

  .fr-icon-links-line::before,
  .fr-icon-links-line::after {
    background-image: url("../../icons/business/links-line.svg");
  }

  .fr-icon-mail-fill::before,
  .fr-icon-mail-fill::after {
    background-image: url("../../icons/business/mail-fill.svg");
  }

  .fr-icon-mail-line::before,
  .fr-icon-mail-line::after {
    background-image: url("../../icons/business/mail-line.svg");
  }

  .fr-icon-mail-open-fill::before,
  .fr-icon-mail-open-fill::after {
    background-image: url("../../icons/business/mail-open-fill.svg");
  }

  .fr-icon-mail-open-line::before,
  .fr-icon-mail-open-line::after {
    background-image: url("../../icons/business/mail-open-line.svg");
  }

  .fr-icon-medal-fill::before,
  .fr-icon-medal-fill::after {
    background-image: url("../../icons/business/medal-fill.svg");
  }

  .fr-icon-medal-line::before,
  .fr-icon-medal-line::after {
    background-image: url("../../icons/business/medal-line.svg");
  }

  .fr-icon-pie-chart-2-fill::before,
  .fr-icon-pie-chart-2-fill::after {
    background-image: url("../../icons/business/pie-chart-2-fill.svg");
  }

  .fr-icon-pie-chart-2-line::before,
  .fr-icon-pie-chart-2-line::after {
    background-image: url("../../icons/business/pie-chart-2-line.svg");
  }

  .fr-icon-pie-chart-box-fill::before,
  .fr-icon-pie-chart-box-fill::after {
    background-image: url("../../icons/business/pie-chart-box-fill.svg");
  }

  .fr-icon-pie-chart-box-line::before,
  .fr-icon-pie-chart-box-line::after {
    background-image: url("../../icons/business/pie-chart-box-line.svg");
  }

  .fr-icon-printer-fill::before,
  .fr-icon-printer-fill::after {
    background-image: url("../../icons/business/printer-fill.svg");
  }

  .fr-icon-printer-line::before,
  .fr-icon-printer-line::after {
    background-image: url("../../icons/business/printer-line.svg");
  }

  .fr-icon-profil-fill::before,
  .fr-icon-profil-fill::after {
    background-image: url("../../icons/business/profil-fill.svg");
  }

  .fr-icon-profil-line::before,
  .fr-icon-profil-line::after {
    background-image: url("../../icons/business/profil-line.svg");
  }

  .fr-icon-projector-2-fill::before,
  .fr-icon-projector-2-fill::after {
    background-image: url("../../icons/business/projector-2-fill.svg");
  }

  .fr-icon-projector-2-line::before,
  .fr-icon-projector-2-line::after {
    background-image: url("../../icons/business/projector-2-line.svg");
  }

  .fr-icon-send-plane-fill::before,
  .fr-icon-send-plane-fill::after {
    background-image: url("../../icons/business/send-plane-fill.svg");
  }

  .fr-icon-send-plane-line::before,
  .fr-icon-send-plane-line::after {
    background-image: url("../../icons/business/send-plane-line.svg");
  }

  .fr-icon-slideshow-fill::before,
  .fr-icon-slideshow-fill::after {
    background-image: url("../../icons/business/slideshow-fill.svg");
  }

  .fr-icon-slideshow-line::before,
  .fr-icon-slideshow-line::after {
    background-image: url("../../icons/business/slideshow-line.svg");
  }

  .fr-icon-window-fill::before,
  .fr-icon-window-fill::after {
    background-image: url("../../icons/business/window-fill.svg");
  }

  .fr-icon-window-line::before,
  .fr-icon-window-line::after {
    background-image: url("../../icons/business/window-line.svg");
  }

  .fr-fi-attachment-fill::before {
    background-image: url("../../icons/business/attachment-fill.svg");
  }

  .fr-fi-attachment-line::before {
    background-image: url("../../icons/business/attachment-line.svg");
  }

  .fr-fi-calendar-fill::before {
    background-image: url("../../icons/business/calendar-fill.svg");
  }

  .fr-fi-calendar-line::before {
    background-image: url("../../icons/business/calendar-line.svg");
  }

  .fr-fi-line-chart-fill::before {
    background-image: url("../../icons/business/line-chart-fill.svg");
  }

  .fr-fi-line-chart-line::before {
    background-image: url("../../icons/business/line-chart-line.svg");
  }

  .fr-fi-links-fill::before {
    background-image: url("../../icons/business/links-fill.svg");
  }

  .fr-fi-mail-fill::before {
    background-image: url("../../icons/business/mail-fill.svg");
  }

  .fr-fi-mail-line::before {
    background-image: url("../../icons/business/mail-line.svg");
  }

  .fr-fi-printer-fill::before {
    background-image: url("../../icons/business/printer-fill.svg");
  }

  .fr-fi-printer-line::before {
    background-image: url("../../icons/business/printer-line.svg");
  }

  .fr-icon-chat-2-fill::before,
  .fr-icon-chat-2-fill::after {
    background-image: url("../../icons/communication/chat-2-fill.svg");
  }

  .fr-icon-chat-2-line::before,
  .fr-icon-chat-2-line::after {
    background-image: url("../../icons/communication/chat-2-line.svg");
  }

  .fr-icon-chat-3-fill::before,
  .fr-icon-chat-3-fill::after {
    background-image: url("../../icons/communication/chat-3-fill.svg");
  }

  .fr-icon-chat-3-line::before,
  .fr-icon-chat-3-line::after {
    background-image: url("../../icons/communication/chat-3-line.svg");
  }

  .fr-icon-chat-check-fill::before,
  .fr-icon-chat-check-fill::after {
    background-image: url("../../icons/communication/chat-check-fill.svg");
  }

  .fr-icon-chat-check-line::before,
  .fr-icon-chat-check-line::after {
    background-image: url("../../icons/communication/chat-check-line.svg");
  }

  .fr-icon-chat-delete-fill::before,
  .fr-icon-chat-delete-fill::after {
    background-image: url("../../icons/communication/chat-delete-fill.svg");
  }

  .fr-icon-chat-delete-line::before,
  .fr-icon-chat-delete-line::after {
    background-image: url("../../icons/communication/chat-delete-line.svg");
  }

  .fr-icon-chat-poll-fill::before,
  .fr-icon-chat-poll-fill::after {
    background-image: url("../../icons/communication/chat-poll-fill.svg");
  }

  .fr-icon-chat-poll-line::before,
  .fr-icon-chat-poll-line::after {
    background-image: url("../../icons/communication/chat-poll-line.svg");
  }

  .fr-icon-discuss-fill::before,
  .fr-icon-discuss-fill::after {
    background-image: url("../../icons/communication/discuss-fill.svg");
  }

  .fr-icon-discuss-line::before,
  .fr-icon-discuss-line::after {
    background-image: url("../../icons/communication/discuss-line.svg");
  }

  .fr-icon-feedback-fill::before,
  .fr-icon-feedback-fill::after {
    background-image: url("../../icons/communication/feedback-fill.svg");
  }

  .fr-icon-feedback-line::before,
  .fr-icon-feedback-line::after {
    background-image: url("../../icons/communication/feedback-line.svg");
  }

  .fr-icon-message-2-fill::before,
  .fr-icon-message-2-fill::after {
    background-image: url("../../icons/communication/message-2-fill.svg");
  }

  .fr-icon-message-2-line::before,
  .fr-icon-message-2-line::after {
    background-image: url("../../icons/communication/message-2-line.svg");
  }

  .fr-icon-question-answer-fill::before,
  .fr-icon-question-answer-fill::after {
    background-image: url("../../icons/communication/question-answer-fill.svg");
  }

  .fr-icon-question-answer-line::before,
  .fr-icon-question-answer-line::after {
    background-image: url("../../icons/communication/question-answer-line.svg");
  }

  .fr-icon-questionnaire-fill::before,
  .fr-icon-questionnaire-fill::after {
    background-image: url("../../icons/communication/questionnaire-fill.svg");
  }

  .fr-icon-questionnaire-line::before,
  .fr-icon-questionnaire-line::after {
    background-image: url("../../icons/communication/questionnaire-line.svg");
  }

  .fr-icon-video-chat-fill::before,
  .fr-icon-video-chat-fill::after {
    background-image: url("../../icons/communication/video-chat-fill.svg");
  }

  .fr-icon-video-chat-line::before,
  .fr-icon-video-chat-line::after {
    background-image: url("../../icons/communication/video-chat-line.svg");
  }

  .fr-icon-ball-pen-fill::before,
  .fr-icon-ball-pen-fill::after {
    background-image: url("../../icons/design/ball-pen-fill.svg");
  }

  .fr-icon-ball-pen-line::before,
  .fr-icon-ball-pen-line::after {
    background-image: url("../../icons/design/ball-pen-line.svg");
  }

  .fr-icon-brush-3-fill::before,
  .fr-icon-brush-3-fill::after {
    background-image: url("../../icons/design/brush-3-fill.svg");
  }

  .fr-icon-brush-3-line::before,
  .fr-icon-brush-3-line::after {
    background-image: url("../../icons/design/brush-3-line.svg");
  }

  .fr-icon-brush-fill::before,
  .fr-icon-brush-fill::after {
    background-image: url("../../icons/design/brush-fill.svg");
  }

  .fr-icon-brush-line::before,
  .fr-icon-brush-line::after {
    background-image: url("../../icons/design/brush-line.svg");
  }

  .fr-icon-contrast-fill::before,
  .fr-icon-contrast-fill::after {
    background-image: url("../../icons/design/contrast-fill.svg");
  }

  .fr-icon-contrast-line::before,
  .fr-icon-contrast-line::after {
    background-image: url("../../icons/design/contrast-line.svg");
  }

  .fr-icon-crop-fill::before,
  .fr-icon-crop-fill::after {
    background-image: url("../../icons/design/crop-fill.svg");
  }

  .fr-icon-crop-line::before,
  .fr-icon-crop-line::after {
    background-image: url("../../icons/design/crop-line.svg");
  }

  .fr-icon-drag-move-2-fill::before,
  .fr-icon-drag-move-2-fill::after {
    background-image: url("../../icons/design/drag-move-2-fill.svg");
  }

  .fr-icon-drag-move-2-line::before,
  .fr-icon-drag-move-2-line::after {
    background-image: url("../../icons/design/drag-move-2-line.svg");
  }

  .fr-icon-drop-fill::before,
  .fr-icon-drop-fill::after {
    background-image: url("../../icons/design/drop-fill.svg");
  }

  .fr-icon-drop-line::before,
  .fr-icon-drop-line::after {
    background-image: url("../../icons/design/drop-line.svg");
  }

  .fr-icon-edit-box-fill::before,
  .fr-icon-edit-box-fill::after {
    background-image: url("../../icons/design/edit-box-fill.svg");
  }

  .fr-icon-edit-box-line::before,
  .fr-icon-edit-box-line::after {
    background-image: url("../../icons/design/edit-box-line.svg");
  }

  .fr-icon-edit-fill::before,
  .fr-icon-edit-fill::after {
    background-image: url("../../icons/design/edit-fill.svg");
  }

  .fr-icon-edit-line::before,
  .fr-icon-edit-line::after {
    background-image: url("../../icons/design/edit-line.svg");
  }

  .fr-icon-ink-bottle-fill::before,
  .fr-icon-ink-bottle-fill::after {
    background-image: url("../../icons/design/ink-bottle-fill.svg");
  }

  .fr-icon-ink-bottle-line::before,
  .fr-icon-ink-bottle-line::after {
    background-image: url("../../icons/design/ink-bottle-line.svg");
  }

  .fr-icon-layout-grid-fill::before,
  .fr-icon-layout-grid-fill::after {
    background-image: url("../../icons/design/layout-grid-fill.svg");
  }

  .fr-icon-layout-grid-line::before,
  .fr-icon-layout-grid-line::after {
    background-image: url("../../icons/design/layout-grid-line.svg");
  }

  .fr-icon-mark-pen-fill::before,
  .fr-icon-mark-pen-fill::after {
    background-image: url("../../icons/design/mark-pen-fill.svg");
  }

  .fr-icon-mark-pen-line::before,
  .fr-icon-mark-pen-line::after {
    background-image: url("../../icons/design/mark-pen-line.svg");
  }

  .fr-icon-paint-brush-fill::before,
  .fr-icon-paint-brush-fill::after {
    background-image: url("../../icons/design/paint-brush-fill.svg");
  }

  .fr-icon-paint-brush-line::before,
  .fr-icon-paint-brush-line::after {
    background-image: url("../../icons/design/paint-brush-line.svg");
  }

  .fr-icon-paint-fill::before,
  .fr-icon-paint-fill::after {
    background-image: url("../../icons/design/paint-fill.svg");
  }

  .fr-icon-paint-line::before,
  .fr-icon-paint-line::after {
    background-image: url("../../icons/design/paint-line.svg");
  }

  .fr-icon-palette-fill::before,
  .fr-icon-palette-fill::after {
    background-image: url("../../icons/design/palette-fill.svg");
  }

  .fr-icon-palette-line::before,
  .fr-icon-palette-line::after {
    background-image: url("../../icons/design/palette-line.svg");
  }

  .fr-icon-pantone-fill::before,
  .fr-icon-pantone-fill::after {
    background-image: url("../../icons/design/pantone-fill.svg");
  }

  .fr-icon-pantone-line::before,
  .fr-icon-pantone-line::after {
    background-image: url("../../icons/design/pantone-line.svg");
  }

  .fr-icon-pen-nib-fill::before,
  .fr-icon-pen-nib-fill::after {
    background-image: url("../../icons/design/pen-nib-fill.svg");
  }

  .fr-icon-pen-nib-line::before,
  .fr-icon-pen-nib-line::after {
    background-image: url("../../icons/design/pen-nib-line.svg");
  }

  .fr-icon-pencil-fill::before,
  .fr-icon-pencil-fill::after {
    background-image: url("../../icons/design/pencil-fill.svg");
  }

  .fr-icon-pencil-line::before,
  .fr-icon-pencil-line::after {
    background-image: url("../../icons/design/pencil-line.svg");
  }

  .fr-icon-pencil-ruler-fill::before,
  .fr-icon-pencil-ruler-fill::after {
    background-image: url("../../icons/design/pencil-ruler-fill.svg");
  }

  .fr-icon-pencil-ruler-line::before,
  .fr-icon-pencil-ruler-line::after {
    background-image: url("../../icons/design/pencil-ruler-line.svg");
  }

  .fr-icon-shapes-fill::before,
  .fr-icon-shapes-fill::after {
    background-image: url("../../icons/design/shapes-fill.svg");
  }

  .fr-icon-shapes-line::before,
  .fr-icon-shapes-line::after {
    background-image: url("../../icons/design/shapes-line.svg");
  }

  .fr-icon-sip-fill::before,
  .fr-icon-sip-fill::after {
    background-image: url("../../icons/design/sip-fill.svg");
  }

  .fr-icon-sip-line::before,
  .fr-icon-sip-line::after {
    background-image: url("../../icons/design/sip-line.svg");
  }

  .fr-icon-table-fill::before,
  .fr-icon-table-fill::after {
    background-image: url("../../icons/design/table-fill.svg");
  }

  .fr-icon-table-line::before,
  .fr-icon-table-line::after {
    background-image: url("../../icons/design/table-line.svg");
  }

  .fr-fi-edit-fill::before {
    background-image: url("../../icons/design/edit-fill.svg");
  }

  .fr-fi-edit-line::before {
    background-image: url("../../icons/design/edit-line.svg");
  }

  .fr-icon-bug-fill::before,
  .fr-icon-bug-fill::after {
    background-image: url("../../icons/development/bug-fill.svg");
  }

  .fr-icon-bug-line::before,
  .fr-icon-bug-line::after {
    background-image: url("../../icons/development/bug-line.svg");
  }

  .fr-icon-code-box-fill::before,
  .fr-icon-code-box-fill::after {
    background-image: url("../../icons/development/code-box-fill.svg");
  }

  .fr-icon-code-box-line::before,
  .fr-icon-code-box-line::after {
    background-image: url("../../icons/development/code-box-line.svg");
  }

  .fr-icon-code-s-slash-line::before,
  .fr-icon-code-s-slash-line::after {
    background-image: url("../../icons/development/code-s-slash-line.svg");
  }

  .fr-icon-cursor-fill::before,
  .fr-icon-cursor-fill::after {
    background-image: url("../../icons/development/cursor-fill.svg");
  }

  .fr-icon-cursor-line::before,
  .fr-icon-cursor-line::after {
    background-image: url("../../icons/development/cursor-line.svg");
  }

  .fr-icon-git-branch-fill::before,
  .fr-icon-git-branch-fill::after {
    background-image: url("../../icons/development/git-branch-fill.svg");
  }

  .fr-icon-git-branch-line::before,
  .fr-icon-git-branch-line::after {
    background-image: url("../../icons/development/git-branch-line.svg");
  }

  .fr-icon-git-commit-fill::before,
  .fr-icon-git-commit-fill::after {
    background-image: url("../../icons/development/git-commit-fill.svg");
  }

  .fr-icon-git-commit-line::before,
  .fr-icon-git-commit-line::after {
    background-image: url("../../icons/development/git-commit-line.svg");
  }

  .fr-icon-git-merge-fill::before,
  .fr-icon-git-merge-fill::after {
    background-image: url("../../icons/development/git-merge-fill.svg");
  }

  .fr-icon-git-merge-line::before,
  .fr-icon-git-merge-line::after {
    background-image: url("../../icons/development/git-merge-line.svg");
  }

  .fr-icon-git-pull-request-fill::before,
  .fr-icon-git-pull-request-fill::after {
    background-image: url("../../icons/development/git-pull-request-fill.svg");
  }

  .fr-icon-git-pull-request-line::before,
  .fr-icon-git-pull-request-line::after {
    background-image: url("../../icons/development/git-pull-request-line.svg");
  }

  .fr-icon-git-repository-commits-fill::before,
  .fr-icon-git-repository-commits-fill::after {
    background-image: url("../../icons/development/git-repository-commits-fill.svg");
  }

  .fr-icon-git-repository-commits-line::before,
  .fr-icon-git-repository-commits-line::after {
    background-image: url("../../icons/development/git-repository-commits-line.svg");
  }

  .fr-icon-git-repository-fill::before,
  .fr-icon-git-repository-fill::after {
    background-image: url("../../icons/development/git-repository-fill.svg");
  }

  .fr-icon-git-repository-line::before,
  .fr-icon-git-repository-line::after {
    background-image: url("../../icons/development/git-repository-line.svg");
  }

  .fr-icon-git-repository-private-fill::before,
  .fr-icon-git-repository-private-fill::after {
    background-image: url("../../icons/development/git-repository-private-fill.svg");
  }

  .fr-icon-git-repository-private-line::before,
  .fr-icon-git-repository-private-line::after {
    background-image: url("../../icons/development/git-repository-private-line.svg");
  }

  .fr-icon-terminal-box-fill::before,
  .fr-icon-terminal-box-fill::after {
    background-image: url("../../icons/development/terminal-box-fill.svg");
  }

  .fr-icon-terminal-box-line::before,
  .fr-icon-terminal-box-line::after {
    background-image: url("../../icons/development/terminal-box-line.svg");
  }

  .fr-icon-terminal-line::before,
  .fr-icon-terminal-line::after {
    background-image: url("../../icons/development/terminal-line.svg");
  }

  .fr-icon-terminal-window-fill::before,
  .fr-icon-terminal-window-fill::after {
    background-image: url("../../icons/development/terminal-window-fill.svg");
  }

  .fr-icon-terminal-window-line::before,
  .fr-icon-terminal-window-line::after {
    background-image: url("../../icons/development/terminal-window-line.svg");
  }

  .fr-icon-bluetooth-fill::before,
  .fr-icon-bluetooth-fill::after {
    background-image: url("../../icons/device/bluetooth-fill.svg");
  }

  .fr-icon-bluetooth-line::before,
  .fr-icon-bluetooth-line::after {
    background-image: url("../../icons/device/bluetooth-line.svg");
  }

  .fr-icon-computer-fill::before,
  .fr-icon-computer-fill::after {
    background-image: url("../../icons/device/computer-fill.svg");
  }

  .fr-icon-computer-line::before,
  .fr-icon-computer-line::after {
    background-image: url("../../icons/device/computer-line.svg");
  }

  .fr-icon-dashboard-3-fill::before,
  .fr-icon-dashboard-3-fill::after {
    background-image: url("../../icons/device/dashboard-3-fill.svg");
  }

  .fr-icon-dashboard-3-line::before,
  .fr-icon-dashboard-3-line::after {
    background-image: url("../../icons/device/dashboard-3-line.svg");
  }

  .fr-icon-database-fill::before,
  .fr-icon-database-fill::after {
    background-image: url("../../icons/device/database-fill.svg");
  }

  .fr-icon-database-line::before,
  .fr-icon-database-line::after {
    background-image: url("../../icons/device/database-line.svg");
  }

  .fr-icon-device-fill::before,
  .fr-icon-device-fill::after {
    background-image: url("../../icons/device/device-fill.svg");
  }

  .fr-icon-device-line::before,
  .fr-icon-device-line::after {
    background-image: url("../../icons/device/device-line.svg");
  }

  .fr-icon-hard-drive-2-fill::before,
  .fr-icon-hard-drive-2-fill::after {
    background-image: url("../../icons/device/hard-drive-2-fill.svg");
  }

  .fr-icon-hard-drive-2-line::before,
  .fr-icon-hard-drive-2-line::after {
    background-image: url("../../icons/device/hard-drive-2-line.svg");
  }

  .fr-icon-mac-fill::before,
  .fr-icon-mac-fill::after {
    background-image: url("../../icons/device/mac-fill.svg");
  }

  .fr-icon-mac-line::before,
  .fr-icon-mac-line::after {
    background-image: url("../../icons/device/mac-line.svg");
  }

  .fr-icon-phone-fill::before,
  .fr-icon-phone-fill::after {
    background-image: url("../../icons/device/phone-fill.svg");
  }

  .fr-icon-phone-line::before,
  .fr-icon-phone-line::after {
    background-image: url("../../icons/device/phone-line.svg");
  }

  .fr-icon-qr-code-fill::before,
  .fr-icon-qr-code-fill::after {
    background-image: url("../../icons/device/qr-code-fill.svg");
  }

  .fr-icon-qr-code-line::before,
  .fr-icon-qr-code-line::after {
    background-image: url("../../icons/device/qr-code-line.svg");
  }

  .fr-icon-rss-fill::before,
  .fr-icon-rss-fill::after {
    background-image: url("../../icons/device/rss-fill.svg");
  }

  .fr-icon-rss-line::before,
  .fr-icon-rss-line::after {
    background-image: url("../../icons/device/rss-line.svg");
  }

  .fr-icon-save-3-fill::before,
  .fr-icon-save-3-fill::after {
    background-image: url("../../icons/device/save-3-fill.svg");
  }

  .fr-icon-save-3-line::before,
  .fr-icon-save-3-line::after {
    background-image: url("../../icons/device/save-3-line.svg");
  }

  .fr-icon-save-fill::before,
  .fr-icon-save-fill::after {
    background-image: url("../../icons/device/save-fill.svg");
  }

  .fr-icon-save-line::before,
  .fr-icon-save-line::after {
    background-image: url("../../icons/device/save-line.svg");
  }

  .fr-icon-server-fill::before,
  .fr-icon-server-fill::after {
    background-image: url("../../icons/device/server-fill.svg");
  }

  .fr-icon-server-line::before,
  .fr-icon-server-line::after {
    background-image: url("../../icons/device/server-line.svg");
  }

  .fr-icon-smartphone-fill::before,
  .fr-icon-smartphone-fill::after {
    background-image: url("../../icons/device/smartphone-fill.svg");
  }

  .fr-icon-smartphone-line::before,
  .fr-icon-smartphone-line::after {
    background-image: url("../../icons/device/smartphone-line.svg");
  }

  .fr-icon-tablet-fill::before,
  .fr-icon-tablet-fill::after {
    background-image: url("../../icons/device/tablet-fill.svg");
  }

  .fr-icon-tablet-line::before,
  .fr-icon-tablet-line::after {
    background-image: url("../../icons/device/tablet-line.svg");
  }

  .fr-icon-tv-fill::before,
  .fr-icon-tv-fill::after {
    background-image: url("../../icons/device/tv-fill.svg");
  }

  .fr-icon-tv-line::before,
  .fr-icon-tv-line::after {
    background-image: url("../../icons/device/tv-line.svg");
  }

  .fr-icon-wifi-fill::before,
  .fr-icon-wifi-fill::after {
    background-image: url("../../icons/device/wifi-fill.svg");
  }

  .fr-icon-wifi-line::before,
  .fr-icon-wifi-line::after {
    background-image: url("../../icons/device/wifi-line.svg");
  }

  .fr-fi-phone-fill::before {
    background-image: url("../../icons/device/phone-fill.svg");
  }

  .fr-fi-phone-line::before {
    background-image: url("../../icons/device/phone-line.svg");
  }

  .fr-fi-rss-fill::before {
    background-image: url("../../icons/device/rss-fill.svg");
  }

  .fr-fi-rss-line::before {
    background-image: url("../../icons/device/rss-line.svg");
  }

  .fr-fi-save-fill::before {
    background-image: url("../../icons/device/save-fill.svg");
  }

  .fr-fi-save-line::before {
    background-image: url("../../icons/device/save-line.svg");
  }

  .fr-icon-article-fill::before,
  .fr-icon-article-fill::after {
    background-image: url("../../icons/document/article-fill.svg");
  }

  .fr-icon-article-line::before,
  .fr-icon-article-line::after {
    background-image: url("../../icons/document/article-line.svg");
  }

  .fr-icon-book-2-fill::before,
  .fr-icon-book-2-fill::after {
    background-image: url("../../icons/document/book-2-fill.svg");
  }

  .fr-icon-book-2-line::before,
  .fr-icon-book-2-line::after {
    background-image: url("../../icons/document/book-2-line.svg");
  }

  .fr-icon-booklet-fill::before,
  .fr-icon-booklet-fill::after {
    background-image: url("../../icons/document/booklet-fill.svg");
  }

  .fr-icon-booklet-line::before,
  .fr-icon-booklet-line::after {
    background-image: url("../../icons/document/booklet-line.svg");
  }

  .fr-icon-clipboard-fill::before,
  .fr-icon-clipboard-fill::after {
    background-image: url("../../icons/document/clipboard-fill.svg");
  }

  .fr-icon-clipboard-line::before,
  .fr-icon-clipboard-line::after {
    background-image: url("../../icons/document/clipboard-line.svg");
  }

  .fr-icon-draft-fill::before,
  .fr-icon-draft-fill::after {
    background-image: url("../../icons/document/draft-fill.svg");
  }

  .fr-icon-draft-line::before,
  .fr-icon-draft-line::after {
    background-image: url("../../icons/document/draft-line.svg");
  }

  .fr-icon-file-add-fill::before,
  .fr-icon-file-add-fill::after {
    background-image: url("../../icons/document/file-add-fill.svg");
  }

  .fr-icon-file-add-line::before,
  .fr-icon-file-add-line::after {
    background-image: url("../../icons/document/file-add-line.svg");
  }

  .fr-icon-file-download-fill::before,
  .fr-icon-file-download-fill::after {
    background-image: url("../../icons/document/file-download-fill.svg");
  }

  .fr-icon-file-download-line::before,
  .fr-icon-file-download-line::after {
    background-image: url("../../icons/document/file-download-line.svg");
  }

  .fr-icon-file-fill::before,
  .fr-icon-file-fill::after {
    background-image: url("../../icons/document/file-fill.svg");
  }

  .fr-icon-file-line::before,
  .fr-icon-file-line::after {
    background-image: url("../../icons/document/file-line.svg");
  }

  .fr-icon-file-pdf-fill::before,
  .fr-icon-file-pdf-fill::after {
    background-image: url("../../icons/document/file-pdf-fill.svg");
  }

  .fr-icon-file-pdf-line::before,
  .fr-icon-file-pdf-line::after {
    background-image: url("../../icons/document/file-pdf-line.svg");
  }

  .fr-icon-file-text-fill::before,
  .fr-icon-file-text-fill::after {
    background-image: url("../../icons/document/file-text-fill.svg");
  }

  .fr-icon-file-text-line::before,
  .fr-icon-file-text-line::after {
    background-image: url("../../icons/document/file-text-line.svg");
  }

  .fr-icon-folder-2-fill::before,
  .fr-icon-folder-2-fill::after {
    background-image: url("../../icons/document/folder-2-fill.svg");
  }

  .fr-icon-folder-2-line::before,
  .fr-icon-folder-2-line::after {
    background-image: url("../../icons/document/folder-2-line.svg");
  }

  .fr-icon-newspaper-fill::before,
  .fr-icon-newspaper-fill::after {
    background-image: url("../../icons/document/newspaper-fill.svg");
  }

  .fr-icon-newspaper-line::before,
  .fr-icon-newspaper-line::after {
    background-image: url("../../icons/document/newspaper-line.svg");
  }

  .fr-icon-survey-fill::before,
  .fr-icon-survey-fill::after {
    background-image: url("../../icons/document/survey-fill.svg");
  }

  .fr-icon-survey-line::before,
  .fr-icon-survey-line::after {
    background-image: url("../../icons/document/survey-line.svg");
  }

  .fr-icon-todo-fill::before,
  .fr-icon-todo-fill::after {
    background-image: url("../../icons/document/todo-fill.svg");
  }

  .fr-icon-todo-line::before,
  .fr-icon-todo-line::after {
    background-image: url("../../icons/document/todo-line.svg");
  }

  .fr-fi-file-download-fill::before {
    background-image: url("../../icons/document/file-download-fill.svg");
  }

  .fr-fi-file-download-line::before {
    background-image: url("../../icons/document/file-download-line.svg");
  }

  .fr-fi-file-fill::before {
    background-image: url("../../icons/document/file-fill.svg");
  }

  .fr-fi-file-line::before {
    background-image: url("../../icons/document/file-line.svg");
  }

  .fr-fi-file-pdf-fill::before {
    background-image: url("../../icons/document/file-pdf-fill.svg");
  }

  .fr-fi-file-pdf-line::before {
    background-image: url("../../icons/document/file-pdf-line.svg");
  }

  .fr-icon-code-view::before,
  .fr-icon-code-view::after {
    background-image: url("../../icons/editor/code-view.svg");
  }

  .fr-icon-font-size::before,
  .fr-icon-font-size::after {
    background-image: url("../../icons/editor/font-size.svg");
  }

  .fr-icon-bold::before,
  .fr-icon-bold::after {
    background-image: url("../../icons/editor/fr--bold.svg");
  }

  .fr-icon-highlight::before,
  .fr-icon-highlight::after {
    background-image: url("../../icons/editor/fr--highlight.svg");
  }

  .fr-icon-quote-fill::before,
  .fr-icon-quote-fill::after {
    background-image: url("../../icons/editor/fr--quote-fill.svg");
  }

  .fr-icon-quote-line::before,
  .fr-icon-quote-line::after {
    background-image: url("../../icons/editor/fr--quote-line.svg");
  }

  .fr-icon-h-1::before,
  .fr-icon-h-1::after {
    background-image: url("../../icons/editor/h-1.svg");
  }

  .fr-icon-h-2::before,
  .fr-icon-h-2::after {
    background-image: url("../../icons/editor/h-2.svg");
  }

  .fr-icon-h-3::before,
  .fr-icon-h-3::after {
    background-image: url("../../icons/editor/h-3.svg");
  }

  .fr-icon-h-4::before,
  .fr-icon-h-4::after {
    background-image: url("../../icons/editor/h-4.svg");
  }

  .fr-icon-h-5::before,
  .fr-icon-h-5::after {
    background-image: url("../../icons/editor/h-5.svg");
  }

  .fr-icon-h-6::before,
  .fr-icon-h-6::after {
    background-image: url("../../icons/editor/h-6.svg");
  }

  .fr-icon-hashtag::before,
  .fr-icon-hashtag::after {
    background-image: url("../../icons/editor/hashtag.svg");
  }

  .fr-icon-italic::before,
  .fr-icon-italic::after {
    background-image: url("../../icons/editor/italic.svg");
  }

  .fr-icon-link-unlink::before,
  .fr-icon-link-unlink::after {
    background-image: url("../../icons/editor/link-unlink.svg");
  }

  .fr-icon-link::before,
  .fr-icon-link::after {
    background-image: url("../../icons/editor/link.svg");
  }

  .fr-icon-list-ordered::before,
  .fr-icon-list-ordered::after {
    background-image: url("../../icons/editor/list-ordered.svg");
  }

  .fr-icon-list-unordered::before,
  .fr-icon-list-unordered::after {
    background-image: url("../../icons/editor/list-unordered.svg");
  }

  .fr-icon-question-mark::before,
  .fr-icon-question-mark::after {
    background-image: url("../../icons/editor/question-mark.svg");
  }

  .fr-icon-separator::before,
  .fr-icon-separator::after {
    background-image: url("../../icons/editor/separator.svg");
  }

  .fr-icon-space::before,
  .fr-icon-space::after {
    background-image: url("../../icons/editor/space.svg");
  }

  .fr-icon-subscript::before,
  .fr-icon-subscript::after {
    background-image: url("../../icons/editor/subscript.svg");
  }

  .fr-icon-superscript::before,
  .fr-icon-superscript::after {
    background-image: url("../../icons/editor/superscript.svg");
  }

  .fr-icon-table-2::before,
  .fr-icon-table-2::after {
    background-image: url("../../icons/editor/table-2.svg");
  }

  .fr-icon-translate-2::before,
  .fr-icon-translate-2::after {
    background-image: url("../../icons/editor/translate-2.svg");
  }

  .fr-fi-font-size::before {
    background-image: url("../../icons/editor/font-size.svg");
  }

  .fr-fi-bold::before {
    background-image: url("../../icons/editor/fr--bold.svg");
  }

  .fr-fi-highlight::before {
    background-image: url("../../icons/editor/fr--highlight.svg");
  }

  .fr-fi-italic::before {
    background-image: url("../../icons/editor/italic.svg");
  }

  .fr-fi-link-unlink::before {
    background-image: url("../../icons/editor/link-unlink.svg");
  }

  .fr-fi-list-ordered::before {
    background-image: url("../../icons/editor/list-ordered.svg");
  }

  .fr-fi-list-unordered::before {
    background-image: url("../../icons/editor/list-unordered.svg");
  }

  .fr-icon-bank-card-fill::before,
  .fr-icon-bank-card-fill::after {
    background-image: url("../../icons/finance/bank-card-fill.svg");
  }

  .fr-icon-bank-card-line::before,
  .fr-icon-bank-card-line::after {
    background-image: url("../../icons/finance/bank-card-line.svg");
  }

  .fr-icon-coin-fill::before,
  .fr-icon-coin-fill::after {
    background-image: url("../../icons/finance/coin-fill.svg");
  }

  .fr-icon-gift-fill::before,
  .fr-icon-gift-fill::after {
    background-image: url("../../icons/finance/gift-fill.svg");
  }

  .fr-icon-gift-line::before,
  .fr-icon-gift-line::after {
    background-image: url("../../icons/finance/gift-line.svg");
  }

  .fr-icon-money-euro-box-fill::before,
  .fr-icon-money-euro-box-fill::after {
    background-image: url("../../icons/finance/money-euro-box-fill.svg");
  }

  .fr-icon-money-euro-box-line::before,
  .fr-icon-money-euro-box-line::after {
    background-image: url("../../icons/finance/money-euro-box-line.svg");
  }

  .fr-icon-money-euro-circle-fill::before,
  .fr-icon-money-euro-circle-fill::after {
    background-image: url("../../icons/finance/money-euro-circle-fill.svg");
  }

  .fr-icon-money-euro-circle-line::before,
  .fr-icon-money-euro-circle-line::after {
    background-image: url("../../icons/finance/money-euro-circle-line.svg");
  }

  .fr-icon-secure-payment-fill::before,
  .fr-icon-secure-payment-fill::after {
    background-image: url("../../icons/finance/secure-payment-fill.svg");
  }

  .fr-icon-secure-payment-line::before,
  .fr-icon-secure-payment-line::after {
    background-image: url("../../icons/finance/secure-payment-line.svg");
  }

  .fr-icon-shopping-bag-fill::before,
  .fr-icon-shopping-bag-fill::after {
    background-image: url("../../icons/finance/shopping-bag-fill.svg");
  }

  .fr-icon-shopping-bag-line::before,
  .fr-icon-shopping-bag-line::after {
    background-image: url("../../icons/finance/shopping-bag-line.svg");
  }

  .fr-icon-shopping-cart-2-fill::before,
  .fr-icon-shopping-cart-2-fill::after {
    background-image: url("../../icons/finance/shopping-cart-2-fill.svg");
  }

  .fr-icon-shopping-cart-2-line::before,
  .fr-icon-shopping-cart-2-line::after {
    background-image: url("../../icons/finance/shopping-cart-2-line.svg");
  }

  .fr-icon-trophy-fill::before,
  .fr-icon-trophy-fill::after {
    background-image: url("../../icons/finance/trophy-fill.svg");
  }

  .fr-icon-trophy-line::before,
  .fr-icon-trophy-line::after {
    background-image: url("../../icons/finance/trophy-line.svg");
  }

  .fr-icon-capsule-fill::before,
  .fr-icon-capsule-fill::after {
    background-image: url("../../icons/health/capsule-fill.svg");
  }

  .fr-icon-capsule-line::before,
  .fr-icon-capsule-line::after {
    background-image: url("../../icons/health/capsule-line.svg");
  }

  .fr-icon-dislike-fill::before,
  .fr-icon-dislike-fill::after {
    background-image: url("../../icons/health/dislike-fill.svg");
  }

  .fr-icon-dislike-line::before,
  .fr-icon-dislike-line::after {
    background-image: url("../../icons/health/dislike-line.svg");
  }

  .fr-icon-dossier-fill::before,
  .fr-icon-dossier-fill::after {
    background-image: url("../../icons/health/dossier-fill.svg");
  }

  .fr-icon-dossier-line::before,
  .fr-icon-dossier-line::after {
    background-image: url("../../icons/health/dossier-line.svg");
  }

  .fr-icon-first-aid-kit-fill::before,
  .fr-icon-first-aid-kit-fill::after {
    background-image: url("../../icons/health/first-aid-kit-fill.svg");
  }

  .fr-icon-first-aid-kit-line::before,
  .fr-icon-first-aid-kit-line::after {
    background-image: url("../../icons/health/first-aid-kit-line.svg");
  }

  .fr-icon-hand-sanitizer-fill::before,
  .fr-icon-hand-sanitizer-fill::after {
    background-image: url("../../icons/health/hand-sanitizer-fill.svg");
  }

  .fr-icon-hand-sanitizer-line::before,
  .fr-icon-hand-sanitizer-line::after {
    background-image: url("../../icons/health/hand-sanitizer-line.svg");
  }

  .fr-icon-health-book-fill::before,
  .fr-icon-health-book-fill::after {
    background-image: url("../../icons/health/health-book-fill.svg");
  }

  .fr-icon-health-book-line::before,
  .fr-icon-health-book-line::after {
    background-image: url("../../icons/health/health-book-line.svg");
  }

  .fr-icon-heart-fill::before,
  .fr-icon-heart-fill::after {
    background-image: url("../../icons/health/heart-fill.svg");
  }

  .fr-icon-heart-line::before,
  .fr-icon-heart-line::after {
    background-image: url("../../icons/health/heart-line.svg");
  }

  .fr-icon-heart-pulse-fill::before,
  .fr-icon-heart-pulse-fill::after {
    background-image: url("../../icons/health/heart-pulse-fill.svg");
  }

  .fr-icon-heart-pulse-line::before,
  .fr-icon-heart-pulse-line::after {
    background-image: url("../../icons/health/heart-pulse-line.svg");
  }

  .fr-icon-lungs-fill::before,
  .fr-icon-lungs-fill::after {
    background-image: url("../../icons/health/lungs-fill.svg");
  }

  .fr-icon-lungs-line::before,
  .fr-icon-lungs-line::after {
    background-image: url("../../icons/health/lungs-line.svg");
  }

  .fr-icon-medicine-bottle-fill::before,
  .fr-icon-medicine-bottle-fill::after {
    background-image: url("../../icons/health/medicine-bottle-fill.svg");
  }

  .fr-icon-medicine-bottle-line::before,
  .fr-icon-medicine-bottle-line::after {
    background-image: url("../../icons/health/medicine-bottle-line.svg");
  }

  .fr-icon-mental-health-fill::before,
  .fr-icon-mental-health-fill::after {
    background-image: url("../../icons/health/mental-health-fill.svg");
  }

  .fr-icon-mental-health-line::before,
  .fr-icon-mental-health-line::after {
    background-image: url("../../icons/health/mental-health-line.svg");
  }

  .fr-icon-microscope-fill::before,
  .fr-icon-microscope-fill::after {
    background-image: url("../../icons/health/microscope-fill.svg");
  }

  .fr-icon-microscope-line::before,
  .fr-icon-microscope-line::after {
    background-image: url("../../icons/health/microscope-line.svg");
  }

  .fr-icon-psychotherapy-fill::before,
  .fr-icon-psychotherapy-fill::after {
    background-image: url("../../icons/health/psychotherapy-fill.svg");
  }

  .fr-icon-psychotherapy-line::before,
  .fr-icon-psychotherapy-line::after {
    background-image: url("../../icons/health/psychotherapy-line.svg");
  }

  .fr-icon-pulse-line::before,
  .fr-icon-pulse-line::after {
    background-image: url("../../icons/health/pulse-line.svg");
  }

  .fr-icon-stethoscope-fill::before,
  .fr-icon-stethoscope-fill::after {
    background-image: url("../../icons/health/stethoscope-fill.svg");
  }

  .fr-icon-stethoscope-line::before,
  .fr-icon-stethoscope-line::after {
    background-image: url("../../icons/health/stethoscope-line.svg");
  }

  .fr-icon-surgical-mask-fill::before,
  .fr-icon-surgical-mask-fill::after {
    background-image: url("../../icons/health/surgical-mask-fill.svg");
  }

  .fr-icon-surgical-mask-line::before,
  .fr-icon-surgical-mask-line::after {
    background-image: url("../../icons/health/surgical-mask-line.svg");
  }

  .fr-icon-syringe-fill::before,
  .fr-icon-syringe-fill::after {
    background-image: url("../../icons/health/syringe-fill.svg");
  }

  .fr-icon-syringe-line::before,
  .fr-icon-syringe-line::after {
    background-image: url("../../icons/health/syringe-line.svg");
  }

  .fr-icon-test-tube-fill::before,
  .fr-icon-test-tube-fill::after {
    background-image: url("../../icons/health/test-tube-fill.svg");
  }

  .fr-icon-test-tube-line::before,
  .fr-icon-test-tube-line::after {
    background-image: url("../../icons/health/test-tube-line.svg");
  }

  .fr-icon-thermometer-fill::before,
  .fr-icon-thermometer-fill::after {
    background-image: url("../../icons/health/thermometer-fill.svg");
  }

  .fr-icon-thermometer-line::before,
  .fr-icon-thermometer-line::after {
    background-image: url("../../icons/health/thermometer-line.svg");
  }

  .fr-icon-virus-fill::before,
  .fr-icon-virus-fill::after {
    background-image: url("../../icons/health/virus-fill.svg");
  }

  .fr-icon-virus-line::before,
  .fr-icon-virus-line::after {
    background-image: url("../../icons/health/virus-line.svg");
  }

  .fr-icon-chrome-fill::before,
  .fr-icon-chrome-fill::after {
    background-image: url("../../icons/logo/chrome-fill.svg");
  }

  .fr-icon-chrome-line::before,
  .fr-icon-chrome-line::after {
    background-image: url("../../icons/logo/chrome-line.svg");
  }

  .fr-icon-edge-fill::before,
  .fr-icon-edge-fill::after {
    background-image: url("../../icons/logo/edge-fill.svg");
  }

  .fr-icon-edge-line::before,
  .fr-icon-edge-line::after {
    background-image: url("../../icons/logo/edge-line.svg");
  }

  .fr-icon-facebook-circle-fill::before,
  .fr-icon-facebook-circle-fill::after {
    background-image: url("../../icons/logo/facebook-circle-fill.svg");
  }

  .fr-icon-facebook-circle-line::before,
  .fr-icon-facebook-circle-line::after {
    background-image: url("../../icons/logo/facebook-circle-line.svg");
  }

  .fr-icon-firefox-fill::before,
  .fr-icon-firefox-fill::after {
    background-image: url("../../icons/logo/firefox-fill.svg");
  }

  .fr-icon-firefox-line::before,
  .fr-icon-firefox-line::after {
    background-image: url("../../icons/logo/firefox-line.svg");
  }

  .fr-icon-dailymotion-fill::before,
  .fr-icon-dailymotion-fill::after {
    background-image: url("../../icons/logo/fr--dailymotion-fill.svg");
  }

  .fr-icon-dailymotion-line::before,
  .fr-icon-dailymotion-line::after {
    background-image: url("../../icons/logo/fr--dailymotion-line.svg");
  }

  .fr-icon-tiktok-fill::before,
  .fr-icon-tiktok-fill::after {
    background-image: url("../../icons/logo/fr--tiktok-fill.svg");
  }

  .fr-icon-tiktok-line::before,
  .fr-icon-tiktok-line::after {
    background-image: url("../../icons/logo/fr--tiktok-line.svg");
  }

  .fr-icon-github-fill::before,
  .fr-icon-github-fill::after {
    background-image: url("../../icons/logo/github-fill.svg");
  }

  .fr-icon-github-line::before,
  .fr-icon-github-line::after {
    background-image: url("../../icons/logo/github-line.svg");
  }

  .fr-icon-google-fill::before,
  .fr-icon-google-fill::after {
    background-image: url("../../icons/logo/google-fill.svg");
  }

  .fr-icon-google-line::before,
  .fr-icon-google-line::after {
    background-image: url("../../icons/logo/google-line.svg");
  }

  .fr-icon-ie-fill::before,
  .fr-icon-ie-fill::after {
    background-image: url("../../icons/logo/ie-fill.svg");
  }

  .fr-icon-ie-line::before,
  .fr-icon-ie-line::after {
    background-image: url("../../icons/logo/ie-line.svg");
  }

  .fr-icon-instagram-fill::before,
  .fr-icon-instagram-fill::after {
    background-image: url("../../icons/logo/instagram-fill.svg");
  }

  .fr-icon-instagram-line::before,
  .fr-icon-instagram-line::after {
    background-image: url("../../icons/logo/instagram-line.svg");
  }

  .fr-icon-linkedin-box-fill::before,
  .fr-icon-linkedin-box-fill::after {
    background-image: url("../../icons/logo/linkedin-box-fill.svg");
  }

  .fr-icon-linkedin-box-line::before,
  .fr-icon-linkedin-box-line::after {
    background-image: url("../../icons/logo/linkedin-box-line.svg");
  }

  .fr-icon-mastodon-fill::before,
  .fr-icon-mastodon-fill::after {
    background-image: url("../../icons/logo/mastodon-fill.svg");
  }

  .fr-icon-mastodon-line::before,
  .fr-icon-mastodon-line::after {
    background-image: url("../../icons/logo/mastodon-line.svg");
  }

  .fr-icon-npmjs-fill::before,
  .fr-icon-npmjs-fill::after {
    background-image: url("../../icons/logo/npmjs-fill.svg");
  }

  .fr-icon-npmjs-line::before,
  .fr-icon-npmjs-line::after {
    background-image: url("../../icons/logo/npmjs-line.svg");
  }

  .fr-icon-remixicon-fill::before,
  .fr-icon-remixicon-fill::after {
    background-image: url("../../icons/logo/remixicon-fill.svg");
  }

  .fr-icon-remixicon-line::before,
  .fr-icon-remixicon-line::after {
    background-image: url("../../icons/logo/remixicon-line.svg");
  }

  .fr-icon-safari-fill::before,
  .fr-icon-safari-fill::after {
    background-image: url("../../icons/logo/safari-fill.svg");
  }

  .fr-icon-safari-line::before,
  .fr-icon-safari-line::after {
    background-image: url("../../icons/logo/safari-line.svg");
  }

  .fr-icon-slack-fill::before,
  .fr-icon-slack-fill::after {
    background-image: url("../../icons/logo/slack-fill.svg");
  }

  .fr-icon-slack-line::before,
  .fr-icon-slack-line::after {
    background-image: url("../../icons/logo/slack-line.svg");
  }

  .fr-icon-snapchat-fill::before,
  .fr-icon-snapchat-fill::after {
    background-image: url("../../icons/logo/snapchat-fill.svg");
  }

  .fr-icon-snapchat-line::before,
  .fr-icon-snapchat-line::after {
    background-image: url("../../icons/logo/snapchat-line.svg");
  }

  .fr-icon-telegram-fill::before,
  .fr-icon-telegram-fill::after {
    background-image: url("../../icons/logo/telegram-fill.svg");
  }

  .fr-icon-telegram-line::before,
  .fr-icon-telegram-line::after {
    background-image: url("../../icons/logo/telegram-line.svg");
  }

  .fr-icon-threads-fill::before,
  .fr-icon-threads-fill::after {
    background-image: url("../../icons/logo/threads-fill.svg");
  }

  .fr-icon-threads-line::before,
  .fr-icon-threads-line::after {
    background-image: url("../../icons/logo/threads-line.svg");
  }

  .fr-icon-twitch-fill::before,
  .fr-icon-twitch-fill::after {
    background-image: url("../../icons/logo/twitch-fill.svg");
  }

  .fr-icon-twitch-line::before,
  .fr-icon-twitch-line::after {
    background-image: url("../../icons/logo/twitch-line.svg");
  }

  .fr-icon-twitter-fill::before,
  .fr-icon-twitter-fill::after {
    background-image: url("../../icons/logo/twitter-fill.svg");
  }

  .fr-icon-twitter-line::before,
  .fr-icon-twitter-line::after {
    background-image: url("../../icons/logo/twitter-line.svg");
  }

  .fr-icon-twitter-x-fill::before,
  .fr-icon-twitter-x-fill::after {
    background-image: url("../../icons/logo/twitter-x-fill.svg");
  }

  .fr-icon-twitter-x-line::before,
  .fr-icon-twitter-x-line::after {
    background-image: url("../../icons/logo/twitter-x-line.svg");
  }

  .fr-icon-vimeo-fill::before,
  .fr-icon-vimeo-fill::after {
    background-image: url("../../icons/logo/vimeo-fill.svg");
  }

  .fr-icon-vimeo-line::before,
  .fr-icon-vimeo-line::after {
    background-image: url("../../icons/logo/vimeo-line.svg");
  }

  .fr-icon-vuejs-fill::before,
  .fr-icon-vuejs-fill::after {
    background-image: url("../../icons/logo/vuejs-fill.svg");
  }

  .fr-icon-vuejs-line::before,
  .fr-icon-vuejs-line::after {
    background-image: url("../../icons/logo/vuejs-line.svg");
  }

  .fr-icon-whatsapp-fill::before,
  .fr-icon-whatsapp-fill::after {
    background-image: url("../../icons/logo/whatsapp-fill.svg");
  }

  .fr-icon-whatsapp-line::before,
  .fr-icon-whatsapp-line::after {
    background-image: url("../../icons/logo/whatsapp-line.svg");
  }

  .fr-icon-youtube-fill::before,
  .fr-icon-youtube-fill::after {
    background-image: url("../../icons/logo/youtube-fill.svg");
  }

  .fr-icon-youtube-line::before,
  .fr-icon-youtube-line::after {
    background-image: url("../../icons/logo/youtube-line.svg");
  }

  .fr-fi-facebook-circle-fill::before {
    background-image: url("../../icons/logo/facebook-circle-fill.svg");
  }

  .fr-fi-facebook-circle-line::before {
    background-image: url("../../icons/logo/facebook-circle-line.svg");
  }

  .fr-fi-dailymotion-fill::before {
    background-image: url("../../icons/logo/fr--dailymotion-fill.svg");
  }

  .fr-fi-dailymotion-line::before {
    background-image: url("../../icons/logo/fr--dailymotion-line.svg");
  }

  .fr-fi-github-fill::before {
    background-image: url("../../icons/logo/github-fill.svg");
  }

  .fr-fi-github-line::before {
    background-image: url("../../icons/logo/github-line.svg");
  }

  .fr-fi-instagram-fill::before {
    background-image: url("../../icons/logo/instagram-fill.svg");
  }

  .fr-fi-instagram-line::before {
    background-image: url("../../icons/logo/instagram-line.svg");
  }

  .fr-fi-linkedin-box-fill::before {
    background-image: url("../../icons/logo/linkedin-box-fill.svg");
  }

  .fr-fi-linkedin-box-line::before {
    background-image: url("../../icons/logo/linkedin-box-line.svg");
  }

  .fr-fi-npmjs-fill::before {
    background-image: url("../../icons/logo/npmjs-fill.svg");
  }

  .fr-fi-npmjs-line::before {
    background-image: url("../../icons/logo/npmjs-line.svg");
  }

  .fr-fi-remixicon-fill::before {
    background-image: url("../../icons/logo/remixicon-fill.svg");
  }

  .fr-fi-remixicon-line::before {
    background-image: url("../../icons/logo/remixicon-line.svg");
  }

  .fr-fi-slack-fill::before {
    background-image: url("../../icons/logo/slack-fill.svg");
  }

  .fr-fi-slack-line::before {
    background-image: url("../../icons/logo/slack-line.svg");
  }

  .fr-fi-snapchat-fill::before {
    background-image: url("../../icons/logo/snapchat-fill.svg");
  }

  .fr-fi-snapchat-line::before {
    background-image: url("../../icons/logo/snapchat-line.svg");
  }

  .fr-fi-telegram-fill::before {
    background-image: url("../../icons/logo/telegram-fill.svg");
  }

  .fr-fi-telegram-line::before {
    background-image: url("../../icons/logo/telegram-line.svg");
  }

  .fr-fi-twitch-fill::before {
    background-image: url("../../icons/logo/twitch-fill.svg");
  }

  .fr-fi-twitch-line::before {
    background-image: url("../../icons/logo/twitch-line.svg");
  }

  .fr-fi-twitter-fill::before {
    background-image: url("../../icons/logo/twitter-fill.svg");
  }

  .fr-fi-twitter-line::before {
    background-image: url("../../icons/logo/twitter-line.svg");
  }

  .fr-fi-vimeo-fill::before {
    background-image: url("../../icons/logo/vimeo-fill.svg");
  }

  .fr-fi-vimeo-line::before {
    background-image: url("../../icons/logo/vimeo-line.svg");
  }

  .fr-fi-youtube-fill::before {
    background-image: url("../../icons/logo/youtube-fill.svg");
  }

  .fr-fi-youtube-line::before {
    background-image: url("../../icons/logo/youtube-line.svg");
  }

  .fr-icon-anchor-fill::before,
  .fr-icon-anchor-fill::after {
    background-image: url("../../icons/map/anchor-fill.svg");
  }

  .fr-icon-anchor-line::before,
  .fr-icon-anchor-line::after {
    background-image: url("../../icons/map/anchor-line.svg");
  }

  .fr-icon-bike-fill::before,
  .fr-icon-bike-fill::after {
    background-image: url("../../icons/map/bike-fill.svg");
  }

  .fr-icon-bike-line::before,
  .fr-icon-bike-line::after {
    background-image: url("../../icons/map/bike-line.svg");
  }

  .fr-icon-bus-fill::before,
  .fr-icon-bus-fill::after {
    background-image: url("../../icons/map/bus-fill.svg");
  }

  .fr-icon-bus-line::before,
  .fr-icon-bus-line::after {
    background-image: url("../../icons/map/bus-line.svg");
  }

  .fr-icon-car-fill::before,
  .fr-icon-car-fill::after {
    background-image: url("../../icons/map/car-fill.svg");
  }

  .fr-icon-car-line::before,
  .fr-icon-car-line::after {
    background-image: url("../../icons/map/car-line.svg");
  }

  .fr-icon-caravan-fill::before,
  .fr-icon-caravan-fill::after {
    background-image: url("../../icons/map/caravan-fill.svg");
  }

  .fr-icon-caravan-line::before,
  .fr-icon-caravan-line::after {
    background-image: url("../../icons/map/caravan-line.svg");
  }

  .fr-icon-charging-pile-2-fill::before,
  .fr-icon-charging-pile-2-fill::after {
    background-image: url("../../icons/map/charging-pile-2-fill.svg");
  }

  .fr-icon-charging-pile-2-line::before,
  .fr-icon-charging-pile-2-line::after {
    background-image: url("../../icons/map/charging-pile-2-line.svg");
  }

  .fr-icon-compass-3-fill::before,
  .fr-icon-compass-3-fill::after {
    background-image: url("../../icons/map/compass-3-fill.svg");
  }

  .fr-icon-compass-3-line::before,
  .fr-icon-compass-3-line::after {
    background-image: url("../../icons/map/compass-3-line.svg");
  }

  .fr-icon-cup-fill::before,
  .fr-icon-cup-fill::after {
    background-image: url("../../icons/map/cup-fill.svg");
  }

  .fr-icon-cup-line::before,
  .fr-icon-cup-line::after {
    background-image: url("../../icons/map/cup-line.svg");
  }

  .fr-icon-earth-fill::before,
  .fr-icon-earth-fill::after {
    background-image: url("../../icons/map/earth-fill.svg");
  }

  .fr-icon-earth-line::before,
  .fr-icon-earth-line::after {
    background-image: url("../../icons/map/earth-line.svg");
  }

  .fr-icon-france-fill::before,
  .fr-icon-france-fill::after {
    background-image: url("../../icons/map/france-fill.svg");
  }

  .fr-icon-france-line::before,
  .fr-icon-france-line::after {
    background-image: url("../../icons/map/france-line.svg");
  }

  .fr-icon-gas-station-fill::before,
  .fr-icon-gas-station-fill::after {
    background-image: url("../../icons/map/gas-station-fill.svg");
  }

  .fr-icon-gas-station-line::before,
  .fr-icon-gas-station-line::after {
    background-image: url("../../icons/map/gas-station-line.svg");
  }

  .fr-icon-goblet-fill::before,
  .fr-icon-goblet-fill::after {
    background-image: url("../../icons/map/goblet-fill.svg");
  }

  .fr-icon-goblet-line::before,
  .fr-icon-goblet-line::after {
    background-image: url("../../icons/map/goblet-line.svg");
  }

  .fr-icon-map-pin-2-fill::before,
  .fr-icon-map-pin-2-fill::after {
    background-image: url("../../icons/map/map-pin-2-fill.svg");
  }

  .fr-icon-map-pin-2-line::before,
  .fr-icon-map-pin-2-line::after {
    background-image: url("../../icons/map/map-pin-2-line.svg");
  }

  .fr-icon-map-pin-user-fill::before,
  .fr-icon-map-pin-user-fill::after {
    background-image: url("../../icons/map/map-pin-user-fill.svg");
  }

  .fr-icon-map-pin-user-line::before,
  .fr-icon-map-pin-user-line::after {
    background-image: url("../../icons/map/map-pin-user-line.svg");
  }

  .fr-icon-motorbike-fill::before,
  .fr-icon-motorbike-fill::after {
    background-image: url("../../icons/map/motorbike-fill.svg");
  }

  .fr-icon-motorbike-line::before,
  .fr-icon-motorbike-line::after {
    background-image: url("../../icons/map/motorbike-line.svg");
  }

  .fr-icon-passport-fill::before,
  .fr-icon-passport-fill::after {
    background-image: url("../../icons/map/passport-fill.svg");
  }

  .fr-icon-passport-line::before,
  .fr-icon-passport-line::after {
    background-image: url("../../icons/map/passport-line.svg");
  }

  .fr-icon-restaurant-fill::before,
  .fr-icon-restaurant-fill::after {
    background-image: url("../../icons/map/restaurant-fill.svg");
  }

  .fr-icon-restaurant-line::before,
  .fr-icon-restaurant-line::after {
    background-image: url("../../icons/map/restaurant-line.svg");
  }

  .fr-icon-road-map-fill::before,
  .fr-icon-road-map-fill::after {
    background-image: url("../../icons/map/road-map-fill.svg");
  }

  .fr-icon-road-map-line::before,
  .fr-icon-road-map-line::after {
    background-image: url("../../icons/map/road-map-line.svg");
  }

  .fr-icon-sailboat-fill::before,
  .fr-icon-sailboat-fill::after {
    background-image: url("../../icons/map/sailboat-fill.svg");
  }

  .fr-icon-sailboat-line::before,
  .fr-icon-sailboat-line::after {
    background-image: url("../../icons/map/sailboat-line.svg");
  }

  .fr-icon-ship-2-fill::before,
  .fr-icon-ship-2-fill::after {
    background-image: url("../../icons/map/ship-2-fill.svg");
  }

  .fr-icon-ship-2-line::before,
  .fr-icon-ship-2-line::after {
    background-image: url("../../icons/map/ship-2-line.svg");
  }

  .fr-icon-signal-tower-fill::before,
  .fr-icon-signal-tower-fill::after {
    background-image: url("../../icons/map/signal-tower-fill.svg");
  }

  .fr-icon-signal-tower-line::before,
  .fr-icon-signal-tower-line::after {
    background-image: url("../../icons/map/signal-tower-line.svg");
  }

  .fr-icon-suitcase-2-fill::before,
  .fr-icon-suitcase-2-fill::after {
    background-image: url("../../icons/map/suitcase-2-fill.svg");
  }

  .fr-icon-suitcase-2-line::before,
  .fr-icon-suitcase-2-line::after {
    background-image: url("../../icons/map/suitcase-2-line.svg");
  }

  .fr-icon-taxi-fill::before,
  .fr-icon-taxi-fill::after {
    background-image: url("../../icons/map/taxi-fill.svg");
  }

  .fr-icon-taxi-line::before,
  .fr-icon-taxi-line::after {
    background-image: url("../../icons/map/taxi-line.svg");
  }

  .fr-icon-train-fill::before,
  .fr-icon-train-fill::after {
    background-image: url("../../icons/map/train-fill.svg");
  }

  .fr-icon-train-line::before,
  .fr-icon-train-line::after {
    background-image: url("../../icons/map/train-line.svg");
  }

  .fr-fi-map-pin-2-fill::before {
    background-image: url("../../icons/map/map-pin-2-fill.svg");
  }

  .fr-fi-map-pin-2-line::before {
    background-image: url("../../icons/map/map-pin-2-line.svg");
  }

  .fr-fi-road-map-fill::before {
    background-image: url("../../icons/map/road-map-fill.svg");
  }

  .fr-fi-road-map-line::before {
    background-image: url("../../icons/map/road-map-line.svg");
  }

  .fr-icon-align-left::before,
  .fr-icon-align-left::after {
    background-image: url("../../icons/media/align-left.svg");
  }

  .fr-icon-camera-fill::before,
  .fr-icon-camera-fill::after {
    background-image: url("../../icons/media/camera-fill.svg");
  }

  .fr-icon-camera-line::before,
  .fr-icon-camera-line::after {
    background-image: url("../../icons/media/camera-line.svg");
  }

  .fr-icon-clapperboard-fill::before,
  .fr-icon-clapperboard-fill::after {
    background-image: url("../../icons/media/clapperboard-fill.svg");
  }

  .fr-icon-clapperboard-line::before,
  .fr-icon-clapperboard-line::after {
    background-image: url("../../icons/media/clapperboard-line.svg");
  }

  .fr-icon-equalizer-fill::before,
  .fr-icon-equalizer-fill::after {
    background-image: url("../../icons/media/equalizer-fill.svg");
  }

  .fr-icon-equalizer-line::before,
  .fr-icon-equalizer-line::after {
    background-image: url("../../icons/media/equalizer-line.svg");
  }

  .fr-icon-film-fill::before,
  .fr-icon-film-fill::after {
    background-image: url("../../icons/media/film-fill.svg");
  }

  .fr-icon-film-line::before,
  .fr-icon-film-line::after {
    background-image: url("../../icons/media/film-line.svg");
  }

  .fr-icon-fullscreen-line::before,
  .fr-icon-fullscreen-line::after {
    background-image: url("../../icons/media/fullscreen-line.svg");
  }

  .fr-icon-gallery-fill::before,
  .fr-icon-gallery-fill::after {
    background-image: url("../../icons/media/gallery-fill.svg");
  }

  .fr-icon-gallery-line::before,
  .fr-icon-gallery-line::after {
    background-image: url("../../icons/media/gallery-line.svg");
  }

  .fr-icon-headphone-fill::before,
  .fr-icon-headphone-fill::after {
    background-image: url("../../icons/media/headphone-fill.svg");
  }

  .fr-icon-headphone-line::before,
  .fr-icon-headphone-line::after {
    background-image: url("../../icons/media/headphone-line.svg");
  }

  .fr-icon-image-add-fill::before,
  .fr-icon-image-add-fill::after {
    background-image: url("../../icons/media/image-add-fill.svg");
  }

  .fr-icon-image-add-line::before,
  .fr-icon-image-add-line::after {
    background-image: url("../../icons/media/image-add-line.svg");
  }

  .fr-icon-image-edit-fill::before,
  .fr-icon-image-edit-fill::after {
    background-image: url("../../icons/media/image-edit-fill.svg");
  }

  .fr-icon-image-edit-line::before,
  .fr-icon-image-edit-line::after {
    background-image: url("../../icons/media/image-edit-line.svg");
  }

  .fr-icon-image-fill::before,
  .fr-icon-image-fill::after {
    background-image: url("../../icons/media/image-fill.svg");
  }

  .fr-icon-image-line::before,
  .fr-icon-image-line::after {
    background-image: url("../../icons/media/image-line.svg");
  }

  .fr-icon-live-fill::before,
  .fr-icon-live-fill::after {
    background-image: url("../../icons/media/live-fill.svg");
  }

  .fr-icon-live-line::before,
  .fr-icon-live-line::after {
    background-image: url("../../icons/media/live-line.svg");
  }

  .fr-icon-mic-fill::before,
  .fr-icon-mic-fill::after {
    background-image: url("../../icons/media/mic-fill.svg");
  }

  .fr-icon-mic-line::before,
  .fr-icon-mic-line::after {
    background-image: url("../../icons/media/mic-line.svg");
  }

  .fr-icon-music-2-fill::before,
  .fr-icon-music-2-fill::after {
    background-image: url("../../icons/media/music-2-fill.svg");
  }

  .fr-icon-music-2-line::before,
  .fr-icon-music-2-line::after {
    background-image: url("../../icons/media/music-2-line.svg");
  }

  .fr-icon-notification-3-fill::before,
  .fr-icon-notification-3-fill::after {
    background-image: url("../../icons/media/notification-3-fill.svg");
  }

  .fr-icon-notification-3-line::before,
  .fr-icon-notification-3-line::after {
    background-image: url("../../icons/media/notification-3-line.svg");
  }

  .fr-icon-pause-circle-fill::before,
  .fr-icon-pause-circle-fill::after {
    background-image: url("../../icons/media/pause-circle-fill.svg");
  }

  .fr-icon-pause-circle-line::before,
  .fr-icon-pause-circle-line::after {
    background-image: url("../../icons/media/pause-circle-line.svg");
  }

  .fr-icon-play-circle-fill::before,
  .fr-icon-play-circle-fill::after {
    background-image: url("../../icons/media/play-circle-fill.svg");
  }

  .fr-icon-play-circle-line::before,
  .fr-icon-play-circle-line::after {
    background-image: url("../../icons/media/play-circle-line.svg");
  }

  .fr-icon-stop-circle-fill::before,
  .fr-icon-stop-circle-fill::after {
    background-image: url("../../icons/media/stop-circle-fill.svg");
  }

  .fr-icon-stop-circle-line::before,
  .fr-icon-stop-circle-line::after {
    background-image: url("../../icons/media/stop-circle-line.svg");
  }

  .fr-icon-volume-down-fill::before,
  .fr-icon-volume-down-fill::after {
    background-image: url("../../icons/media/volume-down-fill.svg");
  }

  .fr-icon-volume-down-line::before,
  .fr-icon-volume-down-line::after {
    background-image: url("../../icons/media/volume-down-line.svg");
  }

  .fr-icon-volume-mute-fill::before,
  .fr-icon-volume-mute-fill::after {
    background-image: url("../../icons/media/volume-mute-fill.svg");
  }

  .fr-icon-volume-mute-line::before,
  .fr-icon-volume-mute-line::after {
    background-image: url("../../icons/media/volume-mute-line.svg");
  }

  .fr-icon-volume-up-fill::before,
  .fr-icon-volume-up-fill::after {
    background-image: url("../../icons/media/volume-up-fill.svg");
  }

  .fr-icon-volume-up-line::before,
  .fr-icon-volume-up-line::after {
    background-image: url("../../icons/media/volume-up-line.svg");
  }

  .fr-fi-equalizer-fill::before {
    background-image: url("../../icons/media/equalizer-fill.svg");
  }

  .fr-fi-equalizer-line::before {
    background-image: url("../../icons/media/equalizer-line.svg");
  }

  .fr-fi-image-fill::before {
    background-image: url("../../icons/media/image-fill.svg");
  }

  .fr-fi-image-line::before {
    background-image: url("../../icons/media/image-line.svg");
  }

  .fr-fi-pause-circle-fill::before {
    background-image: url("../../icons/media/pause-circle-fill.svg");
  }

  .fr-fi-pause-circle-line::before {
    background-image: url("../../icons/media/pause-circle-line.svg");
  }

  .fr-fi-volume-down-fill::before {
    background-image: url("../../icons/media/volume-down-fill.svg");
  }

  .fr-fi-volume-down-line::before {
    background-image: url("../../icons/media/volume-down-line.svg");
  }

  .fr-fi-volume-mute-fill::before {
    background-image: url("../../icons/media/volume-mute-fill.svg");
  }

  .fr-fi-volume-mute-line::before {
    background-image: url("../../icons/media/volume-mute-line.svg");
  }

  .fr-fi-volume-up-fill::before {
    background-image: url("../../icons/media/volume-up-fill.svg");
  }

  .fr-fi-volume-up-line::before {
    background-image: url("../../icons/media/volume-up-line.svg");
  }

  .fr-fi-play-line::before,
  .fr-fi-play-line::after {
    background-image: url("../../icons/media/play-circle-line.svg");
  }

  .fr-fi-play-fill::before,
  .fr-fi-play-fill::after {
    background-image: url("../../icons/media/play-circle-fill.svg");
  }

  .fr-icon-play-line::before,
  .fr-icon-play-line::after {
    background-image: url("../../icons/media/play-circle-line.svg");
  }

  .fr-icon-play-fill::before,
  .fr-icon-play-fill::after {
    background-image: url("../../icons/media/play-circle-fill.svg");
  }

  .fr-icon-accessibility-fill::before,
  .fr-icon-accessibility-fill::after {
    background-image: url("../../icons/others/fr--accessibility-fill.svg");
  }

  .fr-icon-accessibility-line::before,
  .fr-icon-accessibility-line::after {
    background-image: url("../../icons/others/fr--accessibility-line.svg");
  }

  .fr-icon-ear-off-fill::before,
  .fr-icon-ear-off-fill::after {
    background-image: url("../../icons/others/fr--ear-off-fill.svg");
  }

  .fr-icon-ear-off-line::before,
  .fr-icon-ear-off-line::after {
    background-image: url("../../icons/others/fr--ear-off-line.svg");
  }

  .fr-icon-mental-disabilities-fill::before,
  .fr-icon-mental-disabilities-fill::after {
    background-image: url("../../icons/others/fr--mental-disabilities-fill.svg");
  }

  .fr-icon-mental-disabilities-line::before,
  .fr-icon-mental-disabilities-line::after {
    background-image: url("../../icons/others/fr--mental-disabilities-line.svg");
  }

  .fr-icon-sign-language-fill::before,
  .fr-icon-sign-language-fill::after {
    background-image: url("../../icons/others/fr--sign-language-fill.svg");
  }

  .fr-icon-sign-language-line::before,
  .fr-icon-sign-language-line::after {
    background-image: url("../../icons/others/fr--sign-language-line.svg");
  }

  .fr-icon-leaf-fill::before,
  .fr-icon-leaf-fill::after {
    background-image: url("../../icons/others/leaf-fill.svg");
  }

  .fr-icon-leaf-line::before,
  .fr-icon-leaf-line::after {
    background-image: url("../../icons/others/leaf-line.svg");
  }

  .fr-icon-lightbulb-fill::before,
  .fr-icon-lightbulb-fill::after {
    background-image: url("../../icons/others/lightbulb-fill.svg");
  }

  .fr-icon-lightbulb-line::before,
  .fr-icon-lightbulb-line::after {
    background-image: url("../../icons/others/lightbulb-line.svg");
  }

  .fr-icon-plant-fill::before,
  .fr-icon-plant-fill::after {
    background-image: url("../../icons/others/plant-fill.svg");
  }

  .fr-icon-plant-line::before,
  .fr-icon-plant-line::after {
    background-image: url("../../icons/others/plant-line.svg");
  }

  .fr-icon-recycle-fill::before,
  .fr-icon-recycle-fill::after {
    background-image: url("../../icons/others/recycle-fill.svg");
  }

  .fr-icon-recycle-line::before,
  .fr-icon-recycle-line::after {
    background-image: url("../../icons/others/recycle-line.svg");
  }

  .fr-icon-scales-3-fill::before,
  .fr-icon-scales-3-fill::after {
    background-image: url("../../icons/others/scales-3-fill.svg");
  }

  .fr-icon-scales-3-line::before,
  .fr-icon-scales-3-line::after {
    background-image: url("../../icons/others/scales-3-line.svg");
  }

  .fr-icon-seedling-fill::before,
  .fr-icon-seedling-fill::after {
    background-image: url("../../icons/others/seedling-fill.svg");
  }

  .fr-icon-seedling-line::before,
  .fr-icon-seedling-line::after {
    background-image: url("../../icons/others/seedling-line.svg");
  }

  .fr-icon-umbrella-fill::before,
  .fr-icon-umbrella-fill::after {
    background-image: url("../../icons/others/umbrella-fill.svg");
  }

  .fr-icon-umbrella-line::before,
  .fr-icon-umbrella-line::after {
    background-image: url("../../icons/others/umbrella-line.svg");
  }

  .fr-icon-wheelchair-fill::before,
  .fr-icon-wheelchair-fill::after {
    background-image: url("../../icons/others/wheelchair-fill.svg");
  }

  .fr-icon-wheelchair-line::before,
  .fr-icon-wheelchair-line::after {
    background-image: url("../../icons/others/wheelchair-line.svg");
  }

  .fr-icon-add-circle-fill::before,
  .fr-icon-add-circle-fill::after {
    background-image: url("../../icons/system/add-circle-fill.svg");
  }

  .fr-icon-add-circle-line::before,
  .fr-icon-add-circle-line::after {
    background-image: url("../../icons/system/add-circle-line.svg");
  }

  .fr-icon-add-line::before,
  .fr-icon-add-line::after {
    background-image: url("../../icons/system/add-line.svg");
  }

  .fr-icon-alarm-warning-fill::before,
  .fr-icon-alarm-warning-fill::after {
    background-image: url("../../icons/system/alarm-warning-fill.svg");
  }

  .fr-icon-alarm-warning-line::before,
  .fr-icon-alarm-warning-line::after {
    background-image: url("../../icons/system/alarm-warning-line.svg");
  }

  .fr-icon-alert-fill::before,
  .fr-icon-alert-fill::after {
    background-image: url("../../icons/system/alert-fill.svg");
  }

  .fr-icon-alert-line::before,
  .fr-icon-alert-line::after {
    background-image: url("../../icons/system/alert-line.svg");
  }

  .fr-icon-arrow-down-fill::before,
  .fr-icon-arrow-down-fill::after {
    background-image: url("../../icons/system/arrow-down-fill.svg");
  }

  .fr-icon-arrow-down-line::before,
  .fr-icon-arrow-down-line::after {
    background-image: url("../../icons/system/arrow-down-line.svg");
  }

  .fr-icon-arrow-down-s-fill::before,
  .fr-icon-arrow-down-s-fill::after {
    background-image: url("../../icons/system/arrow-down-s-fill.svg");
  }

  .fr-icon-arrow-down-s-line::before,
  .fr-icon-arrow-down-s-line::after {
    background-image: url("../../icons/system/arrow-down-s-line.svg");
  }

  .fr-icon-arrow-go-back-fill::before,
  .fr-icon-arrow-go-back-fill::after {
    background-image: url("../../icons/system/arrow-go-back-fill.svg");
  }

  .fr-icon-arrow-go-back-line::before,
  .fr-icon-arrow-go-back-line::after {
    background-image: url("../../icons/system/arrow-go-back-line.svg");
  }

  .fr-icon-arrow-go-forward-fill::before,
  .fr-icon-arrow-go-forward-fill::after {
    background-image: url("../../icons/system/arrow-go-forward-fill.svg");
  }

  .fr-icon-arrow-go-forward-line::before,
  .fr-icon-arrow-go-forward-line::after {
    background-image: url("../../icons/system/arrow-go-forward-line.svg");
  }

  .fr-icon-arrow-left-fill::before,
  .fr-icon-arrow-left-fill::after {
    background-image: url("../../icons/system/arrow-left-fill.svg");
  }

  .fr-icon-arrow-left-line::before,
  .fr-icon-arrow-left-line::after {
    background-image: url("../../icons/system/arrow-left-line.svg");
  }

  .fr-icon-arrow-left-s-fill::before,
  .fr-icon-arrow-left-s-fill::after {
    background-image: url("../../icons/system/arrow-left-s-fill.svg");
  }

  .fr-icon-arrow-left-s-line::before,
  .fr-icon-arrow-left-s-line::after {
    background-image: url("../../icons/system/arrow-left-s-line.svg");
  }

  .fr-icon-arrow-right-fill::before,
  .fr-icon-arrow-right-fill::after {
    background-image: url("../../icons/system/arrow-right-fill.svg");
  }

  .fr-icon-arrow-right-line::before,
  .fr-icon-arrow-right-line::after {
    background-image: url("../../icons/system/arrow-right-line.svg");
  }

  .fr-icon-arrow-right-s-fill::before,
  .fr-icon-arrow-right-s-fill::after {
    background-image: url("../../icons/system/arrow-right-s-fill.svg");
  }

  .fr-icon-arrow-right-s-line::before,
  .fr-icon-arrow-right-s-line::after {
    background-image: url("../../icons/system/arrow-right-s-line.svg");
  }

  .fr-icon-arrow-right-up-line::before,
  .fr-icon-arrow-right-up-line::after {
    background-image: url("../../icons/system/arrow-right-up-line.svg");
  }

  .fr-icon-arrow-up-down-line::before,
  .fr-icon-arrow-up-down-line::after {
    background-image: url("../../icons/system/arrow-up-down-line.svg");
  }

  .fr-icon-arrow-up-fill::before,
  .fr-icon-arrow-up-fill::after {
    background-image: url("../../icons/system/arrow-up-fill.svg");
  }

  .fr-icon-arrow-up-line::before,
  .fr-icon-arrow-up-line::after {
    background-image: url("../../icons/system/arrow-up-line.svg");
  }

  .fr-icon-arrow-up-s-fill::before,
  .fr-icon-arrow-up-s-fill::after {
    background-image: url("../../icons/system/arrow-up-s-fill.svg");
  }

  .fr-icon-arrow-up-s-line::before,
  .fr-icon-arrow-up-s-line::after {
    background-image: url("../../icons/system/arrow-up-s-line.svg");
  }

  .fr-icon-check-line::before,
  .fr-icon-check-line::after {
    background-image: url("../../icons/system/check-line.svg");
  }

  .fr-icon-checkbox-circle-fill::before,
  .fr-icon-checkbox-circle-fill::after {
    background-image: url("../../icons/system/checkbox-circle-fill.svg");
  }

  .fr-icon-checkbox-circle-line::before,
  .fr-icon-checkbox-circle-line::after {
    background-image: url("../../icons/system/checkbox-circle-line.svg");
  }

  .fr-icon-checkbox-fill::before,
  .fr-icon-checkbox-fill::after {
    background-image: url("../../icons/system/checkbox-fill.svg");
  }

  .fr-icon-checkbox-line::before,
  .fr-icon-checkbox-line::after {
    background-image: url("../../icons/system/checkbox-line.svg");
  }

  .fr-icon-close-circle-fill::before,
  .fr-icon-close-circle-fill::after {
    background-image: url("../../icons/system/close-circle-fill.svg");
  }

  .fr-icon-close-circle-line::before,
  .fr-icon-close-circle-line::after {
    background-image: url("../../icons/system/close-circle-line.svg");
  }

  .fr-icon-close-line::before,
  .fr-icon-close-line::after {
    background-image: url("../../icons/system/close-line.svg");
  }

  .fr-icon-delete-bin-fill::before,
  .fr-icon-delete-bin-fill::after {
    background-image: url("../../icons/system/delete-bin-fill.svg");
  }

  .fr-icon-delete-bin-line::before,
  .fr-icon-delete-bin-line::after {
    background-image: url("../../icons/system/delete-bin-line.svg");
  }

  .fr-icon-download-fill::before,
  .fr-icon-download-fill::after {
    background-image: url("../../icons/system/download-fill.svg");
  }

  .fr-icon-download-line::before,
  .fr-icon-download-line::after {
    background-image: url("../../icons/system/download-line.svg");
  }

  .fr-icon-error-warning-fill::before,
  .fr-icon-error-warning-fill::after {
    background-image: url("../../icons/system/error-warning-fill.svg");
  }

  .fr-icon-error-warning-line::before,
  .fr-icon-error-warning-line::after {
    background-image: url("../../icons/system/error-warning-line.svg");
  }

  .fr-icon-external-link-fill::before,
  .fr-icon-external-link-fill::after {
    background-image: url("../../icons/system/external-link-fill.svg");
  }

  .fr-icon-external-link-line::before,
  .fr-icon-external-link-line::after {
    background-image: url("../../icons/system/external-link-line.svg");
  }

  .fr-icon-eye-fill::before,
  .fr-icon-eye-fill::after {
    background-image: url("../../icons/system/eye-fill.svg");
  }

  .fr-icon-eye-line::before,
  .fr-icon-eye-line::after {
    background-image: url("../../icons/system/eye-line.svg");
  }

  .fr-icon-eye-off-fill::before,
  .fr-icon-eye-off-fill::after {
    background-image: url("../../icons/system/eye-off-fill.svg");
  }

  .fr-icon-eye-off-line::before,
  .fr-icon-eye-off-line::after {
    background-image: url("../../icons/system/eye-off-line.svg");
  }

  .fr-icon-filter-fill::before,
  .fr-icon-filter-fill::after {
    background-image: url("../../icons/system/filter-fill.svg");
  }

  .fr-icon-filter-line::before,
  .fr-icon-filter-line::after {
    background-image: url("../../icons/system/filter-line.svg");
  }

  .fr-icon-alert-warning-2-fill::before,
  .fr-icon-alert-warning-2-fill::after {
    background-image: url("../../icons/system/fr--alert-warning-2-fill.svg");
  }

  .fr-icon-alert-warning-fill::before,
  .fr-icon-alert-warning-fill::after {
    background-image: url("../../icons/system/fr--alert-warning-fill.svg");
  }

  .fr-icon-arrow-left-s-first-line::before,
  .fr-icon-arrow-left-s-first-line::after {
    background-image: url("../../icons/system/fr--arrow-left-s-first-line.svg");
  }

  .fr-icon-arrow-left-s-line-double::before,
  .fr-icon-arrow-left-s-line-double::after {
    background-image: url("../../icons/system/fr--arrow-left-s-line-double.svg");
  }

  .fr-icon-arrow-right-down-circle-fill::before,
  .fr-icon-arrow-right-down-circle-fill::after {
    background-image: url("../../icons/system/fr--arrow-right-down-circle-fill.svg");
  }

  .fr-icon-arrow-right-s-last-line::before,
  .fr-icon-arrow-right-s-last-line::after {
    background-image: url("../../icons/system/fr--arrow-right-s-last-line.svg");
  }

  .fr-icon-arrow-right-s-line-double::before,
  .fr-icon-arrow-right-s-line-double::after {
    background-image: url("../../icons/system/fr--arrow-right-s-line-double.svg");
  }

  .fr-icon-arrow-right-up-circle-fill::before,
  .fr-icon-arrow-right-up-circle-fill::after {
    background-image: url("../../icons/system/fr--arrow-right-up-circle-fill.svg");
  }

  .fr-icon-capslock-line::before,
  .fr-icon-capslock-line::after {
    background-image: url("../../icons/system/fr--capslock-line.svg");
  }

  .fr-icon-equal-circle-fill::before,
  .fr-icon-equal-circle-fill::after {
    background-image: url("../../icons/system/fr--equal-circle-fill.svg");
  }

  .fr-icon-error-fill::before,
  .fr-icon-error-fill::after {
    background-image: url("../../icons/system/fr--error-fill.svg");
  }

  .fr-icon-error-line::before,
  .fr-icon-error-line::after {
    background-image: url("../../icons/system/fr--error-line.svg");
  }

  .fr-icon-info-fill::before,
  .fr-icon-info-fill::after {
    background-image: url("../../icons/system/fr--info-fill.svg");
  }

  .fr-icon-info-line::before,
  .fr-icon-info-line::after {
    background-image: url("../../icons/system/fr--info-line.svg");
  }

  .fr-icon-success-fill::before,
  .fr-icon-success-fill::after {
    background-image: url("../../icons/system/fr--success-fill.svg");
  }

  .fr-icon-success-line::before,
  .fr-icon-success-line::after {
    background-image: url("../../icons/system/fr--success-line.svg");
  }

  .fr-icon-theme-fill::before,
  .fr-icon-theme-fill::after {
    background-image: url("../../icons/system/fr--theme-fill.svg");
  }

  .fr-icon-warning-fill::before,
  .fr-icon-warning-fill::after {
    background-image: url("../../icons/system/fr--warning-fill.svg");
  }

  .fr-icon-warning-line::before,
  .fr-icon-warning-line::after {
    background-image: url("../../icons/system/fr--warning-line.svg");
  }

  .fr-icon-information-fill::before,
  .fr-icon-information-fill::after {
    background-image: url("../../icons/system/information-fill.svg");
  }

  .fr-icon-information-line::before,
  .fr-icon-information-line::after {
    background-image: url("../../icons/system/information-line.svg");
  }

  .fr-icon-lock-fill::before,
  .fr-icon-lock-fill::after {
    background-image: url("../../icons/system/lock-fill.svg");
  }

  .fr-icon-lock-line::before,
  .fr-icon-lock-line::after {
    background-image: url("../../icons/system/lock-line.svg");
  }

  .fr-icon-lock-unlock-fill::before,
  .fr-icon-lock-unlock-fill::after {
    background-image: url("../../icons/system/lock-unlock-fill.svg");
  }

  .fr-icon-lock-unlock-line::before,
  .fr-icon-lock-unlock-line::after {
    background-image: url("../../icons/system/lock-unlock-line.svg");
  }

  .fr-icon-logout-box-r-fill::before,
  .fr-icon-logout-box-r-fill::after {
    background-image: url("../../icons/system/logout-box-r-fill.svg");
  }

  .fr-icon-logout-box-r-line::before,
  .fr-icon-logout-box-r-line::after {
    background-image: url("../../icons/system/logout-box-r-line.svg");
  }

  .fr-icon-menu-2-fill::before,
  .fr-icon-menu-2-fill::after {
    background-image: url("../../icons/system/menu-2-fill.svg");
  }

  .fr-icon-menu-fill::before,
  .fr-icon-menu-fill::after {
    background-image: url("../../icons/system/menu-fill.svg");
  }

  .fr-icon-more-fill::before,
  .fr-icon-more-fill::after {
    background-image: url("../../icons/system/more-fill.svg");
  }

  .fr-icon-more-line::before,
  .fr-icon-more-line::after {
    background-image: url("../../icons/system/more-line.svg");
  }

  .fr-icon-notification-badge-fill::before,
  .fr-icon-notification-badge-fill::after {
    background-image: url("../../icons/system/notification-badge-fill.svg");
  }

  .fr-icon-notification-badge-line::before,
  .fr-icon-notification-badge-line::after {
    background-image: url("../../icons/system/notification-badge-line.svg");
  }

  .fr-icon-question-fill::before,
  .fr-icon-question-fill::after {
    background-image: url("../../icons/system/question-fill.svg");
  }

  .fr-icon-question-line::before,
  .fr-icon-question-line::after {
    background-image: url("../../icons/system/question-line.svg");
  }

  .fr-icon-refresh-fill::before,
  .fr-icon-refresh-fill::after {
    background-image: url("../../icons/system/refresh-fill.svg");
  }

  .fr-icon-refresh-line::before,
  .fr-icon-refresh-line::after {
    background-image: url("../../icons/system/refresh-line.svg");
  }

  .fr-icon-search-fill::before,
  .fr-icon-search-fill::after {
    background-image: url("../../icons/system/search-fill.svg");
  }

  .fr-icon-search-line::before,
  .fr-icon-search-line::after {
    background-image: url("../../icons/system/search-line.svg");
  }

  .fr-icon-settings-5-fill::before,
  .fr-icon-settings-5-fill::after {
    background-image: url("../../icons/system/settings-5-fill.svg");
  }

  .fr-icon-settings-5-line::before,
  .fr-icon-settings-5-line::after {
    background-image: url("../../icons/system/settings-5-line.svg");
  }

  .fr-icon-shield-fill::before,
  .fr-icon-shield-fill::after {
    background-image: url("../../icons/system/shield-fill.svg");
  }

  .fr-icon-shield-line::before,
  .fr-icon-shield-line::after {
    background-image: url("../../icons/system/shield-line.svg");
  }

  .fr-icon-star-fill::before,
  .fr-icon-star-fill::after {
    background-image: url("../../icons/system/star-fill.svg");
  }

  .fr-icon-star-line::before,
  .fr-icon-star-line::after {
    background-image: url("../../icons/system/star-line.svg");
  }

  .fr-icon-star-s-fill::before,
  .fr-icon-star-s-fill::after {
    background-image: url("../../icons/system/star-s-fill.svg");
  }

  .fr-icon-star-s-line::before,
  .fr-icon-star-s-line::after {
    background-image: url("../../icons/system/star-s-line.svg");
  }

  .fr-icon-subtract-line::before,
  .fr-icon-subtract-line::after {
    background-image: url("../../icons/system/subtract-line.svg");
  }

  .fr-icon-thumb-down-fill::before,
  .fr-icon-thumb-down-fill::after {
    background-image: url("../../icons/system/thumb-down-fill.svg");
  }

  .fr-icon-thumb-down-line::before,
  .fr-icon-thumb-down-line::after {
    background-image: url("../../icons/system/thumb-down-line.svg");
  }

  .fr-icon-thumb-up-fill::before,
  .fr-icon-thumb-up-fill::after {
    background-image: url("../../icons/system/thumb-up-fill.svg");
  }

  .fr-icon-thumb-up-line::before,
  .fr-icon-thumb-up-line::after {
    background-image: url("../../icons/system/thumb-up-line.svg");
  }

  .fr-icon-time-fill::before,
  .fr-icon-time-fill::after {
    background-image: url("../../icons/system/time-fill.svg");
  }

  .fr-icon-time-line::before,
  .fr-icon-time-line::after {
    background-image: url("../../icons/system/time-line.svg");
  }

  .fr-icon-timer-fill::before,
  .fr-icon-timer-fill::after {
    background-image: url("../../icons/system/timer-fill.svg");
  }

  .fr-icon-timer-line::before,
  .fr-icon-timer-line::after {
    background-image: url("../../icons/system/timer-line.svg");
  }

  .fr-icon-upload-2-fill::before,
  .fr-icon-upload-2-fill::after {
    background-image: url("../../icons/system/upload-2-fill.svg");
  }

  .fr-icon-upload-2-line::before,
  .fr-icon-upload-2-line::after {
    background-image: url("../../icons/system/upload-2-line.svg");
  }

  .fr-icon-upload-fill::before,
  .fr-icon-upload-fill::after {
    background-image: url("../../icons/system/upload-fill.svg");
  }

  .fr-icon-upload-line::before,
  .fr-icon-upload-line::after {
    background-image: url("../../icons/system/upload-line.svg");
  }

  .fr-icon-zoom-in-fill::before,
  .fr-icon-zoom-in-fill::after {
    background-image: url("../../icons/system/zoom-in-fill.svg");
  }

  .fr-icon-zoom-in-line::before,
  .fr-icon-zoom-in-line::after {
    background-image: url("../../icons/system/zoom-in-line.svg");
  }

  .fr-icon-zoom-out-fill::before,
  .fr-icon-zoom-out-fill::after {
    background-image: url("../../icons/system/zoom-out-fill.svg");
  }

  .fr-icon-zoom-out-line::before,
  .fr-icon-zoom-out-line::after {
    background-image: url("../../icons/system/zoom-out-line.svg");
  }

  .fr-fi-add-circle-fill::before {
    background-image: url("../../icons/system/add-circle-fill.svg");
  }

  .fr-fi-add-circle-line::before {
    background-image: url("../../icons/system/add-circle-line.svg");
  }

  .fr-fi-add-line::before {
    background-image: url("../../icons/system/add-line.svg");
  }

  .fr-fi-arrow-down-line::before {
    background-image: url("../../icons/system/arrow-down-line.svg");
  }

  .fr-fi-arrow-down-s-line::before {
    background-image: url("../../icons/system/arrow-down-s-line.svg");
  }

  .fr-fi-arrow-go-back-fill::before {
    background-image: url("../../icons/system/arrow-go-back-fill.svg");
  }

  .fr-fi-arrow-go-back-line::before {
    background-image: url("../../icons/system/arrow-go-back-line.svg");
  }

  .fr-fi-arrow-left-line::before {
    background-image: url("../../icons/system/arrow-left-line.svg");
  }

  .fr-fi-arrow-left-s-line::before {
    background-image: url("../../icons/system/arrow-left-s-line.svg");
  }

  .fr-fi-arrow-right-line::before {
    background-image: url("../../icons/system/arrow-right-line.svg");
  }

  .fr-fi-arrow-right-s-line::before {
    background-image: url("../../icons/system/arrow-right-s-line.svg");
  }

  .fr-fi-arrow-right-up-line::before {
    background-image: url("../../icons/system/arrow-right-up-line.svg");
  }

  .fr-fi-arrow-up-fill::before {
    background-image: url("../../icons/system/arrow-up-fill.svg");
  }

  .fr-fi-arrow-up-line::before {
    background-image: url("../../icons/system/arrow-up-line.svg");
  }

  .fr-fi-arrow-up-s-line::before {
    background-image: url("../../icons/system/arrow-up-s-line.svg");
  }

  .fr-fi-check-line::before {
    background-image: url("../../icons/system/check-line.svg");
  }

  .fr-fi-checkbox-circle-line::before {
    background-image: url("../../icons/system/checkbox-circle-line.svg");
  }

  .fr-fi-close-line::before {
    background-image: url("../../icons/system/close-line.svg");
  }

  .fr-fi-download-line::before {
    background-image: url("../../icons/system/download-line.svg");
  }

  .fr-fi-error-warning-fill::before {
    background-image: url("../../icons/system/error-warning-fill.svg");
  }

  .fr-fi-error-warning-line::before {
    background-image: url("../../icons/system/error-warning-line.svg");
  }

  .fr-fi-external-link-line::before {
    background-image: url("../../icons/system/external-link-line.svg");
  }

  .fr-fi-eye-fill::before {
    background-image: url("../../icons/system/eye-fill.svg");
  }

  .fr-fi-eye-line::before {
    background-image: url("../../icons/system/eye-line.svg");
  }

  .fr-fi-eye-off-fill::before {
    background-image: url("../../icons/system/eye-off-fill.svg");
  }

  .fr-fi-eye-off-line::before {
    background-image: url("../../icons/system/eye-off-line.svg");
  }

  .fr-fi-filter-fill::before {
    background-image: url("../../icons/system/filter-fill.svg");
  }

  .fr-fi-filter-line::before {
    background-image: url("../../icons/system/filter-line.svg");
  }

  .fr-fi-arrow-left-s-first-line::before {
    background-image: url("../../icons/system/fr--arrow-left-s-first-line.svg");
  }

  .fr-fi-arrow-left-s-line-double::before {
    background-image: url("../../icons/system/fr--arrow-left-s-line-double.svg");
  }

  .fr-fi-arrow-right-s-last-line::before {
    background-image: url("../../icons/system/fr--arrow-right-s-last-line.svg");
  }

  .fr-fi-arrow-right-s-line-double::before {
    background-image: url("../../icons/system/fr--arrow-right-s-line-double.svg");
  }

  .fr-fi-error-fill::before {
    background-image: url("../../icons/system/fr--error-fill.svg");
  }

  .fr-fi-error-line::before {
    background-image: url("../../icons/system/fr--error-line.svg");
  }

  .fr-fi-info-fill::before {
    background-image: url("../../icons/system/fr--info-fill.svg");
  }

  .fr-fi-info-line::before {
    background-image: url("../../icons/system/fr--info-line.svg");
  }

  .fr-fi-success-fill::before {
    background-image: url("../../icons/system/fr--success-fill.svg");
  }

  .fr-fi-success-line::before {
    background-image: url("../../icons/system/fr--success-line.svg");
  }

  .fr-fi-theme-fill::before {
    background-image: url("../../icons/system/fr--theme-fill.svg");
  }

  .fr-fi-warning-fill::before {
    background-image: url("../../icons/system/fr--warning-fill.svg");
  }

  .fr-fi-warning-line::before {
    background-image: url("../../icons/system/fr--warning-line.svg");
  }

  .fr-fi-information-fill::before {
    background-image: url("../../icons/system/information-fill.svg");
  }

  .fr-fi-information-line::before {
    background-image: url("../../icons/system/information-line.svg");
  }

  .fr-fi-lock-fill::before {
    background-image: url("../../icons/system/lock-fill.svg");
  }

  .fr-fi-lock-line::before {
    background-image: url("../../icons/system/lock-line.svg");
  }

  .fr-fi-logout-box-r-fill::before {
    background-image: url("../../icons/system/logout-box-r-fill.svg");
  }

  .fr-fi-logout-box-r-line::before {
    background-image: url("../../icons/system/logout-box-r-line.svg");
  }

  .fr-fi-menu-2-fill::before {
    background-image: url("../../icons/system/menu-2-fill.svg");
  }

  .fr-fi-menu-fill::before {
    background-image: url("../../icons/system/menu-fill.svg");
  }

  .fr-fi-question-fill::before {
    background-image: url("../../icons/system/question-fill.svg");
  }

  .fr-fi-question-line::before {
    background-image: url("../../icons/system/question-line.svg");
  }

  .fr-fi-refresh-fill::before {
    background-image: url("../../icons/system/refresh-fill.svg");
  }

  .fr-fi-refresh-line::before {
    background-image: url("../../icons/system/refresh-line.svg");
  }

  .fr-fi-search-fill::before {
    background-image: url("../../icons/system/search-fill.svg");
  }

  .fr-fi-search-line::before {
    background-image: url("../../icons/system/search-line.svg");
  }

  .fr-fi-subtract-line::before {
    background-image: url("../../icons/system/subtract-line.svg");
  }

  .fr-fi-timer-fill::before {
    background-image: url("../../icons/system/timer-fill.svg");
  }

  .fr-fi-timer-line::before {
    background-image: url("../../icons/system/timer-line.svg");
  }

  .fr-fi-upload-2-fill::before {
    background-image: url("../../icons/system/upload-2-fill.svg");
  }

  .fr-fi-upload-2-line::before {
    background-image: url("../../icons/system/upload-2-line.svg");
  }

  .fr-fi-zoom-in-fill::before {
    background-image: url("../../icons/system/zoom-in-fill.svg");
  }

  .fr-fi-zoom-in-line::before {
    background-image: url("../../icons/system/zoom-in-line.svg");
  }

  .fr-fi-zoom-out-fill::before {
    background-image: url("../../icons/system/zoom-out-fill.svg");
  }

  .fr-fi-zoom-out-line::before {
    background-image: url("../../icons/system/zoom-out-line.svg");
  }

  .fr-fi-delete-line::before,
  .fr-fi-delete-line::after {
    background-image: url("../../icons/system/delete-bin-line.svg");
  }

  .fr-fi-delete-fill::before,
  .fr-fi-delete-fill::after {
    background-image: url("../../icons/system/delete-bin-fill.svg");
  }

  .fr-icon-delete-line::before,
  .fr-icon-delete-line::after {
    background-image: url("../../icons/system/delete-bin-line.svg");
  }

  .fr-icon-delete-fill::before,
  .fr-icon-delete-fill::after {
    background-image: url("../../icons/system/delete-bin-fill.svg");
  }

  .fr-icon-account-circle-fill::before,
  .fr-icon-account-circle-fill::after {
    background-image: url("../../icons/user/account-circle-fill.svg");
  }

  .fr-icon-account-circle-line::before,
  .fr-icon-account-circle-line::after {
    background-image: url("../../icons/user/account-circle-line.svg");
  }

  .fr-icon-account-pin-circle-fill::before,
  .fr-icon-account-pin-circle-fill::after {
    background-image: url("../../icons/user/account-pin-circle-fill.svg");
  }

  .fr-icon-account-pin-circle-line::before,
  .fr-icon-account-pin-circle-line::after {
    background-image: url("../../icons/user/account-pin-circle-line.svg");
  }

  .fr-icon-admin-fill::before,
  .fr-icon-admin-fill::after {
    background-image: url("../../icons/user/admin-fill.svg");
  }

  .fr-icon-admin-line::before,
  .fr-icon-admin-line::after {
    background-image: url("../../icons/user/admin-line.svg");
  }

  .fr-icon-group-fill::before,
  .fr-icon-group-fill::after {
    background-image: url("../../icons/user/group-fill.svg");
  }

  .fr-icon-group-line::before,
  .fr-icon-group-line::after {
    background-image: url("../../icons/user/group-line.svg");
  }

  .fr-icon-parent-fill::before,
  .fr-icon-parent-fill::after {
    background-image: url("../../icons/user/parent-fill.svg");
  }

  .fr-icon-parent-line::before,
  .fr-icon-parent-line::after {
    background-image: url("../../icons/user/parent-line.svg");
  }

  .fr-icon-team-fill::before,
  .fr-icon-team-fill::after {
    background-image: url("../../icons/user/team-fill.svg");
  }

  .fr-icon-team-line::before,
  .fr-icon-team-line::after {
    background-image: url("../../icons/user/team-line.svg");
  }

  .fr-icon-user-add-fill::before,
  .fr-icon-user-add-fill::after {
    background-image: url("../../icons/user/user-add-fill.svg");
  }

  .fr-icon-user-add-line::before,
  .fr-icon-user-add-line::after {
    background-image: url("../../icons/user/user-add-line.svg");
  }

  .fr-icon-user-fill::before,
  .fr-icon-user-fill::after {
    background-image: url("../../icons/user/user-fill.svg");
  }

  .fr-icon-user-heart-fill::before,
  .fr-icon-user-heart-fill::after {
    background-image: url("../../icons/user/user-heart-fill.svg");
  }

  .fr-icon-user-heart-line::before,
  .fr-icon-user-heart-line::after {
    background-image: url("../../icons/user/user-heart-line.svg");
  }

  .fr-icon-user-line::before,
  .fr-icon-user-line::after {
    background-image: url("../../icons/user/user-line.svg");
  }

  .fr-icon-user-search-fill::before,
  .fr-icon-user-search-fill::after {
    background-image: url("../../icons/user/user-search-fill.svg");
  }

  .fr-icon-user-search-line::before,
  .fr-icon-user-search-line::after {
    background-image: url("../../icons/user/user-search-line.svg");
  }

  .fr-icon-user-setting-fill::before,
  .fr-icon-user-setting-fill::after {
    background-image: url("../../icons/user/user-setting-fill.svg");
  }

  .fr-icon-user-setting-line::before,
  .fr-icon-user-setting-line::after {
    background-image: url("../../icons/user/user-setting-line.svg");
  }

  .fr-icon-user-star-fill::before,
  .fr-icon-user-star-fill::after {
    background-image: url("../../icons/user/user-star-fill.svg");
  }

  .fr-icon-user-star-line::before,
  .fr-icon-user-star-line::after {
    background-image: url("../../icons/user/user-star-line.svg");
  }

  .fr-fi-user-fill::before {
    background-image: url("../../icons/user/user-fill.svg");
  }

  .fr-fi-user-line::before {
    background-image: url("../../icons/user/user-line.svg");
  }

  .fr-fi-account-line::before,
  .fr-fi-account-line::after {
    background-image: url("../../icons/user/account-circle-line.svg");
  }

  .fr-fi-account-fill::before,
  .fr-fi-account-fill::after {
    background-image: url("../../icons/user/account-circle-fill.svg");
  }

  .fr-icon-account-line::before,
  .fr-icon-account-line::after {
    background-image: url("../../icons/user/account-circle-line.svg");
  }

  .fr-icon-account-fill::before,
  .fr-icon-account-fill::after {
    background-image: url("../../icons/user/account-circle-fill.svg");
  }

  .fr-icon-cloudy-2-fill::before,
  .fr-icon-cloudy-2-fill::after {
    background-image: url("../../icons/weather/cloudy-2-fill.svg");
  }

  .fr-icon-cloudy-2-line::before,
  .fr-icon-cloudy-2-line::after {
    background-image: url("../../icons/weather/cloudy-2-line.svg");
  }

  .fr-icon-fire-fill::before,
  .fr-icon-fire-fill::after {
    background-image: url("../../icons/weather/fire-fill.svg");
  }

  .fr-icon-fire-line::before,
  .fr-icon-fire-line::after {
    background-image: url("../../icons/weather/fire-line.svg");
  }

  .fr-icon-flashlight-fill::before,
  .fr-icon-flashlight-fill::after {
    background-image: url("../../icons/weather/flashlight-fill.svg");
  }

  .fr-icon-flashlight-line::before,
  .fr-icon-flashlight-line::after {
    background-image: url("../../icons/weather/flashlight-line.svg");
  }

  .fr-icon-flood-fill::before,
  .fr-icon-flood-fill::after {
    background-image: url("../../icons/weather/flood-fill.svg");
  }

  .fr-icon-flood-line::before,
  .fr-icon-flood-line::after {
    background-image: url("../../icons/weather/flood-line.svg");
  }

  .fr-icon-avalanches-fill::before,
  .fr-icon-avalanches-fill::after {
    background-image: url("../../icons/weather/fr--avalanches-fill.svg");
  }

  .fr-icon-submersion-fill::before,
  .fr-icon-submersion-fill::after {
    background-image: url("../../icons/weather/fr--submersion-fill.svg");
  }

  .fr-icon-heavy-showers-fill::before,
  .fr-icon-heavy-showers-fill::after {
    background-image: url("../../icons/weather/heavy-showers-fill.svg");
  }

  .fr-icon-heavy-showers-line::before,
  .fr-icon-heavy-showers-line::after {
    background-image: url("../../icons/weather/heavy-showers-line.svg");
  }

  .fr-icon-moon-fill::before,
  .fr-icon-moon-fill::after {
    background-image: url("../../icons/weather/moon-fill.svg");
  }

  .fr-icon-moon-line::before,
  .fr-icon-moon-line::after {
    background-image: url("../../icons/weather/moon-line.svg");
  }

  .fr-icon-snowy-fill::before,
  .fr-icon-snowy-fill::after {
    background-image: url("../../icons/weather/snowy-fill.svg");
  }

  .fr-icon-snowy-line::before,
  .fr-icon-snowy-line::after {
    background-image: url("../../icons/weather/snowy-line.svg");
  }

  .fr-icon-sparkling-2-fill::before,
  .fr-icon-sparkling-2-fill::after {
    background-image: url("../../icons/weather/sparkling-2-fill.svg");
  }

  .fr-icon-sparkling-2-line::before,
  .fr-icon-sparkling-2-line::after {
    background-image: url("../../icons/weather/sparkling-2-line.svg");
  }

  .fr-icon-sun-fill::before,
  .fr-icon-sun-fill::after {
    background-image: url("../../icons/weather/sun-fill.svg");
  }

  .fr-icon-sun-line::before,
  .fr-icon-sun-line::after {
    background-image: url("../../icons/weather/sun-line.svg");
  }

  .fr-icon-temp-cold-fill::before,
  .fr-icon-temp-cold-fill::after {
    background-image: url("../../icons/weather/temp-cold-fill.svg");
  }

  .fr-icon-temp-cold-line::before,
  .fr-icon-temp-cold-line::after {
    background-image: url("../../icons/weather/temp-cold-line.svg");
  }

  .fr-icon-thunderstorms-fill::before,
  .fr-icon-thunderstorms-fill::after {
    background-image: url("../../icons/weather/thunderstorms-fill.svg");
  }

  .fr-icon-thunderstorms-line::before,
  .fr-icon-thunderstorms-line::after {
    background-image: url("../../icons/weather/thunderstorms-line.svg");
  }

  .fr-icon-tornado-fill::before,
  .fr-icon-tornado-fill::after {
    background-image: url("../../icons/weather/tornado-fill.svg");
  }

  .fr-icon-tornado-line::before,
  .fr-icon-tornado-line::after {
    background-image: url("../../icons/weather/tornado-line.svg");
  }

  .fr-icon-typhoon-fill::before,
  .fr-icon-typhoon-fill::after {
    background-image: url("../../icons/weather/typhoon-fill.svg");
  }

  .fr-icon-typhoon-line::before,
  .fr-icon-typhoon-line::after {
    background-image: url("../../icons/weather/typhoon-line.svg");
  }

  .fr-icon-windy-fill::before,
  .fr-icon-windy-fill::after {
    background-image: url("../../icons/weather/windy-fill.svg");
  }

  .fr-icon-windy-line::before,
  .fr-icon-windy-line::after {
    background-image: url("../../icons/weather/windy-line.svg");
  }

  .fr-fi-flashlight-fill::before {
    background-image: url("../../icons/weather/flashlight-fill.svg");
  }

  .fr-fi-flashlight-line::before {
    background-image: url("../../icons/weather/flashlight-line.svg");
  }
}

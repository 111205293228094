@import 'remixicons';
@import 'header';
@import 'newsletter-banner';
@import 'footer';
@import 'contrib';
@import 'faq';
@import 'remixicons';

a {
  --link-underline: none;
}

.fr-header__logo .fr-logo {
  font-size: 0.7875rem !important;
}

.bg-alt-blue-france-975 {
  background-color: #f5f5fe !important;
}

.auto-height {
  height: auto !important;
}

.input-form {
  position: relative;
  display: inline-block;
}

.fr-collapse {
  max-height: var(--collapse-max-height) !important;
}

label {
  margin-bottom: 0 !important; // Needed to respect DSFR and counter reboot.scss from bootstrap
}

.fr-btns-group .fr-link {
  // Make sure we can mix a a tag and a btn tag in a btn group
  line-height: 2.5rem;
}

.labels {
  .fr-card__body {
    padding: 0 1rem;
  }

  .fr-card__header {
    .fr-responsive-img {
      height: 100px;
      width: auto;
    }
  }

  @media (width >= 48em) {
    .fr-card--horizontal .fr-card__header {
      flex: 0 0 30%;
      width: 30%;
    }
  }
}

.leaflet-container .a4a-map-popup-content a {
  text-decoration: underline;
}

.leaflet-control-layers-base input[type='radio'] {
  appearance: auto;
}

.leaflet-control-layers-toggle {
  background-image: url('../../node_modules/leaflet/dist/images/layers.png') !important;
  background-size: auto !important;
  background-position: 50% !important;
}

.leaflet-control-container {
  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out,
  .leaflet-bar-part {
    background-image: none;
  }
}

.text-center {
  text-align: center;
}

// SPACING
.mt-10,
.my-10 {
  margin-top: 9rem !important;
}

.mt-20,
.my-20 {
  margin-top: 15rem !important;
}

.display--block {
  display: block;
}

.justify {
  &--self {
    &-end {
      justify-self: flex-end;
    }
  }
}

.display {
  &--flex {
    display: flex;
  }
}

@mixin line-clamp($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: $lines;
  line-clamp: $lines;
}

.text--clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  &-2 {
    @extend .text--clamp;
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }

  &-3 {
    @extend .text--clamp;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
}

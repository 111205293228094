@import 'variables';
@import 'utilities';
@import 'breakpoints';
@import '~bootstrap/scss/bootstrap';

// App specific styles
@import 'loader';
@import 'contribution';
@import 'half-circle-progress-bar';
@import 'leaflet';

#cta .fr-btn {
  @media screen and (max-width: $viewport-md) {
    margin-bottom: 0.5rem;
  }
}

iframe {
  border: 0px;
  margin: 0px;
}

a.btn {
  text-decoration: none;
}

.btn:hover,
.btn:focus {
  background-color: white;
  color: rgba(41, 41, 255, 0.5);
  background-image: none !important;
  --color-hover: rgba(41, 41, 255, 0.5);
  --color-active: rgba(41, 41, 255, 0.5);
  border: 2px solid rgba(41, 41, 255, 0.5);
}

#home-latest-erps-list {
  & ul {
    padding-left: 0px;
  }

  & li {
    list-style-type: none;
  }
}

select[name='language'] {
  width: fit-content;
  padding: 0.25rem 2rem;
}

#widgetmodal {
  z-index: 9999;
}

.autocomplete-result-list {
  z-index: 1001 !important;
  padding-left: inherit;
  padding-right: inherit;
  left: 0;
  right: 0;
}

.a4a {
  // Ensure sticky footer
  // https://dev.to/nehalahmadkhan/how-to-make-footer-stick-to-bottom-of-web-page-3i14
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &-footer {
    margin-top: auto;
  }

  &-bg-home {
    background-position: center;
    background-size: cover;
  }

  &-staging-notice {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background: $warning;
    padding: 2px 10px;
    text-align: center;
    z-index: 9999;
  }

  .bold {
    font-weight: bold;
  }

  .focus-img:focus img {
    border: 2px solid white;
    padding: 2px;
  }

  &-comment-block {
    p {
      margin: 0;
      overflow-wrap: break-word;

      &::before {
        content: '« ';
      }

      &::after {
        content: ' »';
      }
    }
  }

  &-autocomplete-result {
    cursor: pointer;
    font-size: 0.87em;
    padding: 8px 12px;

    &:hover {
      color: $white;
      background-color: $primary;
      border-color: $primary;
    }
  }

  .full-width {
    width: 100%;
  }

  .full-max-width {
    max-width: 100%;
  }

  .flex {
    display: flex;
  }

  .larger-font {
    font-size: 1.2rem;
  }

  .rounded-only-bottom {
    border-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .btn {
    .icon {
      font-size: 0.9em;
    }
  }

  .act-icon {
    background: $primary;
    border-radius: 25%;
    color: currentColor;
    width: 48px;
    height: 48px;
    padding: 6px;

    &-black {
      filter: grayscale(100%) contrast(1.75);
    }

    &-erp {
      // large viewport
      @media screen and (min-width: $viewport-md) {
        width: 96px;
        height: 96px;
        padding: 7px;
        margin-right: 15px;
      }

      // mobile viewport
      @media screen and (max-width: $viewport-md) {
        width: 32px;
        height: 32px;
        padding: 4px;
        margin-right: 10px;
      }
    }

    &-invert {
      filter: invert(1) contrast(2);
    }

    &-rounded {
      border-radius: 50%;
    }

    &-16 {
      width: 16px;
      height: 16px;
      padding: 2px;
    }

    &-20 {
      width: 20px;
      height: 20px;
      padding: 2px;
    }

    &-24 {
      width: 24px;
      height: 24px;
      padding: 3px;
    }

    &-32 {
      width: 32px;
      height: 32px;
      padding: 4px;
    }

    &-48 {
      width: 48px;
      height: 48px;
      padding: 5px;
    }

    &-72 {
      width: 72px;
      height: 72px;
      padding: 6px;
    }

    &-96 {
      width: 96px;
      height: 96px;
      padding: 7px;
    }
  }

  svg {
    fill: currentColor;
  }

  .cgu {
    p,
    li {
      color: #444;
    }
  }

  &-partenaire-logo {
    max-width: 100%;
    max-height: 120px;
  }

  &-partenaire-logo-small {
    max-width: 100%;
    max-height: 80px;
  }

  .nav-tabs .active {
    font-weight: bold;
  }

  &-notifications {
    position: fixed;
    bottom: 0;
    right: 10px;
    padding: 0;
    z-index: 9999;

    @media screen and (max-width: $viewport-md) {
      left: 0;
      right: 0;
    }

    &-item {
      min-width: 320px;
      max-width: 600px;
      animation: fadeInOut 6s;
      animation-fill-mode: forwards;
      opacity: 1;
      margin: 10px;
      right: 0;
      height: auto;

      @media screen and (max-width: $viewport-md) {
        left: 10px;
        right: 10px;
        width: calc(100% - 20px);
        max-width: calc(100% - 20px);
      }

      @keyframes fadeInOut {
        0% {
          height: auto;
          opacity: 0;
          right: 0;
        }

        5% {
          height: auto;
          opacity: 1;
          right: 0;
        }

        90% {
          height: auto;
          opacity: 1;
          right: 0;
        }

        100% {
          height: 0px;
          opacity: 0;
          right: -1000px;
          z-index: -1;
        }
      }
    }
  }

  .no-list {
    list-style-type: none;
  }

  .text-overlay {
    overflow: overlay;
  }

  &-home-commune-link {
    text-decoration: none;

    &:hover {
      text-decoration: none;

      .card-body {
        background-color: #f8f9fa;
      }
    }
  }

  .a11y-field {
    list-style-type: none;
    margin: 0;
    padding: 0.27rem 0;
  }

  &-text-smaller {
    font-size: 0.9em;
  }

  hr[noshade] {
    border-top: 1px solid #ddd;
    margin: 0.5em 0;
  }

  .bl-highlight {
    border-left: 6px solid #ddd;
    margin-left: 2em;
    padding-left: 1em;
  }

  .list-group-item {
    padding: 0.75rem 1.25rem;
  }

  .form-group {
    > label {
      color: #444;
      font-weight: 500;
    }

    margin-bottom: 2em;
  }

  &-tab-content-section {
    min-height: 100%;
    background: $white;
  }

  &-localisation-map {
    width: 100%;
    height: 400px;
  }

  &-map-activite-icon {
    box-shadow: 2px 2px 5px #777;

    &.highlighted {
      background-color: #fff;
      border-radius: 50px;
      border: 4px solid #fff;
    }
  }

  &-map-popup-content {
    font-size: 1.2em;
  }

  &-result-map {
    background-color: #eee;
    background-position: center;
    background-size: cover;
    min-height: 100px;
  }

  &-map-reverse-results {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &-logo {
    font-size: 3.5em;
  }

  &-logo img {
    width: 90px;
  }

  &-navbar {
    background-color: $primary;
    padding: 1px 1em;
  }

  &-app-map {
    @media screen and (min-width: $viewport-md) {
      height: calc(100vh - 58px - 57px);
    }

    @media screen and (max-width: $viewport-md) {
      height: calc(100vh - 148px);
    }
  }

  &-app-erp-map {
    @media screen and (min-width: $viewport-md) {
      height: 350px;
    }

    @media screen and (max-width: $viewport-md) {
      height: 200px;
    }
  }

  &-search-form {
    .autocomplete-input {
      &.loading {
        background: transparent url('../img/loading.gif') no-repeat;
        background-position: calc(100% - 8px) center;
      }
    }

    div.search-where-field {
      max-height: 5rem;
    }

    @media screen and (min-width: $viewport-xl) {
      input[name='what'] {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        &:focus {
          z-index: 100000;
        }
      }

      input[name='where'] {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;

        &:focus {
          z-index: 100000;
        }
      }
    }
  }

  &-search-pager {
    justify-content: flex-end; // desktop

    @media only screen and (max-width: $viewport-sm) {
      // on mobile we want the pagination links to sit centered under the section heading
      margin-top: 1em;
      justify-content: center;
    }
  }

  &-search-map-area {
    position: relative;
    height: 600px; // desktop

    @media only screen and (max-width: $viewport-sm) {
      order: -1; // on mobile the map is on top of results
    }
  }

  &-search-map-area-global {
    position: relative;
    min-height: 800px; // desktop

    @media only screen and (max-width: $viewport-sm) {
      min-height: 200px; // mobile
      order: -1; // on mobile the map is on top of results
    }
  }

  &-search-map {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &-cluster-icon {
    background-color: #13629a;
    border-radius: 50%;
    color: white;
    font-size: 12px;
    font-weight: 600;
    height: 32px;
    line-height: 32px;
    margin-left: -16px;
    margin-top: -16px;
    text-align: center;
    width: 32px;
  }

  &-icon-btn {
    display: inline;
    padding-bottom: 1px;
    padding-right: 8px;
  }

  &-locate-icon::before {
    line-height: inherit !important;
  }

  /* Forms */

  .asteriskField {
    color: darkorange;
    font-size: 1.1em;
    font-weight: bold;
    position: absolute;
    left: 5px;
    text-decoration: none;
  }

  input[type='checkbox'] + .form-check-label.requiredField {
    display: flex;
  }

  input[type='checkbox'] + .form-check-label.requiredField .asteriskField {
    left: -10px;
  }

  .hidden {
    display: none;
  }

  .form-text.text-muted {
    font-size: 0.8em;
  }

  &-form-choices {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    &.inline {
      li {
        display: inline-block;
        margin-right: 10px;
      }
    }
  }

  &-form-field {
    border-bottom: 1px solid #ddd;
  }

  &-form-input-number {
    width: 100px;
  }
}

#erp-results-list {
  max-height: 600px;
  overflow-y: scroll;

  &:has(div) {
    border: 1px solid $input-border-color;
    padding: 2px;
    border-radius: 3px;
  }
}

@media (min-width: 768px) {
  #erp-results-list {
    margin-right: 10px;
  }
}

#export-results {
  display: none;

  @media screen and (min-width: $viewport-lg) {
    display: block;
  }
}

div.equipments {
  margin: 15px 0;
}

div.equipments button {
  margin: 2px 2px;
}

div.equipments label {
  margin-bottom: 0px;
}

// add a left margin on "See all filters" only if there is some selected filters/equipments
div.equipments-selected
  > span:not(.hidden, .section-text)
  ~ button:first-of-type {
  margin-left: 0.5rem !important;
}

div.equipments > span:not(.hidden, .section-text) {
  display: flex !important;
  margin: 0;
  padding: 0;
}

.row > * {
  width: auto !important;
}

@media (min-width: $viewport-md) {
  div.equipments .section-text {
    width: 110px;
    text-align: left;
  }
}

@media (min-width: 768px) {
  .responsive-tabs .tab-content > .tab-pane {
    display: block;
  }
}

.a4a-search-map-area {
  height: 600px !important;
}

.fr-tabs {
  --tabs-height: auto !important;
}

@media (min-width: $viewport-md) {
  .fr-tabs__list {
    display: none !important;
  }

  .fr-tabs__panel:not(.fr-tabs__panel--selected) {
    visibility: visible !important;
  }

  .fr-tabs__panel {
    margin-right: 0px !important;
    left: 0px !important;
  }

  .fr-tabs__panel--direction-end {
    transform: none !important;
  }
}

// icons for tag filters
.tag-picto {
  &:before {
    height: 16px;
    width: 16px;
    margin-right: 3px;
    content: '';
    background-color: var(--background-action-high-blue-france);
  }

  &[aria-pressed='true']:before {
    background-color: white;
  }

  &-wheelchair:before {
    mask-image: url('../img/fauteuil.svg');
  }

  &-diff-walking:before {
    mask-image: url('../img/cane.svg');
  }

  &-diff-vision:before {
    mask-image: url('../img/cessite.svg');
  }

  &-deaf:before {
    mask-image: url('../img/surdite.svg');
  }

  &-diff-understand:before {
    mask-image: url('../img/communication.svg');
  }
}

#search-form .fr-btn--tertiary {
  height: 32px;
  border-radius: 6px;
  padding: 0 0.5rem;
  margin: 0;
  margin-top: 2px;
  box-shadow: inset 0 0 0 1px var(--background-action-high-blue-france);
  font-size: 0.875rem;
}

.fr-search-bar button {
  min-height: 2.5rem !important;
  max-height: 2.5rem !important;
}

.fr-search-bar button:hover {
  color: white;
}

.fr-search-bar button[disabled]:hover {
  color: var(--text-disabled-grey);
}

@media (min-width: 48em) {
  .fr-footer__bottom-item {
    margin: 0.4rem 0 0 0.3rem !important;
  }

  .fr-footer__content {
    flex-basis: 60% !important;
  }
}

.fr-sidemenu__item.is-done::before {
  color: limegreen;
  left: -0.75rem;
  top: 0.75rem;
  content: '✔';
  box-shadow: none;
}

.fr-sidemenu__item::before {
  box-shadow: none !important;
}

.team .fr-card {
  min-height: 10rem;
}

.remove-title-if-empty {
  h3:has(+ ul:empty) {
    display: none;
  }
}

.indented1 {
  margin-left: 2rem;
}

.indented2 {
  margin-left: 4rem;
}

@media screen and (max-width: $viewport-md) {
  .erp-details {
    .card {
      border-left: 0px;
      border-right: 0px;
      border-top: 1px solid #00000020;
      border-bottom: 1px solid #00000020;
      border-radius: 0px;
    }

    .fr-col-md-6 {
      padding: 0px;
    }
  }
}

#map-demo {
  width: 100%;
  height: 400px;
}

#widget-code > div {
  background-color: black;
  padding: 1rem;

  code {
    color: lightgrey;
  }
}

.blue-title {
  color: var(--text-action-high-blue-france) !important;
}

.bg-alt-green-tilleul-verveine {
  background-color: var(--background-alt-green-tilleul-verveine) !important;

  .fr-btn {
    background-color: white;
  }
}

.bg-alt-green-archipel {
  background-color: var(--green-archipel-975-75) !important;

  .fr-btn {
    background-color: white;
  }
}

.bg-low-green-bourgeon {
  background-color: var(--green-bourgeon-975-75) !important;

  .fr-btn {
    background-color: white;
  }
}

.bg-low-orange-terre-battue {
  background-color: var(--orange-terre-battue-975-75) !important;

  .fr-btn {
    background-color: white;
  }
}

.homepage-hero {
  .fr-content-media__img {
    height: 100%;
  }

  .fr-content-media__img img {
    max-width: 100%;
    height: 100%;
  }

  figure.fr-content-media {
    height: 100%;
  }
}

.down-arrow {
  color: #fbe769;
}

.message-box {
  position: fixed;
  bottom: 50px;
  right: 20px;
  max-width: 500px;
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 5px;
  z-index: 1000;

  &.success {
    background-color: #d4edda;
    color: #155724;
    border-color: #c3e6cb;
  }

  &.error {
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
  }

  a {
    text-decoration: underline;
  }
}

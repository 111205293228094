$white: #fff;
$body-color: #111;
$dark: #333;
$primary: #075ea2;
$success: #167d2e;
$info: #097182;
$warning: #ffc107;
$input-border-color: #969696;
$navbar-dark-color: rgba($white, 0.8);
$text-dark: #343a40;
$blue-france: #000091;
$text-muted: rgb(104, 104, 104);
$border-radius: 0.25rem !default;

// Responsive texts
$enable-responsive-font-sizes: true;
